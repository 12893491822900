import React, { useState, useEffect, useContext } from "react";
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Table, Col, Input, InputGroup, InputGroupText, Button, Spinner} from "reactstrap";
import "assets/css/sidebar.css";
import { useNavigate, useParams } from "react-router-dom";


function BudgetYearAllocation(props) {

    let { budgetYearId } = useParams();

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [error, setError] = useState('');
    const api = useAxios();

    const [budgetYear, setBudgetYear] = useState('');
    const [departments, setDepartments] = useState([]);
    const [allocation, setAllocation] = useState({});

    const [isSaving, setIsSaving] = useState(false)

    const [totals, setTotals] = useState({});
    const [cat_totals, setCatTotals] = useState({});

    const formatter = new Intl.NumberFormat("en-SG", {
        style: 'currency',
        currency: 'SGD',
        currencySign: 'accounting',
        maximumFractionDigits: 0,
      });
    useEffect(() => {

        if (!user.is_portal_admin) {
            navigate('/dashboard')
        }

        const BUDGET_YEAR_ALLOCATION_DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/allocation/" + budgetYearId + "/";

        const fetchData = async () => {
          try {
            // Get data to populate form
            const budget_year = await api.get(BUDGET_YEAR_ALLOCATION_DATA_URL);
            setBudgetYear({'id': budget_year.data.id, 'year': budget_year.data.year, 'label': budget_year.data.label});
            const depts = {};
            const alloc = {};
            const ttls = {'exp': 0, 'capex': 0, 'total': 0};
            const catttl = {};
            budget_year.data.departments.forEach(d => {
                const all_exp = Math.round(d.allocated_expense);
                const all_capex = Math.round(d.allocated_capex);

                if (!(d.department.category.name in depts)) {
                    depts[d.department.category.name] = [];
                }
                depts[d.department.category.name].push(d);
                alloc[d.id] = {'exp': all_exp, 'capex': all_capex};
                if (!(d.department.category.name in catttl)) {
                    catttl[d.department.category.name] = {'exp': 0, 'capex': 0, 'total': 0};
                }
                catttl[d.department.category.name]['exp'] += all_exp;
                catttl[d.department.category.name]['capex'] += all_capex;
                catttl[d.department.category.name]['total'] += all_exp + all_capex
                ttls['exp'] += all_exp;
                ttls['capex'] += all_capex;
                ttls['total'] += all_exp + all_capex;
            });
            setDepartments(depts);
            setAllocation(alloc);
            setTotals(ttls);
            setCatTotals(catttl);
          } catch {
            setError("Something went wrong");
          }
        };
        fetchData();
    }, []);


    const setAllocated = (cname, did, exp, amount) => {
        if (amount) {
            amount = amount.replaceAll(',', '');
            if (isNaN(amount)) {
                alert("Please enter only numeric value");
                return false;
            } else {
                if (amount < 0) {
                    alert("Please enter only positive value");
                    return false;
                }
                let old_value = allocation[did][exp];
                amount = parseInt(amount);
                totals[exp] -= old_value;
                totals[exp] += amount;
                totals['total'] -= old_value;
                totals['total'] += amount;
                cat_totals[cname][exp] -= old_value;
                cat_totals[cname][exp] += amount;
                cat_totals[cname]['total'] -= old_value;
                cat_totals[cname]['total'] += amount;
                allocation[did][exp] = amount;
                setAllocation({
                    ...allocation
                });
            }
        }
    };

    function validate() {

        return true;
    }

    function preventSubmit(e) {
        e.preventDefault();
        alert("this form should not submit");
        return false;
    }

    function handleSubmit(event) {
        setIsSaving(true);
        const PUT_URL = process.env.REACT_APP_API_URL + "/budget_year/allocate/" + budgetYearId + "/";
        if (validate()) {
            api.put(PUT_URL, {
                'allocation': allocation,
            })
            .then(function (response) {
                alert("Budget Year Allocation successfully updated");
                navigate('/dashboard')
            })
            .catch(function (error) {
                alert(error.message);
                setIsSaving(false);
            });
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            {budgetYear ? (
            <div className="m-2">
                <h4>Budget Year {budgetYear.year} {budgetYear.label.length > 0 ? (<span> &mdash; {budgetYear.label}</span>) : ''} &mdash; <em>Allocation</em></h4>
                <hr />
                <Form onSubmit={preventSubmit}>

                {departments ? (
                    <Table bordered size="sm" hover>
                        <thead>
                            <tr>
                                <th><h4>Cost Centres</h4></th>
                                <th className="text-center" style={{ width: "20%" }}><h4>Expense<br/>{formatter.format(totals['exp'])}</h4></th>
                                <th className="text-center" style={{ width: "20%" }}><h4>Capex<br/>{formatter.format(totals['capex'])}</h4></th>
                                <th className="text-center"><h4>Total<br/>{formatter.format(totals['total'])}</h4></th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(departments).map((cat) => (
                            <React.Fragment key={cat}>
                            <tr>
                                <td className="bg-secondary bg-gradient bg-opacity-25"><strong>{cat}</strong></td>
                                <td className="bg-secondary bg-gradient bg-opacity-25 text-center"><strong>{formatter.format(cat_totals[cat]['exp'])}</strong></td>
                                <td className="bg-secondary bg-gradient bg-opacity-25 text-center"><strong>{formatter.format(cat_totals[cat]['capex'])}</strong></td>
                                <td className="bg-secondary bg-gradient bg-opacity-25 text-center"><strong>{formatter.format(cat_totals[cat]['total'])}</strong></td>
                            </tr>
                            {departments[cat].map((d) => (
                            <tr key={d.id}>
                                <td>{d.department.name}</td>
                                <td>
                                    <InputGroup>
                                        <InputGroupText>$</InputGroupText>
                                        <Input id={d.id + 'exp_amount'} name={d.id + 'exp_amount'} placeholder="0" type="text"
                                            value={(allocation[d.id]) ? Math.round(allocation[d.id]['exp']).toLocaleString() : 0} onChange={(e) => setAllocated(d.department.category.name, d.id, 'exp', e.target.value)} />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup>
                                        <InputGroupText>$</InputGroupText>
                                        <Input id={d.id + 'capex_amount'} name={d.id + 'capex_amount'} placeholder="0" type="text"
                                            value={(allocation[d.id]) ? Math.round(allocation[d.id]['capex']).toLocaleString() : 0} onChange={(e) => setAllocated(d.department.category.name, d.id, 'capex', e.target.value)} />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup>
                                        <InputGroupText>$</InputGroupText>
                                        <Input id={d.id + 'allocated_amount'} name={d.id + 'allocated_amount'} placeholder="0" type="text" readOnly className="bg-secondary bg-opacity-10"
                                            value={((allocation[d.id]['exp'] ? parseInt(allocation[d.id]['exp']) : 0) + (allocation[d.id]['capex'] ? parseInt(allocation[d.id]['capex']) : 0)).toLocaleString()} />
                                    </InputGroup>   

                                </td>
                            </tr>
                            ))}
                            </React.Fragment>
                        ))}
                        </tbody>
                    </Table>
                    ) : null }

                    <FormGroup row>
                        <Col md={{ size: 12}} className="text-center">
                            {(isSaving) ? (
                                <span>
                                    <Spinner size="sm">Updating, please wait...</Spinner> <span>Updating, please wait...</span>
                                </span>
                            ) : (
                                <React.Fragment>
                                    <Button href={"/budget/dashboard"} variant="secondary">
                                        Cancel
                                    </Button>
                                    {' '}
                                    <Button type="button" id="Submit" name="Submit" color="primary" onClick={handleSubmit}>
                                        Update
                                    </Button>
                                </React.Fragment>
                            )}
                        </Col>
                    </FormGroup>

                </Form>
            </div>
            ) : (
                <div className="text-center mt-3">
                    <Spinner>Loading...</Spinner>
                    <p>Loading...</p>
                </div>
            )}
        </React.Fragment>
    );
}

export default BudgetYearAllocation;
