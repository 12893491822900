import Header from "components/HeaderComponent";
import Footer from "components/FooterComponent";
import Sidebar from "components/SidebarComponent";
import Login from "components/LoginComponent";
import ForgotPassword from "components/user/ForgotPasswordComponent";
import ResetPassword from "components/user/ResetPasswordComponent";
import ChangePassword from "components/user/ChangePasswordComponent";

import Dashboard from "components/DashboardComponent";
import { useState } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import PrivateRoute from "utils/PrivateRoute";
import { AuthContextProvider } from "context/AuthContext";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import BudgetHome from "components/budget/BudgetHomeComponent";
// import BudgetSummaryCombined from 'components/budget/BudgetSummaryCombinedComponent';

import ProfitAndLoss from "components/pnl/ProfitAndLossComponent";
import PnLExpense from "./pnl/PnLExpenseComponent";
import PnLDivision from "./pnl/PnLDivisionComponent";

import BudgetSummary from "components/budget/BudgetSummaryComponent";
import BudgetAdd from "components/budget/BudgetAddComponent";
import BudgetEdit from "components/budget/BudgetEditComponent";

import BudgetCategorySummaryComponent from "components/budget/BudgetCategorySummaryComponent";

import BudgetYearAdd from "components/budget/BudgetYearAddComponent";
import BudgetYearEdit from "components/budget/BudgetYearEditComponent";
import BudgetYearAllocation from "./budget/BudgetYearAllocationComponent";
import BudgetDepartmentEdit from "components/budget/BudgetDepartmentEditComponent";

import IncomeSummaryComponent from "./income/IncomeSummaryComponent";
import IncomeAdd from "components/income/IncomeAddComponent";
import IncomeEdit from "components/income/IncomeEditComponent";

import UserList from "components/user/UserListComponent";
import UserProfile from "components/user/UserProfileComponent";
import UserAdd from "components/user/UserAddComponent";
import UserEdit from "components/user/UserEditComponent";

import DepartmentList from "components/department/DepartmentListComponent";
import DepartmentAdd from "components/department/DepartmentAddComponent";
import DepartmentEdit from "components/department/DepartmentEditComponent";

import AccountList from "components/account/AccountListComponent";
import AccountAdd from "components/account/AccountAddComponent";
import AccountEdit from "components/account/AccountEditComponent";

import FundList from "components/fund/FundListComponent";
import FundAdd from "components/fund/FundAddComponent";
import FundEdit from "components/fund/FundEditComponent";

import PLCategoryList from "components/pl_category/PLCategoryListComponent";
import PLCategoryEdit from "components/pl_category/PLCategoryEditComponent";
import PLCategoryAdd from "components/pl_category/PLCategoryAddComponent";

import ItemList from "components/report/ItemListComponent";



library.add(faCircleMinus);

function Main(props) {
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  // const toggle = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="d-flex flex-column h-100">
      <AuthContextProvider>
        <Header />
        <main>
          {/* <Sidebar isOpen={sidebarOpen} toggle={toggle} /> */}
          <div className="d-flex flex-column flex-grow-1 h-100 w-100 p-3 bg-light">
            <Routes>
              <Route path="/" element={<PrivateRoute />}>

                <Route exact path="/" element={<Dashboard />} />
                
                <Route exact path="/dashboard" element={<Dashboard />} />

                {/* PROFIT AND LOSS PAGES */}
                <Route exact path="/budget_year/profit_and_loss/:budgetYearId" element={<ProfitAndLoss />} />
                <Route exact path="/budget_year/pnl_expense/:budgetYearId" element={<PnLExpense />} />
                <Route exact path="/budget_year/pnl_division/:budgetYearId" element={<PnLDivision />} />

                {/* REPORT PAGES */}
                <Route exact path="/report/item_list/:budgetYearId" element={<ItemList />} />

                {/* BUDGET PAGES */}
                <Route exact path="/budget_year/add" element={<BudgetYearAdd />} />
                <Route exact path="/budget_year/edit/:budgetYearId" element={<BudgetYearEdit />} />
                <Route exact path="/budget_year/allocation/:budgetYearId" element={<BudgetYearAllocation />} />
                
                <Route exact path="/budget_category/summary/:budgetYearId/:budgetDeptCatId" element={<BudgetCategorySummaryComponent />} />

                <Route exact path="/budget_department/edit/:budgetDepartmentId" element={<BudgetDepartmentEdit />} />
                
                <Route exact path="/budget/summary/:budgetDeptId" element={<BudgetSummary />} />
                <Route exact path="/budget/add/:budgetDeptId" element={<BudgetAdd />} />
                <Route exact path="/budget/edit/:budgetDeptId/:budgetId" element={<BudgetEdit />} />
                

                {/* INCOME PAGES */}
                <Route exact path="/income/summary/:budgetYearId" element={<IncomeSummaryComponent />} />
                <Route exact path="/income/add/:budgetYearId" element={<IncomeAdd />} />
                <Route exact path="/income/edit/:budgetYearId/:incomeId" element={<IncomeEdit />} />

                {/* ***********
                Admin Pages
                *********** */}

                {/* USER PAGES */}
                <Route exact path="/staff" element={<UserList />} />
                <Route exact path="/profile" element={<UserProfile />} />
                <Route exact path="/change_password" element={<ChangePassword />} />
                <Route exact path="/account/user/add" element={<UserAdd />} />
                <Route exact path="/account/user/edit/:staffId" element={<UserEdit />} />

                {/* DEPARTMENTS PAGES */}
                <Route exact path="/departments" element={<DepartmentList />} />
                <Route exact path="/department/add" element={<DepartmentAdd />} />
                <Route exact path="/department/edit/:deptId" element={<DepartmentEdit />} />

                {/* ACCOUNTS PAGES */}
                <Route exact path="/budget_accounts" element={<AccountList />} />
                <Route exact path="/budget_account/add" element={<AccountAdd />} />
                <Route exact path="/budget_account/edit/:accId" element={<AccountEdit />} />

                {/* FUNDS PAGES */}
                <Route exact path="/funds" element={<FundList />} />
                <Route exact path="/fund/add" element={<FundAdd />} />
                <Route exact path="/fund/edit/:fundId" element={<FundEdit />} />

                {/* PL CATEGORIES PAGES */}
                <Route exact path="/pl_categories/:plType" element={<PLCategoryList />} />
                <Route exact path="/pl_category/edit/:plCatId" element={<PLCategoryEdit />} />
                <Route exact path="/pl_category/add/:plCatLevel/:plCatType" element={<PLCategoryAdd />} />

              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot_password" element={<ForgotPassword />} />
              <Route path="/reset_password/:token" element={<ResetPassword />} />
              <Route path="*" element={<Navigate replace to="/login" />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </AuthContextProvider>
    </div>
  );
}

export default Main;
