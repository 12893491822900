import React, { useState, useEffect } from "react";
import {Row, Table, Spinner } from "reactstrap";
import {Link} from 'react-router-dom';
import 'assets/css/budgetsummary.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretUp, faCaretUp } from "@fortawesome/free-solid-svg-icons";

function ProfitAndLossCombined({pnl_data, formatter, pformatter}) {

  const[showIncome, setShowIncome] = useState(false);  
  // Toggle for income rows
  const toggleIncome = () => { setShowIncome(!showIncome) }

  // State for expenses rows
  const[showOpex, setShowOpex] = useState(false);  
  // Toggle for income rows
  const toggleOpex = () => { setShowOpex(!showOpex) }

  // State for capital expense rows
  const[showCapex, setShowCapex] = useState(false);  
  // Toggle for income rows
  const toggleCapex = () => { setShowCapex(!showCapex) }
  
  const[showOpexCC, setShowOpexCC] = useState([]);

  const toggleOpexCC = (cat_id) => {
    const updatedShowOpexCC = {...showOpexCC};
    updatedShowOpexCC[cat_id] = !showOpexCC[cat_id];
    setShowOpexCC(updatedShowOpexCC) 
  }

  const[showCapexCC, setShowCapexCC] = useState([]);

  const toggleCapexCC = (cat_id) => {
    const updatedShowCapexCC = {...showCapexCC};
    updatedShowCapexCC[cat_id] = !showCapexCC[cat_id];
    setShowCapexCC(updatedShowCapexCC) 
  }

  useEffect(() => {

    /* Initialize showOpexCC collapse state */
    const initShowOpexCC = [];
    Object.keys(pnl_data['expenses']['opex']).forEach((cat) => {
      initShowOpexCC[cat.id] = false;
    });
    setShowOpexCC(initShowOpexCC);

    /* Initialize showCapexCC collapse state */
    const initShowCapexCC = [];
    Object.keys(pnl_data['expenses']['capex']).forEach((cat) => {
      initShowCapexCC[cat.id] = false;
    });
    setShowCapexCC(initShowCapexCC);

  }, []);

  return (
    <React.Fragment>
      <Row className="m-1">
      </Row>
      <hr />
      {(pnl_data['incomes']) ? (
        <div className="tableFixHead">
        <Table id="budget-summary-combined" bordered size="sm">
          <thead className="bg-info">
            <tr>
              <th rowSpan={2} className="text-center" style={{minWidth: "200px"}}> </th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{pnl_data['year']}</th>
              <th colSpan={pnl_data['incomes']['used_funds'].length} className="text-center budget-summary-fund">Fund</th>
              <th colSpan={12} className="text-center budget-summary-month">Month</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{pnl_data['year_value']}</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{pnl_data['year_value']-1}<br/></th>
              <th rowSpan={2} className="text-center"> Difference</th>
              <th rowSpan={2} className="text-center"> Difference (%)</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Allocated<br />Budget Year<br/>{pnl_data['year_value']}</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Difference</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Difference (%)</th>
            </tr>
            <tr> 
              {pnl_data['incomes']['used_funds'].map((fund) => (
                <th key={`head${fund.id}`} className="text-center budget-summary-fund" style={{maxWidth: 80 + 'px'}}>{fund.code}<br/>{fund.description}</th>
              ))}
              {Array.from({length:12},(v,k)=>k+1).map((m) => (
                <th key={`head${m}`} className="text-center budget-summary-month" style={{ maxWidth: 80 + 'px' }}>{m}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          
          {/* INCOME */}
          {pnl_data['incomes']['incomes'] ? (
              <React.Fragment>
                {showIncome ? (
                  <React.Fragment>
                    <tr className="pnl-income-rows">
                      <td className="budget-summary-acc-cat" colSpan={21 + pnl_data['incomes']['used_funds'].length}><strong><em>** Income **</em></strong></td>
                    </tr>
                    {Object.keys(pnl_data['incomes']['incomes']).map((cat, index) => (
                      <tr key={`income_${pnl_data['incomes']['incomes'][cat]['cat_id']}`} className="pnl-income-rows">
                        <td>{pnl_data['incomes']['incomes'][cat]['income_category_name']}</td>
                        <td className="text-end">{formatter.format(pnl_data['incomes']['incomes'][cat]['total'])}</td>

                        {/* Fund columns */}
                        {pnl_data['incomes']['used_funds'].map((fund) => (
                          <td className="text-end budget-summary-fund" key={`income${fund.id}`}>
                            {pnl_data['incomes']['incomes'][cat]['funds'][fund.id] ? formatter.format(pnl_data['incomes']['incomes'][cat]['funds'][fund.id]['fund_total']) : ''}
                          </td>
                        ))}

                        {/* Months columns */}
                        {Array.from({length:12},(v,k)=>k+1).map((m) => (
                          <td className="text-end budget-summary-month" key={`income${m}`}>
                            {pnl_data['incomes']['incomes'][cat]['months'][m] ? formatter.format(pnl_data['incomes']['incomes'][cat]['months'][m]['month_total']) : ''}
                          </td>
                        ))}

                        <td className="text-end">{formatter.format(pnl_data['incomes']['incomes'][cat]['total'])}</td>
                        <td className="text-end">
                          {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'] && pnl_data['ly_incomes']['incomes'][cat]) ? (
                            formatter.format(pnl_data['ly_incomes']['incomes'][cat]['total'])
                          ) : '-'}
                        </td>
                        <td className="text-end">
                          {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'] && pnl_data['ly_incomes']['incomes'][cat]) ? (
                              formatter.format(pnl_data['incomes']['incomes'][cat]['total'] - pnl_data['ly_incomes']['incomes'][cat]['total'])
                            ) : '-'}
                        </td>
                        <td className="text-end">
                          {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'] && pnl_data['ly_incomes']['incomes'][cat]) ? (
                            (pnl_data['incomes']['incomes'][cat]['total'] - pnl_data['ly_incomes']['incomes'][cat]['total'] != 0) ? (
                              pformatter.format((pnl_data['incomes']['incomes'][cat]['total'] - pnl_data['ly_incomes']['incomes'][cat]['total'])*100/pnl_data['ly_incomes']['incomes'][cat]['total'])
                             + '%') : '0%') : '-'}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                </React.Fragment>
                ) : ''}
                <tr className="pnl-sub-total">
                  <td className="text-end">
                    <span className="float-start pnl-toggler" title="Expand Income" onClick={toggleIncome}><FontAwesomeIcon icon={faSquareCaretUp} /></span>
                    <strong><em>TOTAL&nbsp;
                    <Link className="text-decoration-none" title="Open Income Summary" to={`/income/summary/${pnl_data['budget_year_id']}`} target="_blank" style={{whiteSpace: "pre-wrap"}}>INCOME</Link>
                      </em></strong>                    
                  </td>
                  <td className="text-end"><strong>{formatter.format(pnl_data['incomes']['total'])}</strong></td>

                  {/* Fund columns */}
                  {pnl_data['incomes']['used_funds'].map((fund) => (
                    <td className="text-end" key={`income_total${fund.id}`}>
                      <strong>{pnl_data['incomes']['funds_total'][fund.id] ? formatter.format(pnl_data['incomes']['funds_total'][fund.id]) : ''}</strong>
                    </td>
                  ))}

                  {/* Months columns */}
                  {Array.from({length:12},(v,k)=>k+1).map((m) => (
                    <td className="text-end" key={`income_total${m}`}>
                      <strong>{pnl_data['incomes']['months_total'][m] ? formatter.format(pnl_data['incomes']['months_total'][m]) : ''}</strong>
                    </td>
                  ))}

                  <td className="text-end"><strong>{formatter.format(pnl_data['incomes']['total'])}</strong></td>
                  <td className="text-end">
                    <strong>
                    {(pnl_data['ly_incomes']) ? (
                      formatter.format(pnl_data['ly_incomes']['total'])
                    ) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                    {(pnl_data['ly_incomes']) ? (
                      formatter.format(pnl_data['incomes']['total'] - pnl_data['ly_incomes']['total'])
                    ) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                    {(pnl_data['ly_incomes']) ? (
                        (pnl_data['incomes']['total'] - pnl_data['ly_incomes']['total'] != 0) ? (
                          pformatter.format((pnl_data['incomes']['total'] - pnl_data['ly_incomes']['total'])*100/pnl_data['ly_incomes']['total'])
                         + '%') : '0%') : '-'}
                    </strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </React.Fragment>
          ) : 'no incomes'}


          {/* DIVIDER */}
          <tr><td colSpan={21 + pnl_data['incomes']['used_funds'].length}>&nbsp;</td></tr>


          {/* EXPENSES */}
          {pnl_data['expenses']['opex'] ? (
              <React.Fragment>
                {showOpex ? (
                  <React.Fragment>
                    <tr className="pnl-income-rows">
                      <td className="budget-summary-acc-cat" colSpan={21 + pnl_data['incomes']['used_funds'].length}><strong><em>** Expenses **</em></strong></td>
                    </tr>
                    {Object.keys(pnl_data['expenses']['opex']).map((cat, index) => (
                      
                      <React.Fragment key={`opex_${pnl_data['expenses']['opex'][cat]['id']}`}>
                        {showOpexCC[pnl_data['expenses']['opex'][cat]['id']] ? (
                          <React.Fragment>
                            <tr>
                              <td colSpan={21 + pnl_data['incomes']['used_funds'].length}>{pnl_data['expenses']['opex'][cat]['cat_name']}</td>
                            </tr>
                          
                            {Object.keys(pnl_data['expenses']['opex'][cat]['departments']).map((dept, dept_i) => (
                              <tr key={`cc_${dept_i}`}>
                                <td>
                                  &bull;&nbsp;
                                  <Link className="text-decoration-none" title={`Open ${dept} Cost Centre Summary`} to={`/budget/summary/${pnl_data['expenses']['opex'][cat]['departments'][dept]['id']}`} target="_blank" style={{whiteSpace: "pre-wrap"}}>{dept}</Link>
                                </td>
                                <td className="text-end">{formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'])}</td>
                                
                                {/* Fund columns */}
                                {pnl_data['incomes']['used_funds'].map((fund) => (
                                <td className="text-end budget-summary-fund" key={`cc_${fund.id}`}>
                                  {pnl_data['expenses']['opex'][cat]['departments'][dept]['funds'][fund.id] ? formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['funds'][fund.id]) : ''}
                                </td>
                                ))}

                                {/* Months columns */}
                                {Array.from({length:12},(v,k)=>k+1).map((m) => (
                                  <td className="text-end budget-summary-month" key={`cc_${m}`}>
                                    {pnl_data['expenses']['opex'][cat]['departments'][dept]['months'][m] ? formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['months'][m]) : ''}
                                  </td>
                                ))}

                                <td className="text-end">{formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'])}</td>

                                <td className="text-end">
                                  {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['opex'] && pnl_data['ly_expenses']['opex'][cat] && pnl_data['ly_expenses']['opex'][cat]['departments'][dept]) ? (
                                    formatter.format(pnl_data['ly_expenses']['opex'][cat]['departments'][dept]['dept_total'])
                                  ) : '-'}
                                </td>
                                <td className="text-end">
                                {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['opex'] && pnl_data['ly_expenses']['opex'][cat] && pnl_data['ly_expenses']['opex'][cat]['departments'][dept]) ? (
                                    formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] - pnl_data['ly_expenses']['opex'][cat]['departments'][dept]['dept_total'])
                                  ) : '-'}
                                </td>
                                <td className="text-end">
                                  {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['opex'] && pnl_data['ly_expenses']['opex'][cat] && pnl_data['ly_expenses']['opex'][cat]['departments'][dept]) ? (
                                    (pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] - pnl_data['ly_expenses']['opex'][cat]['departments'][dept]['dept_total'] != 0) ? (
                                  pformatter.format((pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] - pnl_data['ly_expenses']['opex'][cat]['departments'][dept]['dept_total'])*100/pnl_data['ly_expenses']['opex'][cat]['departments'][dept]['dept_total'])
                                  + '%') : '0%') : '-'}
                                </td>

                                <td className="text-end"><strong>{(pnl_data['expenses']['opex'][cat]['departments'][dept]['allocated_expense'] > 0) ? formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['allocated_expense']) : "-"}</strong></td>
                                <td className="text-end">
                                  <strong>
                                    {(pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] && pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] > 0) ? 
                                      formatter.format(pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] - pnl_data['expenses']['opex'][cat]['departments'][dept]['allocated_expense']) : '-'}
                                  </strong>
                                </td>
                                <td className="text-end">
                                  <strong>
                                    {(pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] && 
                                      pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] > 0 && 
                                      pnl_data['expenses']['opex'][cat]['departments'][dept]['allocated_expense'] !== 0) ? (
                                      pformatter.format((pnl_data['expenses']['opex'][cat]['departments'][dept]['dept_total'] - pnl_data['expenses']['opex'][cat]['departments'][dept]['allocated_expense'])*100/pnl_data['expenses']['opex'][cat]['departments'][dept]['allocated_expense']) + "%"
                                    ) : '-'}
                                  </strong>
                                </td>

                              </tr>
                            ))}
                          </React.Fragment>
                          ) : ''}

                        <tr className="pnl-sub-sub-total">
                          <td className="text-end">
                            <span className="float-start pnl-toggler" onClick={() => toggleOpexCC(pnl_data['expenses']['opex'][cat]['id'])}>
                              <FontAwesomeIcon icon={faCaretUp} />
                            </span>&nbsp;
                            <strong><em>Sub Total for&nbsp;
                              <Link className="text-decoration-none" title={`Open ${pnl_data['expenses']['opex'][cat]['cat_name']} Summary`} to={`/budget_category/summary/${pnl_data['budget_year_id']}/${pnl_data['expenses']['opex'][cat]['id']}`} target="_blank" style={{whiteSpace: "pre-wrap"}}>{pnl_data['expenses']['opex'][cat]['cat_name']}</Link>
                              </em></strong>
                          </td>
                          <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['opex'][cat]['cat_total'])}</strong></td>

                          {/* Fund columns */}
                          {pnl_data['incomes']['used_funds'].map((fund) => (
                            <td className="text-end" key={`opex_subtotal${fund.id}`}>
                              <strong>{pnl_data['expenses']['opex'][cat]['cat_funds_total'][fund.id] ? formatter.format(pnl_data['expenses']['opex'][cat]['cat_funds_total'][fund.id]) : ''}</strong>
                            </td>
                          ))}

                          {/* Months columns */}
                          {Array.from({length:12},(v,k)=>k+1).map((m) => (
                            <td className="text-end" key={`opex_subtotal${m}`}>
                              <strong>{pnl_data['expenses']['opex'][cat]['cat_months_total'][m] ? formatter.format(pnl_data['expenses']['opex'][cat]['cat_months_total'][m]) : ''}</strong>
                            </td>
                          ))}

                          <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['opex'][cat]['cat_total'])}</strong></td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['opex'] && pnl_data['ly_expenses']['opex'][cat]) ? (
                                formatter.format(pnl_data['ly_expenses']['opex'][cat]['cat_total'])
                              ) : '-'}
                            </strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['opex'] && pnl_data['ly_expenses']['opex'][cat]) ? (
                                formatter.format(pnl_data['expenses']['opex'][cat]['cat_total'] - pnl_data['ly_expenses']['opex'][cat]['cat_total'])
                              ) : '-'}
                            </strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['opex'] && pnl_data['ly_expenses']['opex'][cat]) ? (
                                (pnl_data['expenses']['opex'][cat]['cat_total'] - pnl_data['ly_expenses']['opex'][cat]['cat_total'] != 0) ? (
                              pformatter.format((pnl_data['expenses']['opex'][cat]['cat_total'] - pnl_data['ly_expenses']['opex'][cat]['cat_total'])*100/pnl_data['ly_expenses']['opex'][cat]['cat_total'])
                              + '%') : '0%') : '-'}
                            </strong>
                          </td>

                          <td className="text-end"><strong>{(pnl_data['expenses']['opex'][cat]['allocated_expense_total'] > 0) ? formatter.format(pnl_data['expenses']['opex'][cat]['allocated_expense_total']) : "-"}</strong></td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['expenses']['opex'][cat]['cat_total'] && pnl_data['expenses']['opex'][cat]['cat_total'] > 0) ? 
                                formatter.format(pnl_data['expenses']['opex'][cat]['cat_total'] - pnl_data['expenses']['opex'][cat]['allocated_expense_total']) : '-'}
                            </strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['expenses']['opex'][cat]['cat_total'] && 
                                pnl_data['expenses']['opex'][cat]['cat_total'] > 0 && 
                                pnl_data['expenses']['opex'][cat]['allocated_expense_total'] !== 0) ? (
                                pformatter.format((pnl_data['expenses']['opex'][cat]['cat_total'] - pnl_data['expenses']['opex'][cat]['allocated_expense_total'])*100/pnl_data['expenses']['opex'][cat]['allocated_expense_total']) + "%"
                              ) : '-'}
                            </strong>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </React.Fragment>
                ) : ''}
                <tr className="pnl-sub-total">
                  <td className="text-end">
                    <span className="float-start pnl-toggler" onClick={toggleOpex}><FontAwesomeIcon icon={faSquareCaretUp} /></span>
                    <strong><em>TOTAL EXPENSES</em></strong>
                  </td>
                  <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['opex_total'])}</strong></td>

                  {/* Fund columns */}
                  {pnl_data['incomes']['used_funds'].map((fund) => (
                    <td className="text-end" key={`opex_total${fund.id}`}>
                      <strong>{pnl_data['expenses']['opex_funds_total'][fund.id] ? formatter.format(pnl_data['expenses']['opex_funds_total'][fund.id]) : ''}</strong>
                    </td>
                  ))}

                  {/* Months columns */}
                  {Array.from({length:12},(v,k)=>k+1).map((m) => (
                    <td className="text-end" key={`opex_total${m}`}>
                      <strong>{pnl_data['expenses']['opex_months_total'][m] ? formatter.format(pnl_data['expenses']['opex_months_total'][m]) : ''}</strong>
                    </td>
                  ))}

                  <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['opex_total'])}</strong></td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['ly_expenses']) ? formatter.format(pnl_data['ly_expenses']['opex_total']) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['ly_expenses']) ? formatter.format(pnl_data['expenses']['opex_total'] - pnl_data['ly_expenses']['opex_total']) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['ly_expenses']) ? (
                        (pnl_data['expenses']['opex_total'] - pnl_data['ly_expenses']['opex_total'] != 0) ?
                      pformatter.format((pnl_data['expenses']['opex_total'] - pnl_data['ly_expenses']['opex_total'])*100/pnl_data['ly_expenses']['opex_total']) + '%'
                      : '0%') : '-'}
                    </strong>
                  </td>

                  <td className="text-end"><strong>{(pnl_data['expenses']['opex_allocated_total'] > 0) ? formatter.format(pnl_data['expenses']['opex_allocated_total']) : "-"}</strong></td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['expenses']['opex_total'] && pnl_data['expenses']['opex_total'] > 0) ? 
                        formatter.format(pnl_data['expenses']['opex_total'] - pnl_data['expenses']['opex_allocated_total']) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['expenses']['opex_total'] && 
                        pnl_data['expenses']['opex_total'] > 0 && 
                        pnl_data['expenses']['opex_allocated_total'] !== 0) ? (
                        pformatter.format((pnl_data['expenses']['opex_total'] - pnl_data['expenses']['opex_allocated_total'])*100/pnl_data['expenses']['opex_allocated_total']) + "%"
                      ) : '-'}
                    </strong>
                  </td>

                </tr>
              </React.Fragment>
          ) : 'no operating expenses'}


          {/* DIVIDER */}
          <tr><td colSpan={21 + pnl_data['incomes']['used_funds'].length}>&nbsp;</td></tr>


          {/* SURPLUS DEFICIT */}
          <React.Fragment>
            <tr className="pnl-sub-total">
              <td className="text-end">
                <strong><em>SURPLUS/(DEFICIT)</em></strong>
              </td>
              <td className="text-end"><strong>{formatter.format(pnl_data['surplus_deficit']['amount'])}</strong></td>

              {/* Fund columns */}
              {pnl_data['incomes']['used_funds'].map((fund) => (
                <td className="text-end" key={`surplus_deficit_${fund.id}`}>
                  <strong>{pnl_data['surplus_deficit']['sd_funds'][fund.id] ? 
                    formatter.format(pnl_data['surplus_deficit']['sd_funds'][fund.id]) : ''}
                    </strong>
                </td>
              ))}

              {/* Months columns */}
              {Array.from({length:12},(v,k)=>k+1).map((m) => (
                <td className="text-end" key={`surplus_deficit_${m}`}>
                  <strong>{pnl_data['surplus_deficit']['sd_months'][m] ? 
                    formatter.format(pnl_data['surplus_deficit']['sd_months'][m]) : ''}
                    </strong>
                </td>
              ))}

              <td className="text-end"><strong>{formatter.format(pnl_data['surplus_deficit']['amount'])}</strong></td>
              <td className="text-end">
                <strong>{(pnl_data['ly_surplus_deficit']) ? formatter.format(pnl_data['ly_surplus_deficit']['amount']) : '-'}</strong>
              </td>
              <td className="text-end">
                <strong>
                  {(pnl_data['ly_surplus_deficit']) ? 
                  formatter.format(pnl_data['surplus_deficit']['amount'] - pnl_data['ly_surplus_deficit']['amount']) : '-'}
                </strong>
              </td>
              <td className="text-end">
                <strong>
                  {(pnl_data['ly_surplus_deficit']) ? (
                    (pnl_data['surplus_deficit']['amount'] - pnl_data['ly_surplus_deficit']['amount'] != 0) ?
                    pformatter.format((pnl_data['surplus_deficit']['amount'] - pnl_data['ly_surplus_deficit']['amount'])*100/pnl_data['ly_surplus_deficit']['amount']) + '%'
                    : '0%'
                  ) : '-'}
                </strong>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </React.Fragment>

          {/* DIVIDER */}
          <tr><td colSpan={21 + pnl_data['incomes']['used_funds'].length}>&nbsp;</td></tr>


          {/* CAPITAL EXPENDITURE */}
          {pnl_data['expenses']['capex'] ? (
              <React.Fragment>
                {showCapex ? (
                  <React.Fragment>
                    <tr className="pnl-income-rows">
                      <td className="budget-summary-acc-cat" colSpan={21 + pnl_data['incomes']['used_funds'].length}><strong><em>** Capital Expenditure **</em></strong></td>
                    </tr>
                    {Object.keys(pnl_data['expenses']['capex']).map((cat, index) => (

                      <React.Fragment key={`capex_${pnl_data['expenses']['capex'][cat]['id']}`}>
                        {showCapexCC[pnl_data['expenses']['capex'][cat]['id']] ? (
                          <React.Fragment>
                            <tr>
                              <td colSpan={21 + pnl_data['incomes']['used_funds'].length}>{pnl_data['expenses']['capex'][cat]['cat_name']}</td>
                            </tr>

                            {Object.keys(pnl_data['expenses']['capex'][cat]['departments']).map((dept, dept_i) => (
                              <tr key={`capex_cc_${pnl_data['expenses']['capex'][cat]['departments'][dept]['id']}`}>
                                <td>&bull;&nbsp;
                                  <Link className="text-decoration-none" title={`Open ${dept} Cost Centre Summary`} to={`/budget/summary/${pnl_data['expenses']['capex'][cat]['departments'][dept]['id']}`} target="_blank" style={{whiteSpace: "pre-wrap"}}>{dept}</Link>
                                </td>
                                <td className="text-end">{formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'])}</td>
                                
                                {/* Fund columns */}
                                {pnl_data['incomes']['used_funds'].map((fund) => (
                                <td className="text-end budget-summary-fund" key={`capex_cc_${fund.id}`}>
                                  {pnl_data['expenses']['capex'][cat]['departments'][dept]['funds'][fund.id] ? formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['funds'][fund.id]) : ''}
                                </td>
                                ))}

                                {/* Months columns */}
                                {Array.from({length:12},(v,k)=>k+1).map((m) => (
                                  <td className="text-end budget-summary-month" key={`capex_cc_${m}`}>
                                    {pnl_data['expenses']['capex'][cat]['departments'][dept]['months'][m] ? formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['months'][m]) : ''}
                                  </td>
                                ))}

                                <td className="text-end">{formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'])}</td>

                                <td className="text-end">
                                  {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['capex'] && pnl_data['ly_expenses']['capex'][cat] && pnl_data['ly_expenses']['capex'][cat]['departments'][dept]) ? (
                                    formatter.format(pnl_data['ly_expenses']['capex'][cat]['departments'][dept]['dept_total'])
                                  ) : '-'}
                                </td>
                                <td className="text-end">
                                {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['capex'] && pnl_data['ly_expenses']['capex'][cat] && pnl_data['ly_expenses']['capex'][cat]['departments'][dept]) ? (
                                    formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] - pnl_data['ly_expenses']['capex'][cat]['departments'][dept]['dept_total'])
                                  ) : '-'}
                                </td>
                                <td className="text-end">
                                  {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['capex'] && pnl_data['ly_expenses']['capex'][cat] && pnl_data['ly_expenses']['capex'][cat]['departments'][dept]) ? (
                                    (pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] - pnl_data['ly_expenses']['capex'][cat]['departments'][dept]['dept_total'] != 0) ? (
                                  pformatter.format((pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] - pnl_data['ly_expenses']['capex'][cat]['departments'][dept]['dept_total'])*100/pnl_data['ly_expenses']['capex'][cat]['departments'][dept]['dept_total'])
                                  + '%') : '0%') : '-'}
                                </td>

                                <td className="text-end"><strong>{(pnl_data['expenses']['capex'][cat]['departments'][dept]['allocated_capex'] > 0) ? formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['allocated_capex']) : "-"}</strong></td>
                                <td className="text-end">
                                  <strong>
                                    {(pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] && pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] > 0) ? 
                                      formatter.format(pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] - pnl_data['expenses']['capex'][cat]['departments'][dept]['allocated_capex']) : '-'}
                                  </strong>
                                </td>
                                <td className="text-end">
                                  <strong>
                                    {(pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] && 
                                      pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] > 0 && 
                                      pnl_data['expenses']['capex'][cat]['departments'][dept]['allocated_capex'] !== 0) ? (
                                      pformatter.format((pnl_data['expenses']['capex'][cat]['departments'][dept]['dept_total'] - pnl_data['expenses']['capex'][cat]['departments'][dept]['allocated_capex'])*100/pnl_data['expenses']['capex'][cat]['departments'][dept]['allocated_capex']) + "%"
                                    ) : '-'}
                                  </strong>
                                </td>

                              </tr>
                            ))}

                          </React.Fragment>
                        ) : ''}

                        <tr className="pnl-sub-sub-total">
                          <td className="text-end">
                            <span className="float-start pnl-toggler" onClick={() => toggleCapexCC(pnl_data['expenses']['capex'][cat]['id'])}>
                              <FontAwesomeIcon icon={faCaretUp} />
                            </span>&nbsp;
                            <strong><em>Sub Total for&nbsp;
                              <Link className="text-decoration-none" title={`Open ${pnl_data['expenses']['capex'][cat]['cat_name']} Summary`} to={`/budget_category/summary/${pnl_data['budget_year_id']}/${pnl_data['expenses']['capex'][cat]['id']}`} target="_blank" style={{whiteSpace: "pre-wrap"}}>{pnl_data['expenses']['capex'][cat]['cat_name']}</Link>
                              </em></strong>
                          </td>
                          <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['capex'][cat]['cat_total'])}</strong></td>

                          {/* Fund columns */}
                          {pnl_data['incomes']['used_funds'].map((fund) => (
                            <td className="text-end" key={`capex_fund_${fund.id}`}>
                              <strong>{pnl_data['expenses']['capex'][cat]['cat_funds_total'][fund.id] ? formatter.format(pnl_data['expenses']['capex'][cat]['cat_funds_total'][fund.id]) : ''}</strong>
                            </td>
                          ))}

                          {/* Months columns */}
                          {Array.from({length:12},(v,k)=>k+1).map((m) => (
                            <td className="text-end" key={`capex_month_${m}`}>
                              <strong>{pnl_data['expenses']['capex'][cat]['cat_months_total'][m] ? formatter.format(pnl_data['expenses']['capex'][cat]['cat_months_total'][m]) : ''}</strong>
                            </td>
                          ))}

                          <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['capex'][cat]['cat_total'])}</strong></td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['capex'] && pnl_data['ly_expenses']['capex'][cat]) ? 
                              formatter.format(pnl_data['ly_expenses']['capex'][cat]['cat_total']) : '-'}</strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['capex'] && pnl_data['ly_expenses']['capex'][cat]) ? 
                              formatter.format(pnl_data['expenses']['capex'][cat]['cat_total'] - pnl_data['ly_expenses']['capex'][cat]['cat_total']) : '-'}
                            </strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['ly_expenses'] && pnl_data['ly_expenses']['capex'] && pnl_data['ly_expenses']['capex'][cat]) ? (
                                (pnl_data['expenses']['capex'][cat]['cat_total'] - pnl_data['ly_expenses']['capex'][cat]['cat_total'] != 0) ? (
                              pformatter.format((pnl_data['expenses']['capex'][cat]['cat_total'] - pnl_data['ly_expenses']['capex'][cat]['cat_total'])*100/pnl_data['ly_expenses']['capex'][cat]['cat_total']) + '%') : '0%') : '-'}
                            </strong>
                          </td>

                          <td className="text-end"><strong>{(pnl_data['expenses']['capex'][cat]['allocated_capex_total'] > 0) ? formatter.format(pnl_data['expenses']['capex'][cat]['allocated_capex_total']) : "-"}</strong></td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['expenses']['capex'][cat]['cat_total'] && pnl_data['expenses']['capex'][cat]['cat_total'] > 0) ? 
                                formatter.format(pnl_data['expenses']['capex'][cat]['cat_total'] - pnl_data['expenses']['capex'][cat]['allocated_capex_total']) : '-'}
                            </strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              {(pnl_data['expenses']['capex'][cat]['cat_total'] && 
                                pnl_data['expenses']['capex'][cat]['cat_total'] > 0 && 
                                pnl_data['expenses']['capex'][cat]['allocated_capex_total'] !== 0) ? (
                                pformatter.format((pnl_data['expenses']['capex'][cat]['cat_total'] - pnl_data['expenses']['capex'][cat]['allocated_capex_total'])*100/pnl_data['expenses']['capex'][cat]['allocated_capex_total']) + "%"
                              ) : '-'}
                            </strong>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </React.Fragment>
                ) : ''}
                <tr className="pnl-sub-total">
                  <td className="text-end">
                    <span className="float-start pnl-toggler" onClick={toggleCapex}><FontAwesomeIcon icon={faSquareCaretUp} /></span>
                    <strong><em>TOTAL CAPITAL EXPENDITURE</em></strong>
                  </td>
                  <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['capex_total'])}</strong></td>

                  {/* Fund columns */}
                  {pnl_data['incomes']['used_funds'].map((fund) => (
                    <td className="text-end" key={`capex_fund_total_${fund.id}`}>
                      <strong>{pnl_data['expenses']['capex_funds_total'][fund.id] ? formatter.format(pnl_data['expenses']['capex_funds_total'][fund.id]) : ''}</strong>
                    </td>
                  ))}

                  {/* Months columns */}
                  {Array.from({length:12},(v,k)=>k+1).map((m) => (
                    <td className="text-end" key={`capex_month_total_${m}`}>
                      <strong>{pnl_data['expenses']['capex_months_total'][m] ? formatter.format(pnl_data['expenses']['capex_months_total'][m]) : ''}</strong>
                    </td>
                  ))}

                  <td className="text-end"><strong>{formatter.format(pnl_data['expenses']['capex_total'])}</strong></td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['ly_expenses']) ? formatter.format(pnl_data['ly_expenses']['capex_total']) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['ly_expenses']) ? formatter.format(pnl_data['expenses']['capex_total'] - pnl_data['ly_expenses']['capex_total']) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['ly_expenses']) ? (
                        (pnl_data['expenses']['capex_total'] - pnl_data['ly_expenses']['capex_total'] != 0) ?
                      pformatter.format((pnl_data['expenses']['capex_total'] - pnl_data['ly_expenses']['capex_total'])*100/pnl_data['ly_expenses']['capex_total']) + '%'
                      : '0%') : '-'}
                    </strong>
                  </td>

                  <td className="text-end"><strong>{(pnl_data['expenses']['capex_allocated_total'] > 0) ? formatter.format(pnl_data['expenses']['capex_allocated_total']) : "-"}</strong></td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['expenses']['capex_total'] && pnl_data['expenses']['capex_total'] > 0) ? 
                        formatter.format(pnl_data['expenses']['capex_total'] - pnl_data['expenses']['capex_allocated_total']) : '-'}
                    </strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      {(pnl_data['expenses']['capex_total'] && 
                        pnl_data['expenses']['capex_total'] > 0 && 
                        pnl_data['expenses']['capex_allocated_total'] !== 0) ? (
                        pformatter.format((pnl_data['expenses']['capex_total'] - pnl_data['expenses']['capex_allocated_total'])*100/pnl_data['expenses']['capex_allocated_total']) + "%"
                      ) : '-'}
                    </strong>
                  </td>

                </tr>
              </React.Fragment>
          ) : 'no capital expenses'}

          {/* DIVIDER */}
          <tr><td colSpan={21 + pnl_data['incomes']['used_funds'].length}>&nbsp;</td></tr>


          {/* NET SURPLUS DEFICIT */}
          <React.Fragment>
            <tr className="pnl-sub-total">
              <td className="text-end">
                <strong><em>NET SURPLUS/(DEFICIT)</em></strong>
              </td>
              <td className="text-end"><strong>
                {(pnl_data['net_surplus_deficit']['amount'] < 0) ? 
                formatter.format(pnl_data['net_surplus_deficit']['amount']) : ''}
                </strong></td>

              {/* Fund columns */}
              {pnl_data['incomes']['used_funds'].map((fund) => (
                <td className="text-end" key={`net_surplus_deficit_${fund.id}`}>
                  <strong>{pnl_data['net_surplus_deficit']['sd_funds'][fund.id] ? 
                    formatter.format(pnl_data['net_surplus_deficit']['sd_funds'][fund.id]) : ''}
                    </strong>
                </td>
              ))}

              {/* Months columns */}
              {Array.from({length:12},(v,k)=>k+1).map((m) => (
                <td className="text-end" key={`net_surplus_deficit_${m}`}>
                  <strong>{pnl_data['net_surplus_deficit']['sd_months'][m] ? 
                    formatter.format(pnl_data['net_surplus_deficit']['sd_months'][m]) : ''}
                    </strong>
                </td>
              ))}

              <td className="text-end"><strong>{formatter.format(pnl_data['net_surplus_deficit']['amount'])}</strong></td>
              <td className="text-end">
                <strong>{(pnl_data['ly_net_surplus_deficit']) ? formatter.format(pnl_data['ly_net_surplus_deficit']['amount']) : '-'}</strong>
              </td>
              <td className="text-end">
                <strong>
                  {(pnl_data['ly_net_surplus_deficit']) ? 
                  formatter.format(pnl_data['net_surplus_deficit']['amount'] - pnl_data['ly_net_surplus_deficit']['amount']) : '-'}
                </strong>
              </td>
              <td className="text-end">
                <strong>
                  {(pnl_data['ly_net_surplus_deficit']) ? (
                    (pnl_data['net_surplus_deficit']['amount'] - pnl_data['ly_net_surplus_deficit']['amount'] != 0) ?
                    pformatter.format((pnl_data['net_surplus_deficit']['amount'] - pnl_data['ly_net_surplus_deficit']['amount'])*100/pnl_data['ly_net_surplus_deficit']['amount']) + '%'
                    : '0%'
                  ) : '-'}
                </strong>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </React.Fragment>


          </tbody>

        </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default ProfitAndLossCombined;