import React, { useState, useEffect, useContext } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Spinner, Table, } from "reactstrap";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCircleMinus, } from "@fortawesome/free-solid-svg-icons";

function AccountList(props) {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState("");

  const api = useAxios();

  const date_formatter = new Intl.DateTimeFormat("en-SG", {
    dateStyle: "medium",
    timeStyle: "medium",
  });

  useEffect(() => {
    const ACCOUNTS_DATA_URL = process.env.REACT_APP_API_URL + "/budget_account/";
    const fetchData = async () => {
      try {
        const result = await api.get(ACCOUNTS_DATA_URL);
        setAccounts(result.data)
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {!user.is_portal_admin ? (
            <Navigate to="/dashboard" />
          ) : (
            <div>
              <h4>Accounts</h4>

              <p> <Link className="btn btn-secondary btn-sm me-1" to="/budget_account/add" > Add New Account </Link> </p>
              {accounts.length > 0 ? (
                <React.Fragment>
                  <Table id="account-list" bordered responsive size="sm">
                    <thead className="bg-info">
                      <tr>
                        <th>ID</th>
                        <th>Account Number</th>
                        <th>Description</th>
                        <th>Category</th>
                        <th>P&L Category (level 4)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts.map((a) => (
                        <tr key={a.id}>
                          <td><Link className="ms-1" to={`/budget_account/edit/${a.id}`} >{a.id}</Link></td>
                          <td>{a.account_number}</td>
                          <td>{a.description}</td>
                          <td>{a.category.name}</td>
                          <td>{a.pl_category.name}</td>
                          <td>
                            <Link className="ms-1" to={`/budget_account/edit/${a.id}`} > <FontAwesomeIcon icon={faPenToSquare} /> Edit </Link>&nbsp;&nbsp;&nbsp;
                            {/* <Link className="ms-1 text-danger" to={`/department/delete/${d.id}`} > <FontAwesomeIcon icon={faCircleMinus} /> Deactivate</Link> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              ) : (
                <div>No account has been created.</div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default AccountList;
