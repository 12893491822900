import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "context/AuthContext";


const PrivateRoute = () => {
    let { user }  = useContext(AuthContext);
    const location = useLocation();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return user ? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />;
}

export default PrivateRoute;