import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";

const API_TOKEN_URL = process.env.REACT_APP_API_URL + "/account/token/"

const AuthContext = createContext();

export default AuthContext;

export const AuthContextProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const location = useLocation();

  const loginUser = async (username, password) => {
    const response = await fetch(API_TOKEN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password
      })
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      // console.log(JSON.stringify(jwt_decode(data.access), null, 2));
      localStorage.setItem("authTokens", JSON.stringify(data));

      const origin = location.state?.from?.pathname || '/dashboard';
      navigate(origin);
      // navigate('/dashboard')
    } else {
      alert("Login failed. Please enter a valid username and password");
    }
  };
  
//   const registerUser = async (username, password, password2) => {
//     const response = await fetch("http://127.0.0.1:8000/api/register/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify({
//         username,
//         password,
//         password2
//       })
//     });
//     if (response.status === 201) {
//       history.push("/login");
//     } else {
//       alert("Something went wrong!");
//     }
//   };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate('/');
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    // registerUser,
    loginUser,
    logoutUser
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};