import React, { useState, useEffect, useContext } from "react";

import useAxios from "utils/useAxios";

import { Form, FormGroup, Label, Col, Input, Button, Spinner, } from "reactstrap";
import AuthContext from "context/AuthContext";


function ChangePassword(props) {

  const { user, logoutUser } = useContext(AuthContext);

  const api = useAxios();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [requirement, setRequirement] = useState([]);

  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");


  useEffect(() => {

    const CHANGE_PASSWORD_URL =  process.env.REACT_APP_API_URL + "/account/change_password";
    const fetchData = async () => {
      try {
        const result = await api.get(CHANGE_PASSWORD_URL);
        setRequirement(result.data.requirement);
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }  
    }
    fetchData();
  }, []);

  function validate() {
    if (!old_password) {
      alert("Please enter your current password");
      return false;
    }
    if (!password) {
      alert("Please enter your new password");
      return false;
    }
    if (password !== password2) {
      alert("Password doesn't match");
      return false;
    }
    return true;
  }

  function handleSubmit(event) {
    setIsSaving(true);
    const POST_URL = process.env.REACT_APP_API_URL + "/account/change_password";
    if (validate()) {
        api.post(POST_URL, {
            'old_password': old_password,
            'password': password,
        })
        .then(async function (response) {
            alert(response.data.message);
            if (!response.data.error) {
              logoutUser()
            } else {
              setError(response.data.error)
            }
        })
        .catch(function (error) {
            alert(error.message);
        });
    }
    setIsSaving(false);
    event.preventDefault();
}

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          <div className="m-2">
            <h4>Change Password</h4>
            <hr />
            <Form onSubmit={handleSubmit}>
              {requirement ? (
                <FormGroup row>
                  <Col md={{ size: 10, offset: 2 }}>
                  <h5>Password requirements</h5>
                  <ul>
                    {requirement.map((r, i) => (
                      <li key={i}>{r}</li>
                    ))}
                  </ul>
                  </Col>
                </FormGroup>
              ) : ''}
              {error ? (
                <FormGroup row>
                  <Col md={{ size: 10, offset: 2 }} className="text-danger">
                  <h6>Validation errors</h6>
                  <ul>
                    {error.map((e) => (
                      <li>{e}</li>
                    ))}
                  </ul>
                  </Col>
                </FormGroup>
              ) : ''}
              <FormGroup row>
                <Label for="old_password" sm={2}>
                  Enter your current password
                </Label>
                <Col sm={4}>
                  <Input id="old_password" name="old_password" placeholder="Your current password" type="password" value={old_password} onChange={(e) => setOldPassword(e.target.value)} />{" "} </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="password" sm={2}>
                  Enter a new password
                </Label>
                <Col sm={4}>
                  <Input id="password" name="password" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />{" "} </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="password" sm={2}>
                  Enter the new password again
                </Label>
                <Col sm={4}>
                  <Input id="password2" name="password2" placeholder="Verify password" type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />{" "} </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={{ size: 10, offset: 2 }}>
                  {isSaving ? (
                    <span> <Spinner size="sm"> Changing password, please wait... </Spinner>{" "} <span>Changing password, please wait...</span> </span> ) : (
                    <Button type="submit" id="Submit" name="Submit" color="primary" > Submit </Button> )}
                </Col>
              </FormGroup>
            </Form>
          </div>
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default ChangePassword;
