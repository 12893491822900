import React, { useState } from "react";
import { Form, FormGroup, Label, Col, Input, Button, Spinner, UncontrolledAlert} from "reactstrap";

function ForgotPassword(props) {

  const [isSaving, setIsSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // The current location.
  console.log(window.location);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  function validate() {
    if (!username) {
        alert("Please enter your username");
        return false;
    }
    if (!email) {
        alert("Please your email address");
        return false;
    }
    return true;
  }

  const request_password_reset = async (username, email, url) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/account/forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        email,
        url
      })
    });
    const data = await response.json();
    if (data === "Request submitted") {
      alert("Your request has been submitted. If you have entered the correct username and email address, an email containing the link to reset your password will be sent shortly.");
      setSubmitted(true);
    } else {
      alert("Something went wrong, please try again");
    }
  }

  async function handleSubmit(event) {
    setIsSaving(true);
    const url = window.location.origin + "/budget";
    if (validate()) {
      request_password_reset(username, email, url);
    }
    setIsSaving(false);
    event.preventDefault();
  }



  return (
    <React.Fragment>
      <div className="m-2">
          <h4>Request for password reset</h4>
          <hr />
          {(submitted) ? (
            <UncontrolledAlert color="info">
              Your request has been submitted. If you have entered the correct username and email address, an email containing the link to reset your password will be sent shortly. 
            </UncontrolledAlert>
          ) : ''}
          <p>Please enter your login username and email address:</p>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
                  <Label for="username" sm={2}>
                      Username
                  </Label>
                  <Col sm={2}>
                      <Input id="username" name="username" type="text" placeholder="your username" bsSize="sm" onChange={(e) => setUsername(e.target.value)}
                          value={username} />
                  </Col>
              </FormGroup>
              <FormGroup row>
                  <Label for="email" sm={2}>
                      Email
                  </Label>
                  <Col sm={6}>
                      <Input id="email" name="email" placeholder="your email address" type="text" bsSize="sm"
                          value={email} onChange={(e) => setEmail(e.target.value)} />
                  </Col>
              </FormGroup>

              <FormGroup row>
                  <Col md={{ size: 10, offset: 2 }}>
                      {(isSaving) ? (
                        <span>
                          <Spinner size="sm">Submitting request, please wait...</Spinner> <span>Submitting request, please wait...</span>
                        </span>
                      ) : (
                        <Button type="submit" id="Submit" name="Submit" color="primary">
                            Submit
                        </Button>
                      )}
                  </Col>
              </FormGroup>

          </Form>
      </div>
    </React.Fragment>
  );

}

export default ForgotPassword;