import { Navbar, NavbarBrand, NavbarToggler, Collapse, DropdownItem, DropdownMenu, DropdownToggle, NavLink, Nav, UncontrolledDropdown } from "reactstrap";
import React, { useContext } from "react";
import AuthContext from "context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faToolbox, faUser, faKey, faRightFromBracket, faUsers, faSackDollar, faFileInvoiceDollar, faVault, faScaleUnbalanced } from '@fortawesome/free-solid-svg-icons'

function Header() {
  const { user, logoutUser } = useContext(AuthContext);

  return (
    <Navbar color="dark" expand="md" dark>
      <NavbarBrand href="/budget">
        <img src={require('assets/img/apslogo.png')} className="apslogo" />
        APS Budget
      </NavbarBrand>
      {user ? (
        <React.Fragment>
        <NavLink style={{color: 'white', textDecoration: 'none'}} href="/budget">
          <FontAwesomeIcon icon={faHome} /> Home
        </NavLink>
        {user.is_portal_admin ? (
          <Collapse navbar>
            <Nav navbar>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav style={{color: 'white', textDecoration: 'none'}}>
                  <FontAwesomeIcon icon={faToolbox} /> Admin
                </DropdownToggle>
                <DropdownMenu dark>
                  <DropdownItem>
                    <NavLink href="/budget/staff" className="text-white" style={{color: 'white', textDecoration: 'none'}}><FontAwesomeIcon icon={faUsers} /> Staff</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                  < NavLink href="/budget/budget_accounts" className="text-white" style={{color: 'white', textDecoration: 'none'}}><FontAwesomeIcon icon={faFileInvoiceDollar} /> Accounts </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/budget/departments" className="text-white" style={{color: 'white', textDecoration: 'none'}}><FontAwesomeIcon icon={faVault} /> Cost Centres </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/budget/funds" className="text-white" style={{color: 'white', textDecoration: 'none'}}><FontAwesomeIcon icon={faSackDollar} /> Funds </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/budget/pl_categories/1" className="text-white" style={{color: 'white', textDecoration: 'none'}}><FontAwesomeIcon icon={faScaleUnbalanced} /> P&L Categories </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        ) : ''}
        </React.Fragment>
      ) : ''}
      <NavbarToggler onClick={function noRefCheck() {}} />
      <Collapse navbar>
        {user ? (
          <>
            <Nav navbar className="ms-auto me-3">
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  {user.fullname}
                </DropdownToggle>
                <DropdownMenu dark end>
                  <DropdownItem>
                    <NavLink href="/budget/profile"><FontAwesomeIcon icon={faUser} /> My Profile</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/budget/change_password"><FontAwesomeIcon icon={faKey} /> Change Password</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink onClick={logoutUser}><FontAwesomeIcon icon={faRightFromBracket} /> Logout</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </>
        ) : (
          ""
        )}
      </Collapse>
    </Navbar>
  );
}

export default Header;
