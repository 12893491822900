import React, { useState, useEffect, useContext } from "react";
import { Navigate, Link } from "react-router-dom";
import useAxios from "utils/useAxios";
import {Spinner, Table, } from "reactstrap";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function DepartmentList(props) {
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);

  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState("");

  const api = useAxios();

  useEffect(() => {
    const DEPARTMENTS_DATA_URL = process.env.REACT_APP_API_URL + "/department/";
    const fetchData = async () => {
      try {
        const result = await api.get(DEPARTMENTS_DATA_URL);
        setDepartments(sortDeptByCategory(result.data));
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }
    };
    fetchData();
  }, []);

  function sortDeptByCategory(departments) {
    departments.sort((a, b) => {
      // Sort by category name
      if (a.category.name > b.category.name) {
        return 1;
      } else if (a.category.name < b.category.name) {
        return -1;
      } else {
        // If category names are equal, sort by department name
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    return departments;
  }

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {!user.is_portal_admin ? (
            <Navigate to="/dashboard" />
          ) : (
            <div>
              <h4>Cost Centres</h4>

              <p> <Link className="btn btn-secondary btn-sm me-1" to="/department/add" > Add New Cost Centre </Link> </p>
              {departments.length > 0 ? (
                <React.Fragment>
                  <Table id="department-list" bordered responsive size="sm">
                    <thead className="bg-info">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Abbreviation</th>
                        <th>Category</th>
                        <th>P&L Category (level 4)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {departments.map((d) => (
                        <tr key={d.id}>
                          <td><Link className="ms-1" to={`/department/edit/${d.id}`} >{d.id}</Link></td>
                          <td>{d.name}</td>
                          <td>{d.abbr}</td>
                          <td>{d.category.name}</td>
                          <td>{d.pl_category.name}</td>
                          <td>
                            <Link className="ms-1" to={`/department/edit/${d.id}`} > <FontAwesomeIcon icon={faPenToSquare} /> Edit </Link>&nbsp;&nbsp;&nbsp;
                            {/* <Link className="ms-1 text-danger" to={`/department/delete/${d.id}`} > <FontAwesomeIcon icon={faCircleMinus} /> Deactivate</Link> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              ) : (
                <div>No department has been created.</div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default DepartmentList;
