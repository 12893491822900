import axios from "axios";
import jwt_decode from "jwt-decode";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";

const BASE_URL = process.env.REACT_APP_API_URL

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const navigate = useNavigate();

  const axiosInstance = axios.create({
    BASE_URL,
    headers: { Authorization: `Bearer ${authTokens?.access}` }
  });

  axiosInstance.interceptors.request.use(async req => {
    const user = jwt_decode(authTokens.access);
    const isExpired = user.exp * 1000 < Date.now();
  
    if (!isExpired) return req;

    const response = await axios.post(`${BASE_URL}/account/token/refresh/`, {
      refresh: authTokens.refresh
    }).then(function (response) {
      localStorage.setItem("authTokens", JSON.stringify(response.data));
      setAuthTokens(response.data);
      setUser(jwt_decode(response.data.access));
      req.headers.Authorization = `Bearer ${response.data.access}`;
      return req;
    })
    .catch(function (error) {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      navigate('/');
    });

    return req;
    
  });

  return axiosInstance;
};

export default useAxios;