import 'App.css';
import Main from 'components/MainComponent'
import { BrowserRouter } from 'react-router-dom';

function App() {

  return (
    <BrowserRouter basename={"/budget"}>
        <div className="App">
          <Main />
        </div>
  </BrowserRouter>

  );
}

export default App;
