import React, { useState, useEffect, useCallback, useContext } from "react";
import useAxios from "utils/useAxios";
import { Card, CardTitle, CardBody, CardSubtitle, Table, Spinner, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faUserPen, faBook, faChalkboardUser } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons";

function Dashboard(props) {
  const { user } = useContext(AuthContext);
  const [budgetYears, setBudgetYears] = useState([]);
  const [error, setError] = useState("");
  const [deptOwned, setdDeptOwned] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const api = useAxios();

  let currentYear = new Date().getFullYear();

  // flags for collapsing previous year budgets
  const [byCollapse, setByCollapse] = useState({});

  const showExpenses = (byid) => {
    const newByCollapse = {...byCollapse}
    newByCollapse[byid] = false;
    setByCollapse(newByCollapse);
  }

  const hideExpenses = (byid) => {
    const newByCollapse = {...byCollapse}
    newByCollapse[byid] = true;
    setByCollapse(newByCollapse);
  }

  const date_formatter = new Intl.DateTimeFormat("en-SG", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "Asia/Singapore",
  });

  const formatter = new Intl.NumberFormat("en-SG", {
    style: 'currency',
    currency: 'SGD',
    currencySign: 'accounting',
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    const DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/dashboard/";

    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        // setBudgetYears(result.data);
        // sortBudgetYearDepartments(result.data);
        sortBYDepartments(result.data);
        setdDeptOwned(getOwnedDept(result.data));
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  const getOwnedDept = (budgetY) => {
    const depts = [];
    budgetY.forEach((b) => {
      b.departments.forEach((d) => {
        let owned = false;
        d.ownerships.forEach((o) => {
          if (o.owner.id === user.id) {
            owned = true;
          }
        });
        if (owned) {
          depts.push(d.id);
        }
      });
    });
    return depts;
  };

  function sortBYDepartments(budgetYear) {
    budgetYear.forEach((by) => {
      by.departments.sort((a, b) => {
        // Sort by category name
        if (a.department.category.name > b.department.category.name) {
            return 1;
        } else if (a.department.category.name < b.department.category.name) {
            return -1;
        } else {
            // If category names are equal, sort by department name
            if (a.department.name > b.department.name) {
                return 1;
            } else if (a.department.name < b.department.name) {
                return -1;
            } else {
                return 0;
            }
        }
      });
    });
    setBudgetYears(budgetYear);

    // Set collapse flags
    let byc = {}
    budgetYear.forEach((by) => {
      byc[by.id] = by.year < currentYear;
    });
    setByCollapse(byc);
  }

  return (
    <React.Fragment>
      <div>
        <h3>Dashboard</h3>
        {!isLoading ? (
          <React.Fragment>
            {budgetYears.length > 0 ? (
              <React.Fragment>
                {budgetYears.map((b) => (
                  <div key={b.id} className="row mb-1 p-2">
                    <Card>
                      <CardBody>
                        <CardTitle tag="h5">
                          {(user.is_portal_admin || user.is_budget_viewer || user.is_school_leader) ? (
                            <React.Fragment>
                              <Link to={`/budget_year/edit/${b.id}`}> Year {b.year} Budget{" "} {b.label.length > 0 ? ( <span>&mdash; {b.label}</span> ) : ( "" )} </Link>
                              <span className="float-end d-flex">
                                {b.doc1_path ? (
                                  <a title="Open/Download Budget Guidelines in a new tab" className="btn btn-sm btn-outline-secondary mx-2" href={ process.env.REACT_APP_API_URL + "/media/" + b.doc1_path } target="_blank" rel="noopener noreferrer" > <FontAwesomeIcon icon={faBook} /> Budget Guidelines </a>
                                ) : (
                                  ""
                                )}
                                {b.doc2_path ? (
                                  <a title="Open/Download List of Budget Owners in a new tab" className="btn btn-sm btn-outline-secondary mx-2" href={ process.env.REACT_APP_API_URL + "/media/" + b.doc2_path } target="_blank" rel="noopener noreferrer" > <FontAwesomeIcon icon={faChalkboardUser} />{" "} Budget Owners & Preparers </a>
                                ) : (
                                  ""
                                )}

                                {(user.is_portal_admin || user.is_budget_viewer) ? (
                                  <Link className="btn btn-sm btn-secondary ms-2" to={`/budget_year/allocation/${b.id}`} target="_blank" rel="noopener noreferrer" > {" "} Allocation{" "} </Link>
                                ) : ''}

                                {(user.is_portal_admin || user.is_budget_viewer) ? (
                                  <Link className="btn btn-sm btn-secondary mx-2" to={`/income/summary/${b.id}`} target="_blank" rel="noopener noreferrer" > {" "} Income{" "} </Link>
                                ) : ''}
                                
                                <UncontrolledDropdown>
                                  <DropdownToggle caret className='btn btn-sm btn-secondary'>Profit & Loss</DropdownToggle>
                                  <DropdownMenu>
                                    {(user.is_portal_admin || user.is_budget_viewer) ? (
                                      <DropdownItem>
                                        <Link className="dropdown-item" to={`/budget_year/profit_and_loss/${b.id}`} target="_blank" rel="noopener noreferrer" >Summary</Link>
                                      </DropdownItem>
                                    ) : ''}
                                    <DropdownItem>
                                      <Link className="dropdown-item" to={`/budget_year/pnl_expense/${b.id}`} target="_blank" rel="noopener noreferrer" >By Expense</Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Link className="dropdown-item" to={`/budget_year/pnl_division/${b.id}`} target="_blank" rel="noopener noreferrer" >By Division</Link>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Link className="dropdown-item" to={`/report/item_list/${b.id}`} target="_blank" rel="noopener noreferrer" >Income & Expense List</Link>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              Year {b.year} Budget{" "} {b.label.length > 0 ? ( <span>&mdash; {b.label}</span> ) : ( "" )}
                              <span className="float-end d-flex">
                                {b.doc1_path ? (
                                  <a title="Open/Download Budget Guidelines in a new tab" className="btn btn-sm btn-outline-secondary mx-2" href={ process.env.REACT_APP_API_URL + "/media/" + b.doc1_path } target="_blank" rel="noopener noreferrer" > <FontAwesomeIcon icon={faBook} /> Budget Guidelines </a>
                                ) : (
                                  ""
                                )}
                                {b.doc2_path ? (
                                  <a title="Open/Download List of Budget Owners in a new tab" className="btn btn-sm btn-outline-secondary mx-2" href={ process.env.REACT_APP_API_URL + "/media/" + b.doc2_path } target="_blank" rel="noopener noreferrer" > <FontAwesomeIcon icon={faChalkboardUser} />{" "} Budget Owners & Preparers </a>
                                ) : (
                                  ""
                                )}
                              </span>
                            </React.Fragment>
                          )}
                        </CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                          Submission period:{" "}
                          {date_formatter.format(new Date(b.start_date))} -{" "}
                          {date_formatter.format(new Date(b.end_date))}
                        </CardSubtitle>

                        {user.is_portal_admin ? (
                          <CardSubtitle className="mb-2 text-muted" tag="h6">
                            <strong>
                              <span className=""> {" "} <small> <em>Revenue</em>:{" "} {formatter.format(b.total_income)}{" "} </small>{" "} </span>
                              <span className="mx-2"> {" "} <small> <em>Expenditure</em>:{" "} {formatter.format(b.total_expense)}{" "} </small>{" "} </span>
                              <span className="mx-2"> {" "} <small> <em>Surplus/Deficit</em>:{" "} {formatter.format( b.total_income - b.total_expense )}{" "} </small>{" "} </span>
                            </strong>
                          </CardSubtitle>
                        ) : (
                          ""
                        )}

                        {/* EXPENSES */}
                        {byCollapse[b.id] ? (
                          <span className="badge rounded-pill bg-secondary" onClick={e => showExpenses(b.id)} role="button">Show Expenses</span>
                        ) : (
                          <React.Fragment>
                            <span className="badge rounded-pill bg-secondary" onClick={e => hideExpenses(b.id)} role="button">Hide Expenses</span>
                            <div className="pt-3">
                              {b.departments.length > 0 ? (
                                <Table bordered size="sm" hover>
                                  <thead>
                                    <tr>
                                      <th>Cost Centres</th>
                                      <th style={{ width: "20%" }}>Owners</th>
                                      <th style={{ width: "20%" }}>
                                        Assigned Reviewer
                                      </th>
                                      <th>Category</th>
                                      <th className="text-center">Submitted</th>
                                      <th className="text-center">Reviewed</th>
                                      {user.is_portal_admin ? (
                                        <th className="text-center">Checked</th>
                                      ) : (
                                        ""
                                      )}
                                      <th className="text-center">Approved</th>
                                      <th className="text-center">Rejected</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {b.departments.map((d) =>
                                      deptOwned.includes(d.id) ||
                                      user.is_portal_admin ||
                                      user.is_school_leader ||
                                      user.is_budget_viewer ? (
                                        <tr key={d.id}>
                                          <td>
                                            <Link to={`/budget/summary/${d.id}`}>
                                              {d.department.name}
                                            </Link>
                                          </td>
                                          <td>
                                            {user.is_portal_admin ? (
                                              <Link className="ms-2" to={`/budget_department/edit/${d.id}`} title="Change owners" > <FontAwesomeIcon icon={faUserPen} /> </Link> ) : (
                                              ""
                                            )}
                                            {d.ownerships.map((o, index) => (
                                              <span className="badge bg-info text-bg-info ms-2" key={`owner_${o.id}_${d.id}_${b.id}`} > {o.owner.fullname} </span> ))}
                                          </td>
                                          <td>
                                            {user.is_portal_admin ? (
                                              <Link className="ms-2" to={`/budget_department/edit/${d.id}`} title="Change reviewer" > <FontAwesomeIcon icon={faUserPen} /> </Link> ) : (
                                              ""
                                            )}
                                            {d.assigned_reviewer ? (
                                              <span className="badge bg-info text-bg-info ms-2"> {d.assigned_reviewer.fullname} </span> ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {user.is_portal_admin || user.is_budget_viewer || user.is_school_leader ? (
                                              <Link to={`/budget_category/summary/${b.id}/${d.department.category.id}`} > {d.department.category.name} </Link>
                                            ) : (
                                              d.department.category.name
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {d.submitted_at !== null ? (
                                              <span className="text-success">
                                                <FontAwesomeIcon icon={faCircle} />
                                              </span>
                                            ) : (
                                              <span className="text-muted">
                                                <FontAwesomeIcon
                                                  icon={faCircleRegular}
                                                />
                                              </span>
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {d.reviewed_at !== null ? (
                                              <span className="text-success"> <FontAwesomeIcon icon={faCircle} /> </span> ) : (
                                              <span className="text-muted"> <FontAwesomeIcon icon={faCircleRegular} /> </span>
                                            )}
                                          </td>
                                          {user.is_portal_admin ? (
                                            <td className="text-center">
                                              {d.checked_at !== null ? (
                                                <span className="text-success"> <FontAwesomeIcon icon={faCircle} /> </span> ) : (
                                                <span className="text-muted"> <FontAwesomeIcon icon={faCircleRegular} /> </span> )}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          <td className="text-center">
                                            {d.approved_at !== null ? (
                                              <span className="text-success"> <FontAwesomeIcon icon={faCircle} /> </span> ) : (
                                              <span className="text-muted"> <FontAwesomeIcon icon={faCircleRegular} /> </span>
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {d.rejected_at !== null ? (
                                              <span className="text-danger"> <FontAwesomeIcon icon={faCircle} /> </span>
                                            ) : (
                                              <span className="text-muted"> <FontAwesomeIcon icon={faCircleRegular} /> </span>
                                            )}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              ) : null}
                            </div>
                          </React.Fragment>
                        )}

                        {/* <Button color="primary" href="/budget/summary/">
                                View
                            </Button> */}
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </React.Fragment>
            ) : user.is_portal_admin ? (
              <p>
                No budget years has been set up. Do you want to{" "}
                <a href="/budget/budget_year/add">set one now</a>?
              </p>
            ) : (
              <p>
                No budget years has been set up. You can not submit any budget
                at this moment.
              </p>
            )}
          </React.Fragment>
        ) : (
          <div className="text-center mt-3">
            <Spinner>Loading...</Spinner>
            <p>Loading...</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
