import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate, Link, useNavigate } from 'react-router-dom';
import useAxios from "utils/useAxios";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Spinner, Form, Button, Table, Label } from "reactstrap";
import 'assets/css/sidebar.css'
import BudgetSummaryFund from 'components/budget/BudgetSummaryFundComponent'
import BudgetSummaryMonth from 'components/budget/BudgetSummaryMonthComponent'
import BudgetSummaryCombined from "components/budget/BudgetSummaryCombinedComponent";
import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPen, faDownload, faTimeline, faCircleCheck } from '@fortawesome/free-solid-svg-icons'


function BudgetSummary(props) {

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  let { budgetDeptId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const [heading, setHeading] = useState("Budget Summary");

  const [isDeptOwner, setIsDeptOwner] = useState(false);
  const [budgetDepartment, setBudgetDepartment] = useState('');
  const [owners, setOwners] = useState([]);
  const [owner_ids, setOwnerIDs] = useState([]);

  const [submitLabel, setSubmitLabel] = useState('Submit for Review');

  const [department, setDepartment] = useState('');
  const [budgetYear, setBudgetYear] = useState('');
  const [budgets, setBudgets] = useState([]);
  const [funds, setFunds] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [rejectionReason, setRejectionReason] = useState('');

  const [lyBudgets, setLYBudgets] = useState([]);

  // const [summarizedBudgets, setSummarizedBudgets] = useState([]);
  const [error, setError] = useState('');

  const api = useAxios();


  // const percent_formatter = {
  //   numberFormatter: new Intl.NumberFormat("en-SG", {
  //     maximumFractionDigits: 0,
  //   }),
  //   format: function(number) {
  //     const nDisplay = this.numberFormatter.format(number)
  //     if (number < 0)
  //       return <span style={{color: "red"}}>{nDisplay}</span>;
  //     else
  //       return nDisplay;
  //   }
  // };

  const percent_formatter = new Intl.NumberFormat("en-SG", {
    maximumFractionDigits: 2,
  });

  const formatter = {
    numberFormatter: new Intl.NumberFormat("en-SG", {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: "code",
      currencySign: "accounting",
      maximumFractionDigits: 0,
    }),
    format: function(number) {
      const nDisplay = this.numberFormatter.formatToParts(number)
      .filter(x => x.type !== "currency")
      .map(x => x.value.trim())
      .join("")
      .trim();
      if (number < 0)
        return (<span style={{color: "red"}}>{nDisplay}</span>);
      else
        return nDisplay;
    }
  };


  const date_formatter = new Intl.DateTimeFormat('en-SG', {
    dateStyle: 'medium',
    timeStyle: 'medium',
    timeZone: 'Asia/Singapore',
  });

  useEffect(() => {

    const DATA_URL1 = process.env.REACT_APP_API_URL + "/budget_department/" + budgetDeptId + "/";
    const fetchOwnership = async () => {
      try {
        const result = await api.get(DATA_URL1);
        setBudgetDepartment(result.data);
        setSubmitButtonLabel(result.data.status);
        const dept_owners = [];
        const dept_owner_ids = [];
        let isDeptOwner = false;
        result.data.ownerships.forEach((o) => {
          if(o.owner.id === user.id) {
            isDeptOwner = true;
          }
          dept_owners.push(o.owner.fullname);
          dept_owner_ids.push(o.owner.id);
        });
        setIsDeptOwner(isDeptOwner);
        setOwners(dept_owners);
        setOwnerIDs(dept_owner_ids);
        canEditBudgetDeparment(result.data, isDeptOwner);
      } catch {
        // alert("Something went wrong");
      }  
    }
    fetchOwnership();
  
    const DATA_URL = process.env.REACT_APP_API_URL + "/budget/summary/" + budgetDeptId + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        setDepartment(result.data.department.department);
        setBudgetYear(result.data.department.budget_year);
        setFunds(extractUsedFunds(result.data.department.budgets));
        setAccounts(result.data.accounts);
        setStatusUpdates(result.data.department.status_updates);
        var cyBudgets = summarizeBudgets(result.data.department.budgets, result.data.accounts);
        if (result.data.last_year_department) {
          var lyBudgets = summarizeBudgets(result.data.last_year_department.budgets, result.data.accounts);
          setLYBudgets(lyBudgets);
          cyBudgets = mergeLyBudget(cyBudgets, lyBudgets);
        }
        setBudgets(cyBudgets);
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);
 
  const mergeLyBudget = (cyBudgets, ly) => {
    var budgets = JSON.parse(JSON.stringify(cyBudgets));
    for (var i=0, n=ly.length; i < n; ++i ) {
      const acc_cat = ly[i].name;
      var cat_found = false;
      for (var j=0, m=budgets.length; j < m; ++j ) {
        if (budgets[j].name === acc_cat) {
          cat_found = true;
          for (var k=0, p=ly[i].accounts.length; k < p; ++k ) {
            var acc_found = false;
            var acc_desc = ly[i].accounts[k].description;
            var num_accs = budgets[j].accounts.length;
            var insert_index = num_accs
            for (var l=0; l < num_accs; ++l ) {
              if (ly[i].accounts[k].id == budgets[j].accounts[l].id) {
                acc_found = true;
                break; // acc found
              }
              if (ly[i].accounts[k].account_number < budgets[j].accounts[l].account_number) {
                insert_index = l;
                break;
              }
            }
            if (!acc_found && !acc_desc.trim().endsWith("Elimination")) {
              var insert_account = JSON.parse(JSON.stringify(ly[i].accounts[k]));
              insert_account.budgets = [];
              insert_account.total = 0;
              if (insert_index === num_accs)
                budgets[j].accounts.push(insert_account);
              else
                budgets[j].accounts.splice(insert_index, 0, insert_account);
            }

          }
          break;
        }
      }
      if (!cat_found) {
        var insert_cat = JSON.parse(JSON.stringify(ly[i]));
        for (var v=0, z=insert_cat.accounts.length; v < z; ++v) {
          const acc_desc = insert_cat.accounts[v].description;
          if (acc_desc.trim().endsWith("Elimination")) {
            insert_cat.accounts.splice(v,1);
          } else {
            insert_cat.accounts[v].budgets = [];
            insert_cat.accounts[v].total = 0;
          }
        }
        budgets.splice(j, 0, insert_cat);
      }
    }
    return budgets;
  }

  const setSubmitButtonLabel = (currentStatus) => {
    switch (currentStatus) {
      case "PENDING SUBMISSION":
        setSubmitLabel("Submit for Review");
        break;
      case "PENDING REVIEW":
        setSubmitLabel("Mark as Reviewed");
        break;
      case "PENDING APPROVAL":
        setSubmitLabel("Approve");
        break;
      case "APPROVED":
        setSubmitLabel("Un-Approve");
        break;
      }
  }

  const summarizeBudgets = (budgets, accounts_list) => {
    const summarizedBudgets = [];
    let curCatId = 0;
    let curAccId = 0;
    let j = 0
    let k = 0;
    for (let i = 0; i < budgets.length; ++i) {
      let acc = accounts_list.find(acc => acc.id === budgets[i].account);
      if (acc.category.id !== curCatId) {
        if (curCatId !== 0) j++;
        summarizedBudgets.push(JSON.parse(JSON.stringify(acc.category)));
        summarizedBudgets[j]['accounts'] = [];
        curCatId = acc.category.id;
        k = 0;
        curAccId = 0;
      }
      if (budgets[i].account !== curAccId) {
        if (curAccId !== 0) k++;

        summarizedBudgets[j]['accounts'].push(JSON.parse(JSON.stringify(acc)));
        summarizedBudgets[j]['accounts'][k]['budgets'] = [];
        summarizedBudgets[j]['accounts'][k]['total'] = 0;
        curAccId = budgets[i].account;
      }
      budgets[i]['budget_no'] = i+1;
      summarizedBudgets[j]['accounts'][k]['budgets'].push(budgets[i]);
      summarizedBudgets[j]['accounts'][k]['total'] += parseInt(budgets[i].amount);
    }
    return summarizedBudgets;
  }

  const extractUsedFunds = (budgets) => {
    const usedFunds = [];
    const fundIds = [];

    budgets.forEach((budget) => {
      budget.sofs.forEach((sof) => {
        if (!fundIds.includes(sof.sof.id)) {
          fundIds.push(sof.sof.id);
          usedFunds.push({ 'id': sof.sof.id, 'code': sof.sof.code, 'description': sof.sof.description });
        }
      });
    });

    return usedFunds.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        if (a.description > b.description) {
          return 1;
        } else if (a.description < b.description) {
          return -1;
        } else {
          return 0;
        }
      }
    });

    // return usedFunds.sort((a, b) => { return a.code - b.code });
  }

  // State for canEditBudget
  const [canEditBudget, setCanEditBudget] = useState(false);

  const canEditBudgetDeparment = (dept, isOwner) => {
    const canEdit = user.is_portal_admin ||
                  (isOwner && (dept.status == "PENDING SUBMISSION" || dept.status == "REJECTED"));
    setCanEditBudget(canEdit);
  }
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    switch (tab) {
      case '1': setHeading("Budget Summary"); break;
      case '2': setHeading("Budget Summary (By Fund)"); break;
      case '3': setHeading("Budget Summary (By Month)"); break;
    }
  }

  const url = window.location.origin + "/budget";

  function handleSubmit(event) {
    const PUT_URL = process.env.REACT_APP_API_URL + "/budget_department/" + budgetDeptId + "/submit_approval/";
    let budgetStatus = "PENDING SUBMISSION"
    switch(budgetDepartment.status) {
      case "PENDING SUBMISSION":
      case "REJECTED":
        budgetStatus = "PENDING REVIEW";
        break;
      case "APPROVED":
          budgetStatus = "PENDING SUBMISSION";
        break;
      case "PENDING REVIEW":
          budgetStatus = "PENDING APPROVAL";
          break;
      case "PENDING APPROVAL":
        budgetStatus = "APPROVED";
        break;
      default:
        break;
    }

    api.put(PUT_URL, {
        'budgetDeptId': budgetDeptId,
        'status': budgetStatus,
        'budget_year': budgetYear.year,
        'url': url,
        'owners': owner_ids
      })
        .then(function (response) {
            alert(response.data.status);
            window.location.reload();
        })
        .catch(function (error) {
            alert(error.message);
        });
    event.preventDefault();
  }

  function confirmReject(event) {
    const PUT_URL = process.env.REACT_APP_API_URL + "/budget_department/" + budgetDeptId + "/reject/";
  
    api.put(PUT_URL, {
        'budgetDeptId': budgetDeptId,
        'status': "REJECTED",
        'remarks': rejectionReason,
        'budget_year': budgetYear.year,
        'url': url,
        'owners': owner_ids
      })
        .then(function (response) {
            alert(response.data.status);
            window.location.reload();
        })
        .catch(function (error) {
            alert(error.message);
        });
    event.preventDefault();
    
  }

  function toggleChecked(event) {
    const PUT_URL = process.env.REACT_APP_API_URL + "/budget_department/" + budgetDeptId + "/checked/";
  
    api.put(PUT_URL, {
        'budgetDeptId': budgetDeptId,
      })
        .then(function (response) {
            alert(response.data.status);
            window.location.reload();
        })
        .catch(function (error) {
            alert(error.message);
        });
    event.preventDefault();
    
  }

  const downloadExel = () => {
    setIsDownloading(true);
    const EXCEL_DATA_URL = process.env.REACT_APP_API_URL + "/budget/summary_excel/" + budgetDeptId + "/";

    const fetchExcel = async () => {
      try {
        const result = await api.get(EXCEL_DATA_URL, {responseType: 'blob'});
        const contentDisposition = result.headers['content-disposition'];
        const fileName = contentDisposition.substring(
          contentDisposition.indexOf('filename=') + 9,
          contentDisposition.length
        );
        let blob = new Blob([result.data])
        blob = blob.slice(0, blob.size, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);  
        setIsDownloading(false);
      } catch {
        alert("Something went wrong. Please try again or contact support");
        setIsDownloading(false);
      }
    };
    fetchExcel();
  }

  const [showHistory, setShowHistory] = useState(false);

  function toggleHistory() {
    setShowHistory(!showHistory);
  }

  return (
    <React.Fragment>
      {(!isLoading) ? (
        <React.Fragment>
          {(!isDeptOwner && !user.is_portal_admin && !user.is_school_leader && !user.is_budget_viewer) ? (
              <Navigate to="/dashboard" />
          ) : (
        <div>
          <h4>
            {heading} &mdash; {department.name + " (" + department.abbr + "): " + budgetYear.year} {(budgetYear.label.length > 0) ? ( <span>&mdash; {budgetYear.label}</span>) : ''} 
            {(budgetDepartment.checked_at !== null && user.is_portal_admin) ? (
              <FontAwesomeIcon className="text-success ms-3" title={budgetDepartment.checker.fullname + " on " + date_formatter.format(new Date(budgetDepartment.checked_at))} icon={faCircleCheck} />
            ) : ''}
            </h4>
          
          {(budgetDepartment) ? (
            <React.Fragment>
              
              {(isDownloading) ? (
                <p className="float-end mb-2 me-2">Downloading...
                  <Spinner size="sm" color="success">Downloading...</Spinner>
                </p>
              ) : (
                <Button onClick={downloadExel} title="Download Excel" className="float-end btn btn-primary btn-sm mb-2 me-2" color="primary">
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              )}
              <Button className="float-end btn btn-info btn-sm mb-2 me-2" title="Status History" color="primary" onClick={toggleHistory}>
                <FontAwesomeIcon icon={faTimeline} />
              </Button>

              
              <h6><small>Status: {budgetDepartment.status} 
              {(budgetDepartment.status === "PENDING REVIEW") ? (
                  <small className="text-muted"> (submitted at: {date_formatter.format(new Date(budgetDepartment.submitted_at))})</small>
              ) : ''}
              {(budgetDepartment.status === "PENDING APPROVAL") ? (
                  <small className="text-muted"> (reviewed at: {date_formatter.format(new Date(budgetDepartment.reviewed_at))} by {budgetDepartment.reviewer.fullname})</small>
                  ) : ''}
              {(budgetDepartment.status === "APPROVED") ? (
                  <small className="text-muted"> (approved at: {date_formatter.format(new Date(budgetDepartment.approved_at))} by {budgetDepartment.approver.fullname})</small>
              ) : ''}
              {(budgetDepartment.status === "REJECTED") ? (
                  <small className="text-muted"> (rejected at: {date_formatter.format(new Date(budgetDepartment.rejected_at))} by {budgetDepartment.rejecter.fullname})</small>
              ) : ''}
              </small></h6>

              <Form>
                <div className="mt-3 mb-3">

                  {(user.is_portal_admin || (isDeptOwner && (budgetDepartment.status === "PENDING SUBMISSION" || budgetDepartment.status === "REJECTED"))) ? (
                  <Link className="btn btn-secondary btn-sm me-1" to={`/budget/add/${budgetDeptId}`}>Add Budget Item</Link>
                  ) : ''}

                  {((budgets.length > 0) && ((isDeptOwner && (budgetDepartment.status === "PENDING SUBMISSION" || budgetDepartment.status === "REJECTED")) || 
                  (user.is_portal_admin && (budgetDepartment.status === "PENDING APPROVAL" || budgetDepartment.status === "APPROVED" || budgetDepartment.status === "PENDING REVIEW")) ||
                  (user.is_school_leader && budgetDepartment.status === "PENDING REVIEW"))) ? (
                    <React.Fragment>
                      {(budgetDepartment.status !== "APPROVED") ? (
                        (budgetDepartment.assigned_reviewer) ? (
                        <Button type="button" color="primary" className="btn btn-sm me-1" onClick={handleSubmit}>{submitLabel}</Button>
                        ) : (
                          <span className="bg-warning m-t-3">No reviewer has been assigned for this cost centre, you cannot submit it for review. Please contact Finance department.</span>
                          )
                      ) : ''}

                      {(user.is_portal_admin && budgetDepartment.reviewed_at !== null) ? (
                        (budgetDepartment.checked_at !== null) ? (
                          <Button type="button" color="secondary" className="btn btn-sm me-1" onClick={toggleChecked}>Mark as unchecked</Button>
                        ) : (
                          <Button type="button" color="success" className="btn btn-sm me-1" onClick={toggleChecked}>Mark as checked</Button>
                        )
                        ) : ''}

                      {(budgetDepartment.status !== "PENDING SUBMISSION" && budgetDepartment.status !== "REJECTED") ? (
                        <React.Fragment>
                          <Button type="button" color="danger" className="btn btn-sm me-1" onClick={() => setShowReject(true)}>Reject</Button>

                          {(showReject) ? (
                          <div className="col-4">
                            <div className="form-control mt-2">
                              <Label for="remarks" className="form-label">Rejection Reason:</Label>
                              <textarea class="form-control" name="rejection_reason" id="rejection_reason" col={50} row={3} value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} />
                              <div>
                                <Button type="button" className="btn btn-sm btn-secondary mt-1 me-1" onClick={() => setShowReject(false)}>Cancel</Button>
                                <Button type="button" className="btn btn-danger btn-sm mt-1 me-1" onClick={confirmReject}>Confirm Reject</Button>
                              </div>
                            </div>
                          </div>
                          ) : ''}

                        </React.Fragment>
                      ) : ''}
                    </React.Fragment>
                  ) : ''}

                </div>
              </Form>
              
              {(user.is_portal_admin) ? (
              <h6><small>Owners: 
                  {(owners.length) > 0 ? (
                    <React.Fragment>
                    {owners.map((o) => (
                      <span className="badge bg-info ms-1" key={o}>&nbsp;{o}</span>
                    ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <em>No owners have been set for this department.
                      </em>
                    </React.Fragment>
                  )}
                  <Link className="ms-2" to={`/budget_department/edit/${budgetDeptId}`}>
                      <FontAwesomeIcon icon={faUserPen} />
                  </Link>
              </small></h6>
              ) : ''}

              {(statusUpdates.length > 0 && showHistory) ? (
                <React.Fragment>
                  <div className="card" style={{maxWidth: 50 + 'rem'}}>
                    <div className="card-header">
                      <em>Status History</em>
                    </div>
                    <div className="card-body">
                      <Table size="sm">
                      <thead><tr><th>Date & Time</th><th>Status</th><th>User</th><th>Remarks</th></tr></thead>
                      <tbody>
                      {statusUpdates.map((update) => (
                        <tr key={update.id}>
                          <td>{date_formatter.format(new Date(update.created_at))}</td>
                          <td>{update.status}</td>
                          <td>{update.user.fullname}</td>
                          <td>{update.remarks}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                    </div>
                  </div>
                </React.Fragment>
              ) : ''}

            </React.Fragment>
          ) : ''}

          {(budgets.length > 0) ? (
            <React.Fragment>
          <Nav tabs>
            <NavItem>
              <NavLink active={currentActiveTab === '1'} onClick={() => { toggle('1'); }} role="button">
                Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={currentActiveTab === '2'} onClick={() => { toggle('2'); }} role="button">
                By Fund
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={currentActiveTab === '3'} onClick={() => { toggle('3'); }} role="button">
                By Month
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                    <BudgetSummaryCombined department={budgetDepartment} budgets={budgets} funds={funds} formatter={formatter} pformatter={percent_formatter} canEdit={canEditBudget} year={budgetYear.year} lyBudgets={lyBudgets} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <BudgetSummaryFund department={budgetDepartment} budgets={budgets} funds={funds} formatter={formatter} pformatter={percent_formatter}  canEdit={canEditBudget} year={budgetYear.year} lyBudgets={lyBudgets} />
                  </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <BudgetSummaryMonth department={budgetDepartment} budgets={budgets} funds={funds} formatter={formatter} pformatter={percent_formatter}  canEdit={canEditBudget} year={budgetYear.year} lyBudgets={lyBudgets} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          </React.Fragment>
          ) : (
            <p className="mt-3"><em>No budget items have been added</em></p>
          )
          }
        </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );

}

export default BudgetSummary;