import React, { useState, useEffect, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import useAxios from "utils/useAxios";
import {Spinner, Nav, NavItem, NavLink, TabContent, Row, Col, TabPane } from "reactstrap";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";

import PLCategorySubList from "components/pl_category/PLCategorySubListComponent";

function PLCategoryList(props) {

  let { plType } = useParams();

  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);

  const [pl_categories, setPLCategories] = useState([]);
  const [error, setError] = useState("");

  const api = useAxios();

  useEffect(() => {
    if (plType != null)
      setCurrentActiveTab(plType);

    const PL_CATEGORY_DATA_URL = process.env.REACT_APP_API_URL + "/pl_category/";
    const fetchData = async () => {
      try {
        const result = await api.get(PL_CATEGORY_DATA_URL);
        setPLCategories(result.data);
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }
    };
    fetchData();
  }, []);

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  function addPLCatChild(parent_id) {
    alert("Adding a child category to " + parent_id);
  }
  
  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {!user.is_portal_admin ? (
            <Navigate to="/dashboard" />
          ) : (
            <div>
              <h4>P&L Categories</h4>
              {pl_categories ? (
                <React.Fragment>
                  <Nav tabs>
                    <NavItem>
                      <NavLink active={currentActiveTab === '1'} onClick={() => { toggle('1'); }} role="button">
                        Income
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentActiveTab === '2'} onClick={() => { toggle('2'); }} role="button">
                        Expense
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentActiveTab === '3'} onClick={() => { toggle('3'); }} role="button">
                        Division
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <PLCategorySubList pl_categories={pl_categories['Income']} pl_type="INCOME" cat_name="Income" add_child={addPLCatChild} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <PLCategorySubList pl_categories={pl_categories['Expense']} pl_type="EXPENSE" cat_name="by Expense" add_child={addPLCatChild}  />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <PLCategorySubList pl_categories={pl_categories['Division']} pl_type="DIVISION" cat_name="by Division"  add_child={addPLCatChild} />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
              </React.Fragment>
            ) : (
                <div>No P&L Category has been created.</div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default PLCategoryList;
