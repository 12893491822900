import React, { useState, useEffect, useContext } from "react";
import { Navigate, Link } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Spinner, Table, } from "reactstrap";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function FundList(props) {
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);

  const [funds, setFunds] = useState([]);

  const api = useAxios();

  useEffect(() => {
    const FUNDS_DATA_URL = process.env.REACT_APP_API_URL + "/fund/";
    const fetchData = async () => {
      try {
        const result = await api.get(FUNDS_DATA_URL);
        setFunds(result.data);
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {!user.is_portal_admin ? (
            <Navigate to="/dashboard" />
          ) : (
            <div>
              <h4>Funds</h4>

              <p> <Link className="btn btn-secondary btn-sm me-1" to="/fund/add" > Add New Fund </Link> </p>
              {funds.length > 0 ? (
                <React.Fragment>
                  <Table id="account-list" bordered responsive size="sm">
                    <thead className="bg-info">
                      <tr>
                        <th>ID</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {funds.map((f) => (
                        <tr key={f.id}>
                          <td><Link className="ms-1" to={`/fund/edit/${f.id}`} >{f.id}</Link></td>
                          <td>{f.code}</td>
                          <td>{f.description}</td>
                          <td>
                            <Link className="ms-1" to={`/fund/edit/${f.id}`} > <FontAwesomeIcon icon={faPenToSquare} /> Edit </Link>&nbsp;&nbsp;&nbsp;
                            {/* <Link className="ms-1 text-danger" to={`/department/delete/${d.id}`} > <FontAwesomeIcon icon={faCircleMinus} /> Deactivate</Link> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              ) : (
                <div>No fund has been created.</div>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default FundList;
