import React from "react";
import {Row, Table, Spinner } from "reactstrap";
import 'assets/css/budgetsummary.css'

function ExpenseList({expenses, total, formatter, funds, fundIds, fundTotals}) {

  return (
    <React.Fragment>
      <Row className="m-1">
      </Row>
      {(expenses) ? (
        <div className="tableFixHead">
        <Table id="expense-list" bordered size="sm">
          <thead className="bg-info">
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">Division</th>
              <th className="text-center">Cost Centre</th>
              <th className="text-center">Account</th>
              <th className="text-center">Description</th>
              <th className="text-center">Amount</th>
              {(funds.length > 0) ? (
                funds.map((fund) => (
                  <th key={`head${fund.id}`} className="text-center budget-summary-fund" style={{maxWidth: 120 + 'px'}}>{fund.code}<br/>{fund.description}</th>
                ))
              ) : ''}
            </tr>
          </thead>
          <tbody>
            {expenses.map((e,i) => (
                <tr key={e.id}>
                    <td>{i+1}</td>
                    <td>{e.dept_category}</td>
                    <td>{e.department}</td>
                    <td>{e.account}</td>
                    <td>{e.description}</td>
                    <td className="text-end">{formatter.format(e.amount)}</td>
                    {funds.map((fund) => (
                      <td key={e.id + '_' + fund.id} className="text-end budget-summary-fund">
                        {(e.sofs.filter((s) => s.sof.id === fund.id).length > 0) ? formatter.format(e.sofs.filter((s) => s.sof.id === fund.id)[0].amount) : '-'}
                      </td>
                    ))}
                </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
                <td colSpan={5} className="text-end"><strong>Total</strong></td>
                <td className="text-end"><strong>{formatter.format(total)}</strong></td>
                {funds.map((fund) => (
                  <td key={fund.id + '_fund_total'} className="text-end budget-summary-fund">
                    <strong>{formatter.format(fundTotals[fund.id])}</strong>
                  </td>
                ))}
            </tr>
          </tfoot>
        </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default ExpenseList;