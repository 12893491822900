import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Spinner, Button } from "reactstrap";
import "assets/css/sidebar.css";
import IncomeSummaryCombined from "components/income/IncomeSummaryCombinedComponent";
import IncomeSummaryFund from "components/income/IncomeSummaryFundComponent";
import IncomeSummaryMonth from "components/income/IncomeSummaryMonthComponent";

import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function IncomeSummaryComponent(props) {
  const { user } = useContext(AuthContext);

  let { budgetYearId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const [budgetYear, setBudgetYear] = useState("");
  const [incomes, setIncomes] = useState();
  const [funds, setFunds] = useState([]);

  const [lyIncomes, setLYIncomes] = useState([]);

  const [error, setError] = useState("");

  const api = useAxios();

  const percent_formatter = new Intl.NumberFormat("en-SG", {
    maximumFractionDigits: 2,
  });

  const formatter = {
    numberFormatter: new Intl.NumberFormat("en-SG", {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: "code",
      currencySign: "accounting",
      maximumFractionDigits: 0,
    }),
    format: function(number) {
      const nDisplay = this.numberFormatter.formatToParts(number)
      .filter(x => x.type !== "currency")
      .map(x => x.value.trim())
      .join("")
      .trim();
      if (number < 0)
        return (<span style={{color: "red"}}>{nDisplay}</span>);
      else
      return nDisplay;
    }
  };

  useEffect(() => {
    const DATA_URL = process.env.REACT_APP_API_URL + "/income/summary/" + budgetYearId + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        setBudgetYear(result.data.budget_year);
        setFunds(extractUsedFunds(result.data.budget_year.incomes));
        // setIncomes(groupIncomeByPLCategory(result.data.budget_year.incomes));
        setIncomes(assignSN(result.data.grouped_income));

        if (result.data.ly_budget_year) {
          setLYIncomes(groupIncomeByPLCategory(result.data.ly_budget_year.incomes));
        }
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  const extractUsedFunds = (incomes) => {
    const usedFunds = [];
    const fundIds = [];
    incomes.forEach((i) => {
      i.income_funds.forEach((f) => {
          if (f.fund.hasOwnProperty('id')) {
            if (!fundIds.includes(f.fund.id)) {
              fundIds.push(f.fund.id);
              usedFunds.push({
                id: f.fund.id,
                code: f.fund.code,
                description: f.fund.description,
              });
            }
          }
      })
    });
    return usedFunds.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        if (a.description > b.description) {
          return 1;
        } else if (a.description < b.description) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  };

  const groupIncomeByPLCategory = (incomes) => {
    const incomeMap = new Map();
    incomes.forEach((i) => {
      const collection = incomeMap.get(i.pl_category.parent.parent.sort_order + '@' + i.pl_category.parent.parent.name);
      if (!collection) {
        incomeMap.set(i.pl_category.parent.parent.sort_order + '@' + i.pl_category.parent.parent.name, [i]);
      } else {
        collection.push(i);
      }
    });
    const groupedIncomes = Object.fromEntries(incomeMap);
    const sortedIncomes = Object.keys(groupedIncomes).sort().reduce(
      function (result, key) {
        result[key.slice(6)] = groupedIncomes[key];
        return result;
      }, {});
    // var j = 1;
    // Object.keys(sortedIncomes).forEach((cat) => {
    //   sortedIncomes[cat].forEach((inc) => {
    //     inc['income_no'] = j++;
    //   })
    // })
    return sortedIncomes;
  }

  const assignSN = (incomes) => {
    var j = 1;
    Object.keys(incomes).forEach((cat) => {
      incomes[cat].forEach((inc) => {
        inc['income_no'] = j++;
      })
    })
    return incomes;
  }

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };


  const downloadExel = () => {
    setIsDownloading(true);
    const EXCEL_DATA_URL = process.env.REACT_APP_API_URL + "/income/summary_excel/" + budgetYearId + "/";
    const fetchExcel = async () => {
      try {
        const result = await api.get(EXCEL_DATA_URL, {responseType: 'blob'});
        const contentDisposition = result.headers['content-disposition'];
        const fileName = contentDisposition.substring(
          contentDisposition.indexOf('filename=') + 9,
          contentDisposition.length
        );
        let blob = new Blob([result.data])
        blob = blob.slice(0, blob.size, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);  
        setIsDownloading(false);
      } catch {
        alert("Something went wrong. Please try again or contact support");
        setIsDownloading(false);
      }
    };
    fetchExcel();
  }


  return (
    <React.Fragment>
      {!user.is_portal_admin && !user.is_budget_viewer ? (
        <Navigate to="/dashboard" />
      ) : (
        <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          <div>
            <h4>
              Income Summary &mdash;{" "} Budget Year {budgetYear.year} {(budgetYear.label.length > 0) ? (<span>&mdash; {budgetYear.label}</span>) : ''}
            </h4>

            {isDownloading ? (
              <p className="float-end mb-2 me-2">Downloading...
                <Spinner size="sm" color="success">Downloading...</Spinner>
              </p>
            ) : (
              <Button onClick={downloadExel} title="Download Excel" className="float-end btn btn-primary btn-sm mb-2 me-2" color="primary" >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            )}
            <div className="clearfix"></div>

            {user.is_portal_admin ? (
              <p><Link className="btn btn-secondary btn-sm me-1" to={`/income/add/${budgetYearId}`}>Add Income Item</Link></p>
            ) : ''}

            {Object.keys(incomes).length > 0 ? (
              <React.Fragment>
                <Nav tabs>
                  <NavItem> <NavLink active={currentActiveTab === "1"} onClick={() => { toggle("1"); }} role="button" > Summary </NavLink> </NavItem>
                  <NavItem> <NavLink active={currentActiveTab === "2"} onClick={() => { toggle("2"); }} role="button" > By Fund </NavLink> </NavItem>
                  <NavItem> <NavLink active={currentActiveTab === "3"} onClick={() => { toggle("3"); }} role="button" > By Month </NavLink> </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <br />
                        <IncomeSummaryCombined user={user} incomes={incomes} funds={funds} formatter={formatter} pformatter={percent_formatter} budget_year={budgetYear} ly_incomes={lyIncomes} /> <hr />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <br />
                        <IncomeSummaryFund user={user} incomes={incomes} funds={funds} formatter={formatter} pformatter={percent_formatter} budget_year={budgetYear} ly_incomes={lyIncomes} /> <hr />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <br />
                        <IncomeSummaryMonth user={user} incomes={incomes} funds={funds} formatter={formatter} pformatter={percent_formatter} budget_year={budgetYear} ly_incomes={lyIncomes} /> <hr />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </React.Fragment>
            ) : (
              <p className="mt-3">
                <em>No income has been added</em>
              </p>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
      </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default IncomeSummaryComponent;
