import { useParams, Navigate, Link, useNavigate } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Button, } from "reactstrap";
import 'assets/css/login.css'
import { useContext } from "react";
import AuthContext from "context/AuthContext";

function Login(props) {


  let { user, loginUser }  = useContext(AuthContext);

  const navigate = useNavigate();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  // return user ? <Outlet /> : <Navigate to="/login" />;
  // const {loginUser} = useContext(AuthContext);
  const handleSubmit = e => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;
        username.length > 0 && loginUser(username, password);
    };
  
  return user ? <Navigate to="/dashboard" /> : (
    <div className="form-signin">
      <Form onSubmit={handleSubmit}>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
        <FormGroup floating>
          <Input
            id="username"
            name="username"
            placeholder="Username"
            type="text"
          />
          <Label for="username">Username</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            id="password"
            name="password"
            placeholder="Password"
            type="password"
          />
          <Label for="password">Password</Label>
        </FormGroup>
        {/* <FormGroup check className="mb-3">
          <Label check>
            <Input type="checkbox" name="remember" /> Remember me
          </Label>
        </FormGroup> */}
        <FormGroup>
          <p className="text-end"><Link to="/forgot_password">Forgot Password</Link>&nbsp;</p>
        </FormGroup>

        <Button className="w-100 btn btn-lg btn-primary" type="submit">
          Sign in
        </Button>
      </Form>
    </div>
  );
}

export default Login;
