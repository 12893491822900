import React from "react";
import {Row, Table, Spinner } from "reactstrap";
import {Link} from 'react-router-dom';
import 'assets/css/budgetsummary.css'

function BudgetCategorySummaryFund({budgets, funds, formatter, pformatter, year, lyBudgets, cat}) {

  let total_amount = 0;
  let fund_totals = {};
  let month_totals = {};
  let allocated = {};
  let allocated_total = 0;

  let ly_total_amount = 0;
  let ly_dept_totals = {}

  budgets.forEach((dept) => {
      total_amount += (dept.total) ? parseFloat(dept.total) : 0;
      allocated[dept.id] = (cat === 'exp') ? parseInt(dept.allocated_exp) : ((cat === 'capex') ? parseInt(dept.allocated_capex) : (parseInt(dept.allocated_exp) + parseInt(dept.allocated_capex)));
      allocated_total += allocated[dept.id];

      for (var sof_id in dept.sofs) {
        if (dept.sofs.hasOwnProperty(sof_id)) {
            if (fund_totals[sof_id])
                fund_totals[sof_id] += parseFloat(dept.sofs[sof_id]['total']);
            else
                fund_totals[sof_id] = parseFloat(dept.sofs[sof_id]['total']);
        }
      }

      for (var m in dept.months) {
        if (dept.months.hasOwnProperty(m)) {
            if (month_totals[m])
                month_totals[m] += parseFloat(dept.months[m]);
            else
                month_totals[m] = parseFloat(dept.months[m]);
        }
      }
  });

  lyBudgets.forEach((dept) => {
    ly_total_amount += (dept.total) ? parseFloat(dept.total) : 0;
    ly_dept_totals[dept.id] = dept.total;
  });

  return (
    <React.Fragment>
      <Row className="m-1">
      </Row>
      {(budgets) ? (
        <div className="tableFixHead">
        <Table id="budget-summary-combined" bordered size="sm">
          <thead className="bg-info">
            <tr>
              <th rowSpan={2} className="text-center"> </th>
              <th rowSpan={2} className="text-center"> Cost Centre </th>
              <th colSpan={((funds.length > 0) ? funds.length : 1)} className="text-center budget-summary-fund">Fund</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{year}</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{year-1}<br/></th>
              <th rowSpan={2} className="text-center"> Difference</th>
              <th rowSpan={2} className="text-center"> Difference (%)</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Allocated<br />Budget Year<br/>{year}</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Difference</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Difference (%)</th>
            </tr>
            <tr> 
              {(funds.length > 0) ? (
              funds.map((fund) => (
                <th key={`head${fund.id}`} className="text-center budget-summary-fund" style={{maxWidth: 80 + 'px'}}>{fund.code}<br/>{fund.description}</th>
              ))
              ) : <th className="text-center budget-summary-fund">-</th>}
            </tr>
          </thead>
          <tbody>
          {(budgets.length > 0) ? (
            <React.Fragment>
                {budgets.map((dept, i) => (
                    <tr key={dept.id}>
                        <td>{i+1}</td>
                        <td>
                          <Link className="text-decoration-none" to={`/budget/summary/${dept.budget_dept_id}`} target="_blank" style={{whiteSpace: "pre-wrap"}}>{dept.department_name}</Link>
                        </td>

                        {/* Funds Columns */}
                        {(funds.length > 0) ? (
                          funds.map((fund) => (
                          <td key={dept.id + '_' + fund.id} className="text-end budget-summary-fund">
                              {(dept.sofs) ? (
                                (dept.sofs[fund.id]) ? formatter.format(dept.sofs[fund.id].total) : '-'
                              ) : '-'}
                          </td>
                          ))
                        ) : <td className="text-end budget-summary-fund">-</td>}

                        <td className="text-end"><strong>{(dept.total) ? formatter.format(dept.total) : '-'}</strong></td>
                        <td className="text-end"><strong>{(ly_dept_totals[dept.id]) ? formatter.format(ly_dept_totals[dept.id]) : '-'}</strong></td>
                        <td className="text-end"><strong>{(ly_dept_totals[dept.id] && dept.total) ? formatter.format(dept.total - ly_dept_totals[dept.id]) : '-'}</strong></td>
                        <td className="text-end"><strong>{(ly_dept_totals[dept.id] && dept.total) ? pformatter.format((dept.total - ly_dept_totals[dept.id])*100/ly_dept_totals[dept.id]) + "%" : '-'}</strong></td>
                        <td className="text-end"><strong>{(allocated[dept.id] > 0) ? formatter.format(allocated[dept.id]) : "-"}</strong></td>
                        <td className="text-end">
                          <strong>
                            {(dept.total && dept.total > 0) ? formatter.format(dept.total - allocated[dept.id]) : '-'}
                          </strong>
                        </td>
                        <td className="text-end">
                          <strong>
                            {(dept.total && dept.total > 0 && allocated[dept.id] !== 0) ? (
                              pformatter.format((dept.total - allocated[dept.id])*100/allocated[dept.id]) + "%"
                            ) : '-'}
                          </strong>
                        </td>
                    </tr>
                ))}
            </React.Fragment>
          ) : ''}

          </tbody>
          <tfoot>
            <tr className="budget-summary-grand-total">
                <td colSpan={2} className="text-end"><strong>GRAND TOTAL:</strong></td>
                {(funds.length > 0) ? (
                funds.map((fund) => (
                  <td key={fund.id + '_grand_total'} className="text-end budget-summary-fund">
                    <strong>{formatter.format(fund_totals[fund.id])}</strong>
                  </td>
                ))) : <td className="text-end budget-summary-fund">-</td>
                }
                <td className="text-end"><strong>{(total_amount > 0) ? formatter.format(total_amount) : "-"}</strong></td>
                <td className="text-end"><strong>{(ly_total_amount > 0) ? formatter.format(ly_total_amount) : "-"}</strong></td>
                <td className="text-end"><strong>{(ly_total_amount > 0 && total_amount > 0) ? formatter.format(total_amount - ly_total_amount) : "-"}</strong></td>
                <td className="text-end"><strong>{(ly_total_amount > 0 && total_amount > 0) ? pformatter.format((total_amount - ly_total_amount)*100/ly_total_amount) + "%" : "-"}</strong></td>
                <td className="text-end budget-summary-allocated"><strong>{(allocated_total > 0) ? formatter.format(allocated_total) : "-"}</strong></td>
                <td className="text-end budget-summary-allocated">
                  <strong>
                    {(total_amount > 0) ? formatter.format(total_amount - allocated_total) : '-'}
                  </strong>
                </td>
                <td className="text-end budget-summary-allocated">
                  <strong>
                    {(total_amount > 0 && allocated_total !== 0) ? (
                          pformatter.format((total_amount - allocated_total)*100/allocated_total) + "%"
                    ) : '-'}
                  </strong>
                </td>
              </tr>
          </tfoot>
        </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default BudgetCategorySummaryFund;