import React, { useState, useEffect, useContext } from "react";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Spinner} from "reactstrap";


import 'assets/css/sidebar.css'
import AuthContext from "context/AuthContext";


function UserProfile(props) {

  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [username, setUsername] = useState(user.username);
  const [fullname, setFullname] = useState(user.fullname);
  const [email, setEmail] = useState(user.email);

  const api = useAxios();

  useEffect(() => {

    const USER_DATA_URL = process.env.REACT_APP_API_URL + "/account/user/" + user.id + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(USER_DATA_URL);
        setUsername(result.data.username);
        setFullname(result.data.fullname);
        setEmail(result.data.email);
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }  
    }
    fetchData();
  }, []);

  function validate() {
    if (!fullname) {
        alert("Please enter your name");
        return false;
    }
    if (!email) {
        alert("Please your email address");
        return false;
    }
    return true;
}


  function handleSubmit(event) {
    setIsSaving(true);
    const PUT_URL = process.env.REACT_APP_API_URL + "/account/user/" + user.id + "/";
    if (validate()) {
        api.put(PUT_URL, {
            'username': username,
            'fullname': fullname,
            'email': email,
        })
        .then(async function (response) {
            alert("Your profile has been successfully updated");
        })
        .catch(function (error) {
            alert(error.message);
        });
    }
    setIsSaving(false);
    event.preventDefault();
}



  return (
    <React.Fragment>
      {(!isLoading) ? (
        <React.Fragment>
            <div className="m-2">
                <h4>My Profile</h4>
                <hr />
                <Form onSubmit={handleSubmit}>
                  <FormGroup row>
                        <Label for="username" sm={2}>
                            Username
                        </Label>
                        <Col sm={6}>
                            <Input id="username" name="username" type="text" bsSize="sm"
                                value={user.username} readOnly disabled />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" sm={2}>
                            Name
                        </Label>
                        <Col sm={6}>
                            <Input id="name" name="name" placeholder="name" type="text" bsSize="sm"
                                value={fullname} onChange={(e) => setFullname(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="email" sm={2}>
                            Email
                        </Label>
                        <Col sm={6}>
                            <Input id="email" name="email" placeholder="email" type="text" bsSize="sm"
                                value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            {(isSaving) ? (
                              <span>
                                <Spinner size="sm">Updating, please wait...</Spinner> <span>Updating, please wait...</span>
                              </span>
                            ) : (
                              <Button type="submit" id="Submit" name="Submit" color="primary">
                                  Update
                              </Button>
                            )}
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );

}

export default UserProfile;
