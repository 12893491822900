import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Spinner, Button, Table } from "reactstrap";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function PnLExpense(props) {

  const { user } = useContext(AuthContext);

  let { budgetYearId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showLevel, setShowLevel] = useState([1]);
  const [buttonStyles, setButtonStyles] = useState(['btn-success','','',''])

  const [showLevelInc, setShowLevelInc] = useState([1]);
  const [buttonStylesInc, setButtonStylesInc] = useState(['btn-success','','',''])

  const [pnl_data, setPnlData] = useState({});

  const [error, setError] = useState("");

  const api = useAxios();

  const percent_formatter = new Intl.NumberFormat("en-SG", {
    maximumFractionDigits: 2,
  });

  const formatter = {
    numberFormatter: new Intl.NumberFormat("en-SG", {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: "symbol",
      currencySign: "accounting",
      maximumFractionDigits: 0,
    }),
    format: function(number) {
      const nDisplay = this.numberFormatter.formatToParts(number)
    //   .filter(x => x.type !== "currency")
      .map(x => x.value.trim())
      .join("")
      .trim();
      if (number < 0)
        return (<span style={{color: "red"}}>{nDisplay}</span>);
      else
      return nDisplay;
    }
  };

  useEffect(() => {
    const DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/pnl_expense/" + budgetYearId + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        setPnlData(result.data);
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  const downloadExel = () => {
    setIsDownloading(true);
    const EXCEL_DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/pnl_excel/expense/" + budgetYearId + "/";
    const fetchExcel = async () => {
      try {
        const result = await api.get(EXCEL_DATA_URL, {responseType: 'blob'});
        const contentDisposition = result.headers['content-disposition'];
        const fileName = contentDisposition.substring(
          contentDisposition.indexOf('filename=') + 9,
          contentDisposition.length
        );
        let blob = new Blob([result.data])
        blob = blob.slice(0, blob.size, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);  
        setIsDownloading(false);
      } catch {
        alert("Something went wrong. Please try again or contact support");
        setIsDownloading(false);
      }
    };
    fetchExcel();
  }

  const setViewLevel = (level) => {
    const updatedShowLevel = [...showLevel];
    if (showLevel.includes(level)) {
        const li = updatedShowLevel.indexOf(level);
        updatedShowLevel.splice(li,1);
    } else {
        updatedShowLevel.push(level);
    }
    setShowLevel(updatedShowLevel);
    const newButtonStyle = [...buttonStyles];
    for (var i=1; i<5; ++i) {
        if (updatedShowLevel.includes(i)) {
            newButtonStyle[i-1] = 'btn-success';
        } else {
            newButtonStyle[i-1] = '';
        }
    }
    setButtonStyles(newButtonStyle);
  }

  const setViewLevelInc = (level) => {
    const updatedShowLevel = [...showLevelInc];
    if (showLevelInc.includes(level)) {
        const li = updatedShowLevel.indexOf(level);
        updatedShowLevel.splice(li,1);
    } else {
        updatedShowLevel.push(level);
    }
    setShowLevelInc(updatedShowLevel);
    const newButtonStyle = [...buttonStylesInc];
    for (var i=1; i<5; ++i) {
        if (updatedShowLevel.includes(i)) {
            newButtonStyle[i-1] = 'btn-success';
        } else {
            newButtonStyle[i-1] = '';
        }
    }
    setButtonStylesInc(newButtonStyle);
  }

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          {!user.is_portal_admin && !user.is_school_leader ? (
            <Navigate to="/dashboard" />
          ) : (
            <div>
              <h4>Profit and Loss by Expense: Budget Year {pnl_data['budget_year']['year']}</h4>

              {isDownloading ? (
                <p className="float-end mb-2 me-2">Downloading...
                    <Spinner size="sm" color="success">Downloading...</Spinner>
                </p>
              ) : (
                <Button onClick={downloadExel} title="Download Excel" className="float-end btn btn-primary btn-sm mb-2 me-2" color="primary" >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              )}

              <div className="float-start">
                <small>
                <span className="me-2">View Income Level:</span>
                <div className="btn-group">
                {[1,2,3,4].map((i) => (
                    <button key={`btn_inc_${i}`} className={`btn btn-sm ${buttonStylesInc[i-1]}`} onClick={() => setViewLevelInc(i)}>{i}</button>
                ))}
                </div>
                <span className="ms-3 me-2">View Expense Level:</span>
                <div className="btn-group">
                {[1,2,3,4].map((i) => (
                    <button key={`btn_${i}`} className={`btn btn-sm ${buttonStyles[i-1]}`} onClick={() => setViewLevel(i)}>{i}</button>
                ))}
                </div>
                </small>
              </div>


              <div className="clearfix"></div> 

              {(Object.keys(pnl_data).length > 0) ? (
                <React.Fragment>
                    <div className="tableFixHead">
                        <Table id="pnl-expense" size="sm" className="w-75 table table-hover">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Description</th>
                                    <th className="text-center">{pnl_data['budget_year']['year']-1}</th>
                                    <th className="text-center">{pnl_data['budget_year']['year']}</th>
                                    <th className="text-center">Difference</th>
                                    <th className="text-center">Difference (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(pnl_data['incomes']) ? (
                                    <React.Fragment>
                                        {/* Loop Income Items */}

                                        {(showLevelInc.includes(1) || showLevelInc.includes(2) || showLevelInc.includes(3) || showLevelInc.includes(4)) ? (

                                            Object.keys(pnl_data['incomes']).map((l1_id) => (
                                                // Level 1 Income
                                                (pnl_data['incomes'][l1_id]['amount'] != 0 || (pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['amount'] != 0)) ? (

                                                <React.Fragment key={`inc_l1_${l1_id}`}>

                                                    {(showLevelInc.includes(2) || showLevelInc.includes(3) || showLevelInc.includes(4)) ? (
                                                        Object.keys(pnl_data['incomes'][l1_id]['children']).map((l2_id) => (
                                                            // Level 2 Income
                                                            (pnl_data['incomes'][l1_id]['children'][l2_id]['amount'] != 0 || (pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount'] != 0)) ? (

                                                            <React.Fragment key={`inc_l2_${l2_id}`}>

                                                                {(showLevelInc.includes(3) || showLevelInc.includes(4)) ? (
                                                                    Object.keys(pnl_data['incomes'][l1_id]['children'][l2_id]['children']).map((l3_id) => (
                                                                        // Level 3 Income
                                                                        (pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0 || (pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0)) ? (

                                                                        <React.Fragment key={`inc_l3_${l3_id}`}>
                                                                            
                                                                            {(showLevelInc.includes(4)) ? (
                                                                                Object.keys(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children']).map((l4_id) => (
                                                                                    // Level 4 Income
                                                                                    (pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0 || (pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0)) ? (

                                                                                    <React.Fragment key={`inc_l4_${l4_id}`}>
                                                                                        <tr className="level-4">
                                                                                            <td colSpan={2}>{pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['name']}</td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_incomes']) ? 
                                                                                                    formatter.format(pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) :
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td className="text-end">{formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])}</td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_incomes']) ? 
                                                                                                    formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) :
                                                                                                    formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])
                                                                                                }
                                                                                            </td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0) ? (
                                                                                                    (pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0) ?
                                                                                                        percent_formatter.format(((pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])*100) / pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) + '%'
                                                                                                        : '0%') : '-'
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                    
                                                                                    ) : ''
                                                                                ))
                                                                            ) : ''}

                                                                            {(showLevelInc.includes(3)) ? (
                                                                            <tr className="level-3">
                                                                                <td colSpan={2}>{pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['name']}</td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_incomes']) ? 
                                                                                        formatter.format(pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) :
                                                                                        '-'}
                                                                                </td>
                                                                                <td className="text-end">{formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])}</td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_incomes']) ? 
                                                                                        formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) :
                                                                                        formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])
                                                                                    }
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0) ? (
                                                                                        (pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0) ?
                                                                                            percent_formatter.format(((pnl_data['incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])*100) / pnl_data['ly_incomes'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) + '%'
                                                                                            : '0%') : '-'
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            ) : ''}
                                                                        
                                                                        </React.Fragment>

                                                                        ) : ''

                                                                    ))
                                                                ) : ''}

                                                                {(showLevelInc.includes(2)) ? (
                                                                <tr className="level-2">
                                                                    <td colSpan={2}><em>{pnl_data['incomes'][l1_id]['children'][l2_id]['name']}</em></td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_incomes']) ? 
                                                                            formatter.format(pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount']) :
                                                                            '-'}
                                                                    </td>
                                                                    <td className="text-end">{formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['amount'])}</td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_incomes']) ? 
                                                                            formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount']) :
                                                                            formatter.format(pnl_data['incomes'][l1_id]['children'][l2_id]['amount'])
                                                                        }
                                                                    </td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount'] != 0) ? (
                                                                            (pnl_data['incomes'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount'] != 0) ?
                                                                                percent_formatter.format(((pnl_data['incomes'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount'])*100) / pnl_data['ly_incomes'][l1_id]['children'][l2_id]['amount']) + '%'
                                                                                : '0%') : '-'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                ) : ''}

                                                            </React.Fragment>

                                                            ) : ''

                                                        ))
                                                    ) : ''}

                                                    {(showLevelInc.includes(1)) ? (
                                                        <tr className="level-1 pnl-income-row">
                                                            <td colSpan={2}><strong>{pnl_data['incomes'][l1_id]['name']}</strong></td>
                                                            <td className="text-end"><strong>
                                                                {(pnl_data['ly_incomes']) ? 
                                                                    formatter.format(pnl_data['ly_incomes'][l1_id]['amount']) :
                                                                    '-'}
                                                                </strong>
                                                            </td>
                                                            <td className="text-end"><strong>{formatter.format(pnl_data['incomes'][l1_id]['amount'])}</strong></td>
                                                            <td className="text-end">
                                                                {(pnl_data['ly_incomes']) ? 
                                                                    formatter.format(pnl_data['incomes'][l1_id]['amount'] - pnl_data['ly_incomes'][l1_id]['amount']) :
                                                                    formatter.format(pnl_data['incomes'][l1_id]['amount'])
                                                                }
                                                            </td>
                                                            <td className="text-end">
                                                                {(pnl_data['ly_incomes'] && pnl_data['ly_incomes'][l1_id]['amount'] != 0) ? (
                                                                    (pnl_data['incomes'][l1_id]['amount'] - pnl_data['ly_incomes'][l1_id]['amount'] != 0) ?
                                                                        percent_formatter.format(((pnl_data['incomes'][l1_id]['amount'] - pnl_data['ly_incomes'][l1_id]['amount'])*100) / pnl_data['ly_incomes'][l1_id]['amount']) + '%'
                                                                        : '0%') : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                    ) : ''}
                                                    <tr className="level-1 pnl-divider-1"><td colSpan={6}></td></tr>
                                                    

                                                </React.Fragment>

                                                ) : ''
                                            ))

                                        ) : ''}

                                            <tr className="level-0">
                                                <td colSpan={2}><strong>TOTAL INCOME</strong></td>
                                                <td className="text-end"><strong>{(pnl_data['ly_income_total']) ? formatter.format(pnl_data['ly_income_total']) : '-'}</strong></td>
                                                <td className="text-end"><strong>{formatter.format(pnl_data['income_total'])}</strong></td>
                                                <td className="text-end">
                                                    <strong>
                                                    {(pnl_data['ly_income_total']) ? 
                                                        formatter.format(pnl_data['income_total'] - pnl_data['ly_income_total']) :
                                                        formatter.format(pnl_data['income_total'])
                                                    }
                                                    </strong>
                                                </td>
                                                <td className="text-end">
                                                    <strong>
                                                    {(pnl_data['ly_income_total'] && pnl_data['income_total'] != 0) ? (
                                                        (pnl_data['income_total'] - pnl_data['ly_income_total'] != 0) ?
                                                            percent_formatter.format(((pnl_data['income_total'] - pnl_data['ly_income_total'])*100) / pnl_data['ly_income_total']) + '%'
                                                            : '0%') : '-'
                                                    }
                                                    </strong>
                                                </td>
                                            </tr>
                                        
                                    </React.Fragment>
                                ) : ''}

                                {(pnl_data['expenses']) ? (
                                    <React.Fragment>
                                        {/* Loop Expense Items */}
                                        <tr className="pnl-divider-2"><td colSpan={6}> </td></tr>

                                        {(showLevel.includes(1) || showLevel.includes(2) || showLevel.includes(3) || showLevel.includes(4)) ? (

                                            Object.keys(pnl_data['expenses']).map((l1_id) => (
                                                // Level 1 Expense
                                                (pnl_data['expenses'][l1_id]['amount'] != 0 || (pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['amount'] != 0)) ? (

                                                <React.Fragment key={`inc_l1_${l1_id}`}>

                                                    {(showLevel.includes(2) || showLevel.includes(3) || showLevel.includes(4)) ? (
                                                        Object.keys(pnl_data['expenses'][l1_id]['children']).map((l2_id) => (
                                                            // Level 2 Expense
                                                            (pnl_data['expenses'][l1_id]['children'][l2_id]['amount'] != 0 || (pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount'] != 0)) ? (

                                                            <React.Fragment key={`inc_l2_${l2_id}`}>

                                                                {(showLevel.includes(3) || showLevel.includes(4)) ? (
                                                                    Object.keys(pnl_data['expenses'][l1_id]['children'][l2_id]['children']).map((l3_id) => (
                                                                        // Level 3 Expense
                                                                        (pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0 || (pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0)) ? (

                                                                        <React.Fragment key={`inc_l3_${l3_id}`}>
                                                                            
                                                                            {(showLevel.includes(4)) ? (
                                                                                Object.keys(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children']).map((l4_id) => (
                                                                                    // Level 4 Expense
                                                                                    (pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0 || (pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0)) ? (

                                                                                    <React.Fragment key={`inc_l4_${l4_id}`}>
                                                                                        <tr className="level-4">
                                                                                            <td>{pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['acc_number']}</td>
                                                                                            <td>{pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['name']}</td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_expenses']) ?
                                                                                                formatter.format(pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) :
                                                                                                '-'}
                                                                                            </td>
                                                                                            <td className="text-end">{formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])}</td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_expenses']) ? 
                                                                                                    formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) :
                                                                                                    formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])
                                                                                                }
                                                                                            </td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0) ? (
                                                                                                    (pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0) ?
                                                                                                        percent_formatter.format(((pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])*100) / pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) + '%'
                                                                                                        : '0%') : '-'
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </React.Fragment>

                                                                                    ) : ''
                                                                                    
                                                                                ))
                                                                            ) : ''}

                                                                            {(showLevel.includes(3)) ? (
                                                                            <tr className="level-3">
                                                                                <td colSpan={2}>{pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['name']}</td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_expenses']) ?
                                                                                        formatter.format(pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) :
                                                                                        '-'}
                                                                                </td>
                                                                                <td className="text-end">{formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])}</td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_expenses']) ? 
                                                                                        formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) :
                                                                                        formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])
                                                                                    }
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0) ? (
                                                                                        (pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0) ?
                                                                                            percent_formatter.format(((pnl_data['expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])*100) / pnl_data['ly_expenses'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) + '%'
                                                                                            : '0%') : '-'
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            ) : ''}
                                                                        
                                                                        </React.Fragment>

                                                                        ) : ''

                                                                    ))
                                                                ) : ''}

                                                                {(showLevel.includes(2)) ? (
                                                                <tr className="level-2">
                                                                    <td colSpan={2}><em>{pnl_data['expenses'][l1_id]['children'][l2_id]['name']}</em></td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_expenses']) ?
                                                                            formatter.format(pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount']) :
                                                                            '-'}
                                                                    </td>
                                                                    <td className="text-end">{formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['amount'])}</td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_expenses']) ? 
                                                                            formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount']) :
                                                                            formatter.format(pnl_data['expenses'][l1_id]['children'][l2_id]['amount'])
                                                                        }
                                                                    </td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount'] != 0) ? (
                                                                            (pnl_data['expenses'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount'] != 0) ?
                                                                                percent_formatter.format(((pnl_data['expenses'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount'])*100) / pnl_data['ly_expenses'][l1_id]['children'][l2_id]['amount']) + '%'
                                                                                : '0%') : '-'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                ) : ''}

                                                            </React.Fragment>

                                                            ) : ''

                                                        ))
                                                    ) : ''}

                                                    {(showLevel.includes(1)) ? (
                                                        <tr className="level-1 pnl-income-row">
                                                            <td colSpan={2}><strong>{pnl_data['expenses'][l1_id]['name']}</strong></td>
                                                            <td className="text-end"><strong>
                                                                {(pnl_data['ly_expenses']) ?
                                                                    formatter.format(pnl_data['ly_expenses'][l1_id]['amount']) :
                                                                    '-'}
                                                                </strong>
                                                            </td>
                                                            <td className="text-end"><strong>{formatter.format(pnl_data['expenses'][l1_id]['amount'])}</strong></td>
                                                            <td className="text-end">
                                                                {(pnl_data['ly_expenses']) ? 
                                                                    formatter.format(pnl_data['expenses'][l1_id]['amount'] - pnl_data['ly_expenses'][l1_id]['amount']) :
                                                                    formatter.format(pnl_data['expenses'][l1_id]['amount'])
                                                                }
                                                            </td>
                                                            <td className="text-end">
                                                                {(pnl_data['ly_expenses'] && pnl_data['ly_expenses'][l1_id]['amount'] != 0) ? (
                                                                    (pnl_data['expenses'][l1_id]['amount'] - pnl_data['ly_expenses'][l1_id]['amount'] != 0) ?
                                                                        percent_formatter.format(((pnl_data['expenses'][l1_id]['amount'] - pnl_data['ly_expenses'][l1_id]['amount'])*100) / pnl_data['ly_expenses'][l1_id]['amount']) + '%'
                                                                        : '0%') : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                    ) : ''}
                                                    <tr className="level-1 pnl-divider-1"><td colSpan={6}></td></tr>
                                                    

                                                </React.Fragment>

                                                ) : ''
                                            ))

                                        ) : ''}

                                            <tr className="level-0">
                                                <td colSpan={2}><strong>TOTAL OPERATING EXPENSES</strong></td>
                                                <td className="text-end"><strong>{(pnl_data['ly_expense_total']) ? formatter.format(pnl_data['ly_expense_total']) : '-'}</strong></td>
                                                <td className="text-end"><strong>{formatter.format(pnl_data['expense_total'])}</strong></td>
                                                <td className="text-end">
                                                    <strong>
                                                    {(pnl_data['ly_expense_total']) ? 
                                                        formatter.format(pnl_data['expense_total'] - pnl_data['ly_expense_total']) :
                                                        formatter.format(pnl_data['expense_total'])
                                                    }
                                                    </strong>
                                                </td>
                                                <td className="text-end">
                                                    <strong>
                                                    {(pnl_data['ly_expense_total'] && pnl_data['expense_total'] != 0) ? (
                                                        (pnl_data['expense_total'] - pnl_data['ly_expense_total'] != 0) ?
                                                            percent_formatter.format(((pnl_data['expense_total'] - pnl_data['ly_expense_total'])*100) / pnl_data['ly_expense_total']) + '%'
                                                            : '0%') : '-'
                                                    }
                                                    </strong>
                                                </td>
                                            </tr>

                                    </React.Fragment>
                                ) : ''}

                                {(pnl_data['surplus_deficit']) ? (
                                    <React.Fragment>

                                        <tr className="pnl-divider-2"><td colSpan={6}> </td></tr>

                                        <tr className="level-0">
                                            <td colSpan={2}>
                                                <strong>
                                                NET {(pnl_data['surplus_deficit'] > 0) ? '(SURPLUS)' : '(DEFICIT)'}
                                                </strong>
                                            </td>
                                            <td className="text-end"><strong>{(pnl_data['ly_surplus_deficit']) ? formatter.format(pnl_data['ly_surplus_deficit']) : '-'}</strong></td>
                                            <td className="text-end"><strong>{formatter.format(pnl_data['surplus_deficit'])}</strong></td>
                                            <td className="text-end">
                                                <strong>
                                                {(pnl_data['ly_surplus_deficit']) ? 
                                                    formatter.format(pnl_data['surplus_deficit'] - pnl_data['ly_surplus_deficit']) :
                                                    formatter.format(pnl_data['surplus_deficit'])
                                                }
                                                </strong>
                                            </td>
                                            <td className="text-end">
                                                <strong>
                                                {(pnl_data['ly_surplus_deficit'] && pnl_data['surplus_deficit'] != 0) ? (
                                                    (pnl_data['surplus_deficit'] - pnl_data['ly_surplus_deficit'] != 0) ?
                                                        percent_formatter.format(((pnl_data['surplus_deficit'] - pnl_data['ly_surplus_deficit'])*100) / pnl_data['ly_surplus_deficit']) + '%'
                                                        : '0%') : '-'
                                                }
                                                </strong>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ) : ''}

                                {(pnl_data['capex']) ? (
                                    <React.Fragment>
                                        {/* Loop Capex Items */}
                                        <tr className="pnl-divider-2"><td colSpan={6}> </td></tr>

                                        {(showLevel.includes(1) || showLevel.includes(2) || showLevel.includes(3) || showLevel.includes(4)) ? (

                                            Object.keys(pnl_data['capex']).map((l1_id) => (
                                                // Level 1 Capex
                                                (pnl_data['capex'][l1_id]['amount'] != 0 || (pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['amount'] != 0)) ? (

                                                <React.Fragment key={`inc_l1_${l1_id}`}>

                                                    {(showLevel.includes(2) || showLevel.includes(3) || showLevel.includes(4)) ? (
                                                        Object.keys(pnl_data['capex'][l1_id]['children']).map((l2_id) => (
                                                            // Level 2 Capex
                                                            (pnl_data['capex'][l1_id]['children'][l2_id]['amount'] != 0 || (pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount'] != 0)) ? (

                                                            <React.Fragment key={`inc_l2_${l2_id}`}>

                                                                {(showLevel.includes(3) || showLevel.includes(4)) ? (
                                                                    Object.keys(pnl_data['capex'][l1_id]['children'][l2_id]['children']).map((l3_id) => (
                                                                        // Level 3 Capex
                                                                        (pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0 || (pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0)) ? (

                                                                        <React.Fragment key={`inc_l3_${l3_id}`}>
                                                                            
                                                                            {(showLevel.includes(4)) ? (
                                                                                Object.keys(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children']).map((l4_id) => (
                                                                                    // Level 4 Capex
                                                                                    (pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0 || (pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0)) ? (

                                                                                    <React.Fragment key={`inc_l4_${l4_id}`}>
                                                                                        <tr className="level-4">
                                                                                            <td>{pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['acc_number']}</td>
                                                                                            <td>{pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['name']}</td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_capex']) ?
                                                                                                    formatter.format(pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) :
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td className="text-end">{formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])}</td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_capex']) ? 
                                                                                                    formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) :
                                                                                                    formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])
                                                                                                }
                                                                                            </td>
                                                                                            <td className="text-end">
                                                                                                {(pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0) ? (
                                                                                                    (pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] != 0) ?
                                                                                                        percent_formatter.format(((pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount'])*100) / pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['children'][l4_id]['amount']) + '%'
                                                                                                        : '0%') : '-'
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </React.Fragment>

                                                                                    ) : ''
                                                                                    
                                                                                ))
                                                                            ) : ''}

                                                                            {(showLevel.includes(3)) ? (
                                                                            <tr className="level-3">
                                                                                <td colSpan={2}>{pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['name']}</td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_capex']) ? 
                                                                                        formatter.format(pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) : 
                                                                                        '-'}
                                                                                </td>
                                                                                <td className="text-end">{formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])}</td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_capex']) ? 
                                                                                        formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) :
                                                                                        formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])
                                                                                    }
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    {(pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0) ? (
                                                                                        (pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] != 0) ?
                                                                                            percent_formatter.format(((pnl_data['capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount'])*100) / pnl_data['ly_capex'][l1_id]['children'][l2_id]['children'][l3_id]['amount']) + '%'
                                                                                            : '0%') : '-'
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            ) : ''}
                                                                        
                                                                        </React.Fragment>

                                                                        ) : ''

                                                                    ))
                                                                ) : ''}

                                                                {(showLevel.includes(2)) ? (
                                                                <tr className="level-2">
                                                                    <td colSpan={2}><em>{pnl_data['capex'][l1_id]['children'][l2_id]['name']}</em></td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_capex']) ? 
                                                                            formatter.format(pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount']) :
                                                                            '-'}
                                                                    </td>
                                                                    <td className="text-end">{formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['amount'])}</td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_capex']) ? 
                                                                            formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount']) :
                                                                            formatter.format(pnl_data['capex'][l1_id]['children'][l2_id]['amount'])
                                                                        }
                                                                    </td>
                                                                    <td className="text-end">
                                                                        {(pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount'] != 0) ? (
                                                                            (pnl_data['capex'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount'] != 0) ?
                                                                                percent_formatter.format(((pnl_data['capex'][l1_id]['children'][l2_id]['amount'] - pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount'])*100) / pnl_data['ly_capex'][l1_id]['children'][l2_id]['amount']) + '%'
                                                                                : '0%') : '-'
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                ) : ''}

                                                            </React.Fragment>

                                                            ) : ''

                                                        ))
                                                    ) : ''}

                                                    {(showLevel.includes(1)) ? (
                                                        <tr className="level-1 pnl-income-row">
                                                            <td colSpan={2}><strong>{pnl_data['capex'][l1_id]['name']}</strong></td>
                                                            <td className="text-end"><strong>
                                                                {(pnl_data['ly_capex']) ? 
                                                                    formatter.format(pnl_data['ly_capex'][l1_id]['amount']) :
                                                                    '-'}
                                                                </strong>
                                                            </td>
                                                            <td className="text-end"><strong>{formatter.format(pnl_data['capex'][l1_id]['amount'])}</strong></td>
                                                            <td className="text-end">
                                                                {(pnl_data['ly_capex']) ? 
                                                                    formatter.format(pnl_data['capex'][l1_id]['amount'] - pnl_data['ly_capex'][l1_id]['amount']) :
                                                                    formatter.format(pnl_data['capex'][l1_id]['amount'])
                                                                }
                                                            </td>
                                                            <td className="text-end">
                                                                {(pnl_data['ly_capex'] && pnl_data['ly_capex'][l1_id]['amount'] != 0) ? (
                                                                    (pnl_data['capex'][l1_id]['amount'] - pnl_data['ly_capex'][l1_id]['amount'] != 0) ?
                                                                        percent_formatter.format(((pnl_data['capex'][l1_id]['amount'] - pnl_data['ly_capex'][l1_id]['amount'])*100) / pnl_data['ly_capex'][l1_id]['amount']) + '%'
                                                                        : '0%') : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                    ) : ''}
                                                    <tr className="level-1 pnl-divider-1"><td colSpan={6}></td></tr>
                                                    

                                                </React.Fragment>

                                                ) : ''
                                            ))

                                        ) : ''}

                                        <tr className="level-0">
                                            <td colSpan={2}><strong>TOTAL CAPITAL EXPENDITURE</strong></td>
                                            <td className="text-end"><strong>{(pnl_data['ly_capex_total']) ? formatter.format(pnl_data['ly_capex_total']) : '-'}</strong></td>
                                            <td className="text-end"><strong>{formatter.format(pnl_data['capex_total'])}</strong></td>
                                            <td className="text-end">
                                                <strong>
                                                {(pnl_data['ly_capex_total']) ? 
                                                    formatter.format(pnl_data['capex_total'] - pnl_data['ly_capex_total']) :
                                                    formatter.format(pnl_data['capex_total'])
                                                }
                                                </strong>
                                            </td>
                                            <td className="text-end">
                                                <strong>
                                                {(pnl_data['ly_capex_total'] && pnl_data['capex_total'] != 0) ? (
                                                    (pnl_data['capex_total'] - pnl_data['ly_capex_total'] != 0) ?
                                                        percent_formatter.format(((pnl_data['capex_total'] - pnl_data['ly_capex_total'])*100) / pnl_data['ly_capex_total']) + '%'
                                                        : '0%') : '-'
                                                }
                                                </strong>
                                            </td>
                                        </tr>

                                    </React.Fragment>
                                ) : ''}

                                {(pnl_data['net_surplus_deficit']) ? (
                                    <React.Fragment>

                                        <tr className="pnl-divider-2"><td colSpan={6}> </td></tr>

                                        <tr className="level-0">
                                            <td colSpan={2}>
                                                <strong>
                                                NET {(pnl_data['net_surplus_deficit'] > 0) ? '(SURPLUS)' : '(DEFICIT)'} AFTER CAPEX
                                                </strong>
                                            </td>
                                            <td className="text-end"><strong>{(pnl_data['ly_net_surplus_deficit']) ? formatter.format(pnl_data['ly_net_surplus_deficit'])  : '-'}</strong></td>
                                            <td className="text-end"><strong>{formatter.format(pnl_data['net_surplus_deficit'])}</strong></td>
                                            <td className="text-end">
                                                <strong>
                                                {(pnl_data['ly_net_surplus_deficit']) ? 
                                                    formatter.format(pnl_data['net_surplus_deficit'] - pnl_data['ly_net_surplus_deficit']) :
                                                    formatter.format(pnl_data['net_surplus_deficit'])
                                                }
                                                </strong>
                                            </td>
                                            <td className="text-end">
                                                <strong>
                                                {(pnl_data['ly_net_surplus_deficit'] && pnl_data['net_surplus_deficit'] != 0) ? (
                                                    (pnl_data['net_surplus_deficit'] - pnl_data['ly_net_surplus_deficit'] != 0) ?
                                                        percent_formatter.format(((pnl_data['net_surplus_deficit'] - pnl_data['ly_net_surplus_deficit'])*100) / pnl_data['ly_net_surplus_deficit']) + '%'
                                                        : '0%') : '-'
                                                }
                                                </strong>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ) : ''}

                            </tbody>
                        </Table>
                    </div>
                </React.Fragment>
              ) : (
                <p className="mt-3">
                  <em>Profit and Loss data not available</em>
                </p>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner className="mb-3" color="info">Preparing Profit & Loss by Expense...</Spinner>
          <p>Preparing Profit & Loss by Expense, please wait ...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default PnLExpense;
