import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Spinner, Button } from "reactstrap";
import "assets/css/sidebar.css";
import AuthContext from "context/AuthContext";
import ProfitAndLossCombined from "components/pnl/ProfitAndLossCombinedComponent";
import ProfitAndLossFund from "components/pnl/ProfitAndLossFundComponent";
import ProfitAndLossMonth from "components/pnl/ProfitAndLossMonthComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function ProfitAndLoss(props) {

  const { user } = useContext(AuthContext);

  let { budgetYearId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const [pnl_data, setPnlData] = useState({});

  const [error, setError] = useState("");

  const api = useAxios();

  const percent_formatter = new Intl.NumberFormat("en-SG", {
    maximumFractionDigits: 2,
  });

  const formatter = {
    numberFormatter: new Intl.NumberFormat("en-SG", {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: "code",
      currencySign: "accounting",
      maximumFractionDigits: 0,
    }),
    format: function(number) {
      const nDisplay = this.numberFormatter.formatToParts(number)
      .filter(x => x.type !== "currency")
      .map(x => x.value.trim())
      .join("")
      .trim();
      if (number < 0)
        return (<span style={{color: "red"}}>{nDisplay}</span>);
      else
      return nDisplay;
    }
  };

  useEffect(() => {
    const DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/profit_and_loss/" + budgetYearId + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        setPnlData(result.data);
        console.log(JSON.stringify(result.data));
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const downloadExel = () => {
    setIsDownloading(true);
    const EXCEL_DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/profit_and_loss_excel/" + budgetYearId + "/";
    const fetchExcel = async () => {
      try {
        const result = await api.get(EXCEL_DATA_URL, {responseType: 'blob'});
        const contentDisposition = result.headers['content-disposition'];
        const fileName = contentDisposition.substring(
          contentDisposition.indexOf('filename=') + 9,
          contentDisposition.length
        );
        let blob = new Blob([result.data])
        blob = blob.slice(0, blob.size, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);  
        setIsDownloading(false);
      } catch {
        alert("Something went wrong. Please try again or contact support");
        setIsDownloading(false);
      }
    };
    fetchExcel();
  }

  return (
    <React.Fragment>
      {!user.is_portal_admin && !user.is_budget_viewer ? (
        <Navigate to="/dashboard" />
      ) : (
        <React.Fragment>
        {!isLoading ? (
          <React.Fragment>
            <div>
              <h4>Profit and Loss Summary: Budget Year {pnl_data['year']}</h4>

              {isDownloading ? (
                <p className="float-end mb-2 me-2">Downloading...
                  <Spinner size="sm" color="success">Downloading...</Spinner>
                </p>
              ) : (
                <Button onClick={downloadExel} title="Download Excel" className="float-end btn btn-primary btn-sm mb-2 me-2" color="primary" >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              )}
              <div className="clearfix"></div>

              {(Object.keys(pnl_data).length > 0) ? (
                <React.Fragment>
                  <Nav tabs>
                    <NavItem>
                      <NavLink active={currentActiveTab === "1"} onClick={() => { toggle("1"); }} role="button" >
                        Summary
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentActiveTab === "2"} onClick={() => { toggle("2"); }} role="button" > By Fund </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentActiveTab === "3"} onClick={() => { toggle("3"); }} role="button" > By Month </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <ProfitAndLossCombined pnl_data={pnl_data} formatter={formatter} pformatter={percent_formatter} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <ProfitAndLossFund pnl_data={pnl_data} formatter={formatter} pformatter={percent_formatter} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <ProfitAndLossMonth pnl_data={pnl_data} formatter={formatter} pformatter={percent_formatter} />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </React.Fragment>
              ) : (
                <p className="mt-3">
                  <em>No items have been added to this budget year.</em>
                </p>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="text-center mt-3">
            <Spinner className="mb-3" color="info">Preparing Profit & Loss by Expense...</Spinner>
            <p>Preparing Summary Profit & Loss, please wait ...</p>
          </div>
        )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ProfitAndLoss;
