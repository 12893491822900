import React from "react";
import {Row, Table, Spinner } from "reactstrap";
import 'assets/css/budgetsummary.css'

function IncomeList({incomes, total, formatter}) {

  return (
    <React.Fragment>
      <Row className="m-1">
      </Row>
      {(incomes) ? (
        <div className="tableFixHead">
        <Table id="expense-list" bordered size="sm">
          <thead className="bg-info">
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">PL Category (L1)</th>
              <th className="text-center">PL Category (L2)</th>
              <th className="text-center">PL Category (L3)</th>
              <th className="text-center">PL Category (L4)</th>
              <th className="text-center">Fund</th>
              <th className="text-center">Account</th>
              <th className="text-center">Cost Centre</th>
              <th className="text-center">Description</th>
              <th className="text-center">Amount</th>
            </tr>
          </thead>
          <tbody>
            {incomes.map((ic, i) => (
                <tr key={ic.id}>
                    <td>{i+1}</td>
                    <td>{ic.pl_cat_1}</td>
                    <td>{ic.pl_cat_2}</td>
                    <td>{ic.pl_cat_3}</td>
                    <td>{ic.pl_cat_4}</td>
                    <td>{ic.fund}</td>
                    <td>{ic.account}</td>
                    <td>{ic.department}</td>
                    <td>{ic.description}</td>
                    <td className="text-end">{formatter.format(ic.amount)}</td>
                </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
                <td colSpan={9} className="text-end"><strong>Total</strong></td>
                <td className="text-end"><strong>{formatter.format(total)}</strong></td>
            </tr>
          </tfoot>
        </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default IncomeList;