import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Table, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import "assets/css/sidebar.css";

function RenderSofRow({ sof, sofs, num_sof, setBudgetSofId, setBudgetSofAmount, removeSof }) {
    // alert('rendering an SOF row with sof_id = ' + sof.sof_id + " for sof_no: " + sof.sof_no);
    return (
        <React.Fragment>
            <Col sm={6}>
                {(sofs.length === 0) ? (
                    <p>Loading list of source of fund...</p>
                ) : (
                    <Select options={sofs} id={'sof_' + sof.sof_no} name={'sof_' + sof.sof_no} placeholder="select a source of fund"
                        value={sofs.filter(s => s.value === sof.sof_id)} onChange={(val) => setBudgetSofId(sof.sof_no, val.value)} />
                )}
            </Col>
            {(num_sof > 1) ? (
                <React.Fragment>
                    <Col sm={2}>
                        <InputGroup>
                            <InputGroupText>$</InputGroupText>
                            <Input id={'sof_' + sof.sof_no + '_amount'} name={'sof_' + sof.sof_no + '_amount'} placeholder="0" type="text"
                                value={(sof.sof_amount) ? Math.round(sof.sof_amount) : ''} onChange={(e) => setBudgetSofAmount(sof.sof_no, e.target.value)} />
                        </InputGroup>
                    </Col>
                    <Col sm={1} className="d-flex align-items-center">
                        <FontAwesomeIcon className="align-middle text-danger" icon="fa-solid fa-circle-minus" onClick={e => removeSof(sof.sof_no)} />
                    </Col>
                </React.Fragment>
            ) : ''}
        </React.Fragment>
    );
}


function BudgetEdit(props) {

    const navigate = useNavigate();

    let { budgetDeptId } = useParams();
    let { budgetId } = useParams();

    const [sofs, setSofs] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [error, setError] = useState('');
    const [num_sof, setNumSof] = useState(1);
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);
    const [budget_year, setBudgetYear] = useState(0);
    const [budget_year_value, setBudgetYearValue] = useState(0);
    const [budget_department, setBudgetDepartment] = useState(budgetDeptId);
    const [budget_department_name, setBudgetDepartmentName] = useState('');
    const [budget_account, setBudgetAccount] = useState(0);
    const [budget_description, setBudgetDescription] = useState('');
    const [budget_amount, setBudgetAmount] = useState(0);
    const [budget_sofs, setBudgetSofs] = useState([{ 'sof_no': 1, 'sof_id': 0, 'sof_amount': 0 }]);
    const [budget_months, setBudgetMonths] = useState([])

    const [isSaving, setIsSaving] = useState(false)

    const formatter = new Intl.NumberFormat('en-SG', {
        maximumFractionDigits: 0,
      });

    useEffect(() => {
        const BUDGET_DATA_URL = process.env.REACT_APP_API_URL + "/budget/" + budgetId + "/";
        const BUDGET_FORM_DATA_URL = process.env.REACT_APP_API_URL + "/budget/create_form/" + budgetDeptId + "/";
        const fetchData = async () => {
            try {
                // Get data to populate form
                const result = await api.get(BUDGET_FORM_DATA_URL);
                if (Object.keys(result.data).length == 0) {
                    navigate('/dashboard');
                }
                if (!user.is_portal_admin
                        && result.data.budget_department.status !== "PENDING SUBMISSION" 
                        && result.data.budget_department.status !== "REJECTED") {
                    navigate('/budget/summary/' + budgetDeptId);
                }
                setBudgetDepartmentName(result.data.department.abbr + ": " + result.data.department.name);
                setBudgetYear(result.data.year.id);
                if (result.data.year.label.length > 0)
                    setBudgetYearValue(result.data.year.year + ' — ' + result.data.year.label);
                else
                    setBudgetYearValue(result.data.year.year);
                const sofs = [];
                result.data.sofs.map((sof) => {
                    sofs.push({ value: sof.id, label: sof.code + ": " + sof.description });
                    return 0;
                });
                setSofs(sofs);
                const accounts = [];
                result.data.accounts.map((acc) => {
                    accounts.push({ value: acc.id, label: acc.account_number + ": " + acc.description });
                    return 0;
                });
                setAccounts(accounts);

                // Get this budget data
                const budget = await api.get(BUDGET_DATA_URL);
                setBudgetAccount(budget.data.account);
                setBudgetDescription(budget.data.description);
                setBudgetAmount(budget.data.amount);
                // Set budget sofs
                const budgetSof = [];
                let sof_no = 1;
                budget.data.sofs.forEach((sof) => {
                    budgetSof.push({ 'sof_no': sof_no++, 'sof_id': sof.sof.id, 'sof_amount': sof.amount })
                });
                setBudgetSofs(budgetSof);
                setNumSof(budgetSof.length);
                // Set budget month
                const budgetMonths = [];
                budget.data.months.forEach((m) => {
                    budgetMonths[m.month] = m.amount;
                });
                setBudgetMonths(budgetMonths);
            } catch {
                setError("Something went wrong");
            }
        };
        fetchData();
    }, []);

    function addSof() {
        setNumSof(num_sof + 1);
        const newBudgetSof = [...budget_sofs, { 'sof_no': num_sof + 1, 'sof_id': 0, 'sof_amount': 0 }];
        setBudgetSofs(newBudgetSof);
    }

    function removeSof(sof_no) {
        setNumSof(num_sof - 1);
        // alert('Inside removeSof --- Sof No: ' + sof_no);
        const newBudgetSof = budget_sofs.filter(sof => sof.sof_no !== sof_no);
        let i = 1;
        const reorderedBudgetSof = newBudgetSof.map(sof => {
            return { ...sof, sof_no: i++ };
        });
        setBudgetSofs(reorderedBudgetSof);

    }

    function setBudgetSofId(sof_no, sof_id) {
        // alert('Inside SeBudgetSofId --- Row No: ' + sof_no + '; Sof ID: ' + sof_id);
        const newBudgetSof = budget_sofs.map(sof => {
            if (sof.sof_no === sof_no) {
                return { ...sof, sof_id: sof_id };
            }
            return sof;
        });
        setBudgetSofs(newBudgetSof);
    }

    function setBudgetSofAmount(sof_no, sof_amount) {
        // alert('Inside setBudgetSofAmount --- Row No: ' + sof_no + '; Sof amount: ' + sof_amount);
        const newBudgetSof = budget_sofs.map(sof => {
            if (sof.sof_no === sof_no) {
                return { ...sof, sof_amount: sof_amount };
            }
            return sof;
        });
        setBudgetSofs(newBudgetSof);
    }

    function validate() {
        if (!budget_department) {
            alert("Please select a Department/CCA/Committee" + budget_department);
            return false;
        }
        if (!budget_account) {
            alert("Please select an account");
            return false;
        }
        if (budget_description.trim() === '') {
            alert("Please enter a description for this budget");
            return false;
        }
        if (!user.is_portal_admin && budget_amount <= 0) {
            alert("Please enter a positive budget amount");
            return false;
        }
        if (Math.floor(budget_amount) !== parseFloat(budget_amount)) {
            alert("Please enter only whole number for all amount");
            return false;
        }
        var whole_number_only = true;
        if (budget_sofs.length > 1) {
            let total_sofs = 0;
            var fund_chosen = true;
            var non_zero_fund_amount = true;
            budget_sofs.forEach((sof) => {
                if (sof.sof_id < 1) fund_chosen = false;
                if (sof.sof_amount === "" || parseFloat(sof.sof_amount) === 0) non_zero_fund_amount = false;
                if (Math.floor(sof.sof_amount) !== parseFloat(sof.sof_amount)) {
                    whole_number_only = false;
                } else {        
                    total_sofs += parseFloat(sof.sof_amount);
                }
            });
            if (!fund_chosen) {
                alert("Please choose the source of fund");
                return false;
            }
            if (!non_zero_fund_amount) {
                alert("Please enter a non-zero value for the source of fund, otherwise remove the unused source of fund");
                return false;
            }
            if (!whole_number_only) {
                alert("Please enter only whole number for all amount");
                return false;
            }
            if (total_sofs !== parseFloat(budget_amount)) {
                alert("Total amount from all sources of fund must be equal to the budget amount. Total SOF:" + total_sofs + " vs Bugdet Amount:" + budget_amount);
                return false;
            }    
        }
        let total_months = 0;
        whole_number_only = true;
        budget_months.forEach((amount, i) => {
            if (!isNaN(parseFloat(amount))) {
                if (Math.floor(amount) !== parseFloat(amount)) {
                    whole_number_only = false;
                } else {
                    total_months += parseFloat(amount);
                }
            }
        });
        if (!whole_number_only) {
            alert("Please enter only whole number for all amount");
            return false;
        }
        if (total_months !== parseFloat(budget_amount)) {
            alert("Total amount for month usage must be equal to the budget amount. " + total_months + " vs " + budget_amount);
            return false;
        }

        return true;
    }

    function handleSubmit(event) {
        setIsSaving(true);
        const PUT_URL = process.env.REACT_APP_API_URL + "/budget/" + budgetId + "/";
        if (validate()) {
            const b_sofs = budget_sofs.map((sof) =>
                ({ 'sof': sof.sof_id, 'amount': (num_sof > 1) ? sof.sof_amount : budget_amount })
            );
            const b_months = [];
            budget_months.slice(1).forEach((amount, i) => {
                if (amount && amount != 0) b_months.push({'month': i + 1, 'amount' : amount});
            });
            
            // alert (JSON.stringify(b_months));

            api.put(PUT_URL, {
                // 'csrfmiddlewaretoken': 'XDpd80GzwdwOiKgb6PKnpCaDjcijtlaHF3HlnhcYBPFJEHD2gHCROy8VKlPWdi3N',
                'id': budgetId,
                'description': budget_description,
                'account': budget_account,
                'amount': budget_amount,
                'year': budget_year,
                'department': budget_department,
                'owner': user.id,
                'sofs': b_sofs,
                'months': b_months
            })
                .then(function (response) {
                    alert(response.data.status);
                    navigate("/budget/summary/" + budgetDeptId);
                })
                .catch(function (error) {
                    alert(error.message);
                });
        } else {
            setIsSaving(false);
        }
        event.preventDefault();
    }

    function deleteBudgetItem() {
        const DELETE_URL = process.env.REACT_APP_API_URL + "/budget/" + budgetId + "/";
        api.delete(DELETE_URL)
        .then(function (response) {
            alert("Budget Item successfully deleted");
            navigate('/budget/summary/' + budgetDeptId)
        })
        .catch(function (error) {
            alert(error.message);
        });
    }

    function updateBudgetMonth(m, amount) {
        const updatedBudgetMonths = budget_months;
        if (amount === "") amount = 0;
        updatedBudgetMonths[m] = amount
        setBudgetMonths(updatedBudgetMonths);
        // alert(JSON.stringify(budget_months));

    }

    const sof_rows = budget_sofs.map((sof) => {
        // alert("Inside sof_rows --- sof no" + sof.sof_no);
        return (
            <div className="row mb-1" key={sof.sof_no}>
                <RenderSofRow sof={sof} sofs={sofs} num_sof={num_sof} setBudgetSofId={setBudgetSofId} setBudgetSofAmount={setBudgetSofAmount} removeSof={removeSof} />
            </div>
        );
    });

    return (
        <React.Fragment>
            <div className="m-2">
                <h4>Edit Budget &mdash; {budget_department_name} [Year {budget_year_value}]</h4>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <FormGroup row>
                        <Label for="department" sm={2}>
                            Account Code
                        </Label>
                        <Col sm={6}>
                            {(accounts.length === 0) ? (
                                <p>Loading list of account code...</p>
                            ) : (
                                <Select options={accounts} id="account" name="account" placeholder="select an account"
                                    value={accounts.filter(a => a.value === budget_account)[0]} onChange={(val) => setBudgetAccount(val.value)} />
                            )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="description" sm={2}>
                            Description
                        </Label>
                        <Col sm={10}>
                            <textarea className="form-control" name="description" id="description" rows={5} style={{fontSize: "small"}} value={budget_description ? budget_description : ''} onChange={(e) => setBudgetDescription(e.target.value)} />

                            {/* <Input id="description" name="description" placeholder="description" type="text" bsSize="sm"
                                value={budget_description ? budget_description : ''} onChange={(e) => setBudgetDescription(e.target.value)} /> */}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="amount" sm={2}>
                            Amount
                        </Label>
                        <Col sm={2}>
                            <InputGroup size="sm">
                                <InputGroupText>$</InputGroupText>
                                <Input id="amount" name="amount" placeholder="0" type="text" 
                                    value={budget_amount ? Math.round(budget_amount) : ''} onChange={(e) => setBudgetAmount(e.target.value)} />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <hr />
                    <FormGroup row>
                        <Label for="sof" sm={2}>
                            Source(s) of Fund
                        </Label>
                        <Col sm={10}>
                            {(num_sof > 1) ? (
                                <p className="bg-info p-1"><small><em>Please allocate the amount for each source of fund and ensure that the total amount from multiple sources of fund equals the amount of this budget.</em></small></p>
                            ) : ''}
                            {sof_rows}
                            <span className="badge Math.rounded-pill bg-secondary" onClick={addSof} role="button">Add another Source of Fund</span>
                        </Col>
                    </FormGroup>
                    <hr />
                    <FormGroup row>
                        <Label for="month" sm={2}>
                            Month of Usage
                        </Label>
                        <Col sm={10}>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Jan</th><th>Feb</th><th>Mar</th><th>Apr</th><th>May</th><th>Jun</th>
                                    </tr>
                                    <tr>
                                    {[...Array(6)].map((x, i) =>
                                        <td>
                                            <InputGroup key={i + 1} size="sm">
                                            <InputGroupText>$</InputGroupText>
                                            <Input id={'month_amount_' + (i+1)} name={'month_amount_' + (i+1)} placeholder="0" type="text" 
                                                onChange={(e) => updateBudgetMonth(i+1, e.target.value)} defaultValue={budget_months[i+1] ? Math.round(budget_months[i+1]) : ''}/>
                                           </InputGroup>
                                        </td>
                                    )}
                                    </tr>
                                    <tr>
                                       <th>Jul</th><th>Aug</th><th>Sep</th><th>Oct</th><th>Nov</th><th>Dec</th>
                                    </tr>
                                    <tr>
                                    {[...Array(6)].map((x, i) =>
                                        <td>
                                            <InputGroup key={(i + 7)} size="sm">
                                            <InputGroupText>$</InputGroupText>
                                            <Input id={'month_amount_' + (i + 7)} name={'month_amount_' + (i + 7)} placeholder="0" type="text" 
                                                onChange={(e) => updateBudgetMonth(i+7, e.target.value)}  defaultValue={budget_months[i+7] ? Math.round(budget_months[i+7]) : ''}/>
                                           </InputGroup>
                                        </td>
                                    )}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            {(isSaving) ? (
                                <span>
                                    <Spinner size="sm">Saving, please wait...</Spinner> <span>Saving, please wait...</span>
                                </span>
                            ) : (
                                <React.Fragment>
                                    <Button href={`/budget/budget/summary/${budgetDeptId}`} variant="secondary">
                                        Cancel
                                    </Button>
                                    {' '}
                                    <Button type="submit" id="Submit" name="Submit" color="primary">
                                        Update
                                    </Button>
                                    {' '}
                                    <Button type="button" id="delete" name="Delete" color="danger" onClick={deleteBudgetItem}>
                                        Delete
                                    </Button>
                                </React.Fragment>
                            )}
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default BudgetEdit;
