import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Spinner, Button, } from "reactstrap";
import "assets/css/sidebar.css";
import BudgetCategorySummaryCombined from "components/budget/BudgetCategorySummaryCombinedComponent";
import BudgetCategorySummaryFund from "components/budget/BudgetCategorySummaryFundComponent";
import BudgetCategorySummaryMonth from "components/budget/BudgetCategorySummaryMonthComponent";
import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function BudgetCategorySummaryComponent(props) {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  let { budgetDeptCatId } = useParams();
  let { budgetYearId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
 
  const [budgetCategory, setBudgetCategory] = useState("");
  const [budgetYear, setBudgetYear] = useState("");
  const [budgets, setBudgets] = useState([]);
  const [budgets_ex, setBudgetsEx] = useState([]);
  const [budgets_fa, setBudgetsFa] = useState([]);
  const [funds, setFunds] = useState([]);
  const [funds_ex, setFundsEx] = useState([]);
  const [funds_fa, setFundsFa] = useState([]);

  const [lyBudgets, setLYBudgets] = useState([]);
  const [lyBudgets_ex, setLYBudgetsEx] = useState([]);
  const [lyBudgets_fa, setLYBudgetsFa] = useState([]);

  const [error, setError] = useState("");

  const api = useAxios();

  const formatter = new Intl.NumberFormat("en-SG", {
    // style: 'currency',
    // currency: 'SGD',
    // currencySign: 'accounting',
    maximumFractionDigits: 0,
  });

  const pformatter = new Intl.NumberFormat("en-SG", {
    // style: 'currency',
    // currency: 'SGD',
    // currencySign: 'accounting',
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    const DATA_URL = process.env.REACT_APP_API_URL + "/budget/cat_summary/" + budgetYearId + "/" + budgetDeptCatId + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        setBudgetCategory(result.data.category);
        setBudgetYear(result.data.budget_year);
        setBudgets(sortByDepartments(result.data.summary.all));
        setBudgetsEx(sortByDepartments(result.data.summary.exp));
        setBudgetsFa(sortByDepartments(result.data.summary.fa));
        setFunds(extractUsedFunds(result.data.summary.all));
        setFundsEx(extractUsedFunds(result.data.summary.exp));
        setFundsFa(extractUsedFunds(result.data.summary.fa));
        if (result.data.ly_summary) {
          setLYBudgets(sortByDepartments(result.data.ly_summary.all));
          setLYBudgetsEx(sortByDepartments(result.data.ly_summary.exp));
          setLYBudgetsFa(sortByDepartments(result.data.ly_summary.fa));
        }
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  const extractUsedFunds = (depts) => {
    const usedFunds = [];
    const fundIds = [];
    depts.forEach((d) => {
      for (var sof_id in d.sofs) {
        if (d.sofs.hasOwnProperty(sof_id)) {
          if (!fundIds.includes(sof_id)) {
            fundIds.push(sof_id);
            usedFunds.push({
              id: sof_id,
              code: d.sofs[sof_id]["code"],
              description: d.sofs[sof_id]["description"],
            });
          }
        }
      }
    });
    return usedFunds.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        if (a.description > b.description) {
          return 1;
        } else if (a.description < b.description) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  };

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  function sortByDepartments(budgets) {
    return budgets.sort((a, b) => {
      // Sort by category name
      if (a.department_name > b.department_name) {
        return 1;
      } else if (a.department_name < b.department_name) {
        return -1;
      } else {
        return 0;
      }
    });
  }


  const downloadExel = () => {
    setIsDownloading(true);
    const EXCEL_DATA_URL = process.env.REACT_APP_API_URL + "/budget/cat_summary_excel/" + budgetYearId + "/" + budgetDeptCatId + "/";
    const fetchExcel = async () => {
      try {
        const result = await api.get(EXCEL_DATA_URL, {responseType: 'blob'});
        const contentDisposition = result.headers['content-disposition'];
        const fileName = contentDisposition.substring(
          contentDisposition.indexOf('filename=') + 9,
          contentDisposition.length
        );
        let blob = new Blob([result.data])
        blob = blob.slice(0, blob.size, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);  
        setIsDownloading(false);
      } catch {
        alert("Something went wrong. Please try again or contact support");
        setIsDownloading(false);
      }
    };
    fetchExcel();
  }


  return (
    <React.Fragment>
      {(!user.is_portal_admin && !user.is_school_leader && !user.is_budget_viewer) ? (
        <Navigate to="/dashboard" />
      ) : (
        <React.Fragment>
          {!isLoading ? (
            <React.Fragment>
              <div>
                <h4>
                  Budget Summary by Cost Centre Category &mdash;{" "}
                  {budgetCategory.name} {budgetYear.year} {(budgetYear.label.length > 0) ? (<span>&mdash; {budgetYear.label}</span>) : ''}
                </h4>

                {budgets.length > 0 ? (
                  <React.Fragment>

                    {(isDownloading) ? (
                      <p className="float-end mb-2 me-2">Downloading...
                        <Spinner size="sm" color="success">Downloading...</Spinner>
                      </p>
                      ) : (
                      <Button onClick={downloadExel} title="Download Excel" className="float-end btn btn-primary btn-sm mb-2 me-2" color="primary">
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    )}
                    <div className="clearfix"></div>

                    <Nav tabs>
                      <NavItem> <NavLink active={currentActiveTab === "1"} onClick={() => { toggle("1"); }} role="button" > Summary </NavLink> </NavItem>
                      <NavItem> <NavLink active={currentActiveTab === "2"} onClick={() => { toggle("2"); }} role="button" > By Fund </NavLink> </NavItem>
                      <NavItem> <NavLink active={currentActiveTab === "3"} onClick={() => { toggle("3"); }} role="button" > By Month </NavLink> </NavItem>
                    </Nav>
                    <TabContent activeTab={currentActiveTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <br />
                            <h5>Expenditure Items</h5>
                            <BudgetCategorySummaryCombined budgets={budgets_ex} funds={funds_ex} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets_ex} cat="exp" /> <hr />
                            <h5>Fixed Asset Items</h5>
                            <BudgetCategorySummaryCombined budgets={budgets_fa} funds={funds_fa} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets_fa} cat="capex" />
                            <h5>Total</h5>
                            <BudgetCategorySummaryCombined budgets={budgets} funds={funds} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets} cat="total" /> <hr />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <br />
                            <h5>Expenditure Items</h5>
                            <BudgetCategorySummaryFund budgets={budgets_ex} funds={funds_ex} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets_ex} cat="exp" /> <hr />
                            <h5>Fixed Asset Items</h5>
                            <BudgetCategorySummaryFund budgets={budgets_fa} funds={funds_fa} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets_fa} cat="capex" />
                            <h5>Total</h5>
                            <BudgetCategorySummaryFund budgets={budgets} funds={funds} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets} cat="total" /> <hr />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <br />
                            <h5>Expenditure Items</h5>
                            <BudgetCategorySummaryMonth budgets={budgets_ex} funds={funds_ex} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets_ex} cat="exp" /> <hr />
                            <h5>Fixed Asset Items</h5>
                            <BudgetCategorySummaryMonth budgets={budgets_fa} funds={funds_fa} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets_fa} cat="capex" />
                            <h5>Total</h5>
                            <BudgetCategorySummaryMonth budgets={budgets} funds={funds} formatter={formatter} pformatter={pformatter} year={budgetYear.year} lyBudgets={lyBudgets} cat="total" /> <hr />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </React.Fragment>
                ) : (
                  <p className="mt-3">
                    <em>No cost centre budgets have been added</em>
                  </p>
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className="text-center mt-3">
              <Spinner>Loading...</Spinner>
              <p>Loading...</p>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default BudgetCategorySummaryComponent;
