import React from "react";
import {Row, Table, Spinner } from "reactstrap";
import {Link} from 'react-router-dom';
import 'assets/css/budgetsummary.css'

function BudgetSummaryMonth({department, budgets, funds, formatter, pformatter, canEdit, year, lyBudgets}) {

  let total_amount = 0;
  let fund_totals = {};
  let month_totals = {};
  let sub_totals = {};
  let cat_totals = {}

  let ly_total_amount = 0;
  let ly_sub_totals = {};
  let ly_cat_totals = {};
  let allocated_totals = {
    'EXPENDITURE ITEMS': (department.allocated_expense) ? parseFloat(department.allocated_expense) : 0,
    'FIXED ASSET ITEMS': (department.allocated_capex) ? parseFloat(department.allocated_capex) : 0,
  };
  let allocated_grand_total = 0;
  
  budgets.forEach((acc_cat) => {
      allocated_grand_total += allocated_totals[acc_cat.name];
      acc_cat.accounts.forEach((account) => {
      const acc_no = account.account_number;
      const acc_cat = account.category.name;
      if (!(acc_no in sub_totals)) {
        sub_totals[acc_no] = {};
        sub_totals[acc_no]['total'] = 0;
      }
      if (!(acc_cat in cat_totals)) {
        cat_totals[acc_cat] = {};
        cat_totals[acc_cat]['total'] = 0;
      }
      account.budgets.forEach((budget) => {
        total_amount += parseFloat(budget.amount);
        sub_totals[acc_no]['total'] += parseFloat(budget.amount);
        cat_totals[acc_cat]['total'] += parseFloat(budget.amount);

        budget.sofs.forEach((s) => {
          if (fund_totals[s.sof.code + '@' + s.sof.description])
            fund_totals[s.sof.code + '@' + s.sof.description] += parseFloat(s.amount);
          else
            fund_totals[s.sof.code + '@' + s.sof.description] = parseFloat(s.amount);

          if (!(acc_no in fund_totals)) {
            fund_totals[acc_no] = {};
          }
          if (fund_totals[acc_no][s.sof.code + '@' + s.sof.description])
            fund_totals[acc_no][s.sof.code + '@' + s.sof.description] += parseFloat(s.amount);
          else
            fund_totals[acc_no][s.sof.code + '@' + s.sof.description] = parseFloat(s.amount);

          if (!(acc_cat in fund_totals)) {
              fund_totals[acc_cat] = {};
            }
            if (fund_totals[acc_cat][s.sof.code + '@' + s.sof.description])
              fund_totals[acc_cat][s.sof.code + '@' + s.sof.description] += parseFloat(s.amount);
            else
              fund_totals[acc_cat][s.sof.code + '@' + s.sof.description] = parseFloat(s.amount);

        });

        budget.months.forEach((m) => {
          if (month_totals[m.month])
            month_totals[m.month] += parseFloat(m.amount);
          else
            month_totals[m.month] = parseFloat(m.amount);

          if (!(acc_no in month_totals)) {
            month_totals[acc_no] = {};
          }
          if (month_totals[acc_no][m.month])
            month_totals[acc_no][m.month] += parseFloat(m.amount);
          else
            month_totals[acc_no][m.month] = parseFloat(m.amount);

          if (!(acc_cat in month_totals)) {
              month_totals[acc_cat] = {};
            }
            if (month_totals[acc_cat][m.month])
              month_totals[acc_cat][m.month] += parseFloat(m.amount);
            else
              month_totals[acc_cat][m.month] = parseFloat(m.amount);
          
        });

      });
      });
  });

  lyBudgets.forEach((acc_cat) => {
    acc_cat.accounts.forEach((account) => {
      const acc_no = account.account_number;
      const acc_cat = account.category.name;
      if (acc_no in sub_totals) {
        if (!(acc_no in ly_sub_totals)) {
          ly_sub_totals[acc_no] = {};
          ly_sub_totals[acc_no]["total"] = 0;
        }
      }
      if (acc_cat in cat_totals) {
        if (!(acc_cat in ly_cat_totals)) {
          ly_cat_totals[acc_cat] = {};
          ly_cat_totals[acc_cat]["total"] = 0;
        }
      }
      account.budgets.forEach((budget) => {
        if (acc_no in sub_totals) {
          ly_total_amount += parseFloat(budget.amount);
          ly_sub_totals[acc_no]["total"] += parseFloat(budget.amount);
          ly_cat_totals[acc_cat]["total"] += parseFloat(budget.amount);
        }

      });
    });
  });

  return (
    <React.Fragment>
      <Row className="m-1">
      </Row>
      <hr />
      {(department) ? (
        <div className="tableFixHead">
        <Table id="budget-summary-combined" bordered size="sm">
          <thead className="bg-info">
            <tr>
              <th rowSpan={2} className="text-center"> </th>
              <th rowSpan={2} className="text-center"> Account &amp; Description </th>
              {/* <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{year} </th> */}
              <th colSpan={12} className="text-center budget-summary-month">Month</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{year}</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{year-1}<br/></th>
              <th rowSpan={2} className="text-center"> Difference</th>
              <th rowSpan={2} className="text-center"> Difference (%)</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Allocated<br />Budget Year<br/>{year}</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Difference</th>
              <th rowSpan={2} className="text-center budget-summary-allocated"> Difference (%)</th>            </tr>
            <tr> 
              {Array.from({length:12},(v,k)=>k+1).map((m) => (
                <th key={`head${m}`} className="text-center budget-summary-month" style={{ maxWidth: 80 + 'px' }}>{m}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(budgets.length > 0) ? (
              <React.Fragment>
                {budgets.map((acc_cat, a) => (
                  <React.Fragment key={acc_cat.id}>
                    <tr>
                      <td className="budget-summary-acc-cat" colSpan={21}><strong>{acc_cat.name}</strong></td>
                    </tr>
                  {acc_cat.accounts.map((account, j) => (
                    <React.Fragment key={account.id}>
                      <tr>
                        <td colSpan={21}><strong>{account.account_number} {account.description}</strong></td>
                      </tr>

                      {account.budgets.map((budget, i) => (
                        <tr key={budget.id}>

                          <td>{budget.budget_no}</td>
                          <td>
                            {(canEdit) ? (
                              <Link className="text-decoration-none" to={`/budget/edit/${department.id}/${budget.id}`} style={{whiteSpace: "pre-wrap"}}>{budget.description}</Link>
                            ) : (<React.Fragment>{budget.description}</React.Fragment>)
                            }
                          </td>
                          {/* {(budget.copied) ? (
                            <td className="text-end text-danger">{formatter.format(budget.amount)}</td>
                          ) : (
                            <td className="text-end">{formatter.format(budget.amount)}</td>
                          )} */}

                          {/* Months Columns */}
                          {Array.from({length:12},(v,k)=>k+1).map((m) => (
                            <td key={budget.id + '_' + m} className="text-end budget-summary-month">
                              {(budget.months.filter((bm) => bm.month === m).length > 0) ? formatter.format(budget.months.filter((bm) => bm.month === m)[0].amount) : '-'}
                            </td>
                          ))}

                          {(budget.copied) ? (
                            <td className="text-end copied-value">{formatter.format(budget.amount)}</td>
                          ) : (
                            <td className="text-end">{formatter.format(budget.amount)}</td>
                          )}
                          <td colSpan={3}></td> 
                        </tr>

                      ))}

                      <tr>
                        <td colSpan={2} className="text-end"><em>Sub-Total for {account.number} {account.description}</em></td>
                        {/* <td className="text-end"><strong><em>{formatter.format(sub_totals[account.account_number]['total'])}</em></strong></td> */}
                        {Array.from({length:12},(v,k)=>k+1).map((m) => (
                          <td key={account.id + '_' + m} className="text-end budget-summary-month" style={{ maxWidth: 80 + 'px' }}>
                            <strong>
                            {(month_totals[account.account_number]) ? (
                             (month_totals[account.account_number][m]) ? formatter.format(month_totals[account.account_number][m]) : '-'
                            ) : '-'}
                            </strong>
                          </td>
                        ))}
                        <td className="text-end">
                          <strong><em>
                          {(account.budgets.length > 0) ? 
                          formatter.format(sub_totals[account.account_number]['total'])
                           : '-'}
                          </em></strong>
                        </td>
                        <td className="text-end">
                          <strong><em>
                            {(ly_sub_totals[account.account_number]) ? formatter.format(ly_sub_totals[account.account_number]['total']) : "-"}
                          </em></strong>
                        </td>
                        <td className="text-end">
                          <strong><em>
                            {(ly_sub_totals[account.account_number]) ? formatter.format(sub_totals[account.account_number]['total']-ly_sub_totals[account.account_number]['total']) : "-"}
                          </em></strong>
                        </td>
                        <td className="text-end">
                          <strong><em>
                            {(ly_sub_totals[account.account_number]) ? pformatter.format((sub_totals[account.account_number]['total']-ly_sub_totals[account.account_number]['total'])*100/ly_sub_totals[account.account_number]['total']) + "%" : "-"}
                          </em></strong>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={21}>&nbsp;</td>
                      </tr>

                    </React.Fragment>
                  ))}
                    <tr className="budget-summary-subtotal">
                      <td colSpan={2} className="text-end budget-summary-acc-cat"><em><strong>Total for {acc_cat.name}</strong></em></td>
                      {/* <td className="text-end budget-summary-acc-cat"><strong><em>{formatter.format(cat_totals[acc_cat.name]['total'])}</em></strong></td> */}
                      {Array.from({length:12},(v,k)=>k+1).map((m) => (
                        <td key={acc_cat.id + '_' + m} className="text-end budget-summary-month" style={{ maxWidth: 80 + 'px' }}>
                          {(month_totals[acc_cat.name]) ? (
                          <strong>{ (month_totals[acc_cat.name][m]) ? formatter.format(month_totals[acc_cat.name][m]) : '-'}</strong>
                          ) : '-'}
                        </td>
                      ))}
                      <td className="text-end budget-summary-acc-cat">
                        <strong><em>
                        {(cat_totals[acc_cat.name]['total'] !== 0) ? (
                        formatter.format(cat_totals[acc_cat.name]['total'])
                        ) : '-'}
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-acc-cat">
                        <strong><em>
                        {(ly_cat_totals[acc_cat.name]) ? formatter.format(ly_cat_totals[acc_cat.name]['total']) : "-"}
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-acc-cat">
                        <strong><em>
                          {(ly_cat_totals[acc_cat.name] && cat_totals[acc_cat.name]['total'] > 0) ? formatter.format(cat_totals[acc_cat.name]['total']-ly_cat_totals[acc_cat.name]['total']) : "-"}
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-acc-cat">
                        <strong><em>
                          {(ly_cat_totals[acc_cat.name] && cat_totals[acc_cat.name]['total'] > 0) ? pformatter.format((cat_totals[acc_cat.name]['total']-ly_cat_totals[acc_cat.name]['total'])*100/ly_cat_totals[acc_cat.name]['total']) + "%" : "-"}
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-allocated">
                        <strong><em>
                          {(allocated_totals[acc_cat.name] > 0) ? formatter.format(allocated_totals[acc_cat.name]) : "-"}
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-allocated">
                        <strong><em>
                          {(cat_totals[acc_cat.name]['total'] !== 0) ? (
                          formatter.format(cat_totals[acc_cat.name]['total'] - allocated_totals[acc_cat.name])
                          ) : '-'}
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-allocated">
                        <strong><em>
                          {(cat_totals[acc_cat.name]['total'] !== 0 && allocated_totals[acc_cat.name] !== 0) ? (
                          pformatter.format((cat_totals[acc_cat.name]['total'] - allocated_totals[acc_cat.name])*100/allocated_totals[acc_cat.name]) + "%"
                          ) : '-'}
                        </em></strong>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={18}>&nbsp;</td>
                    </tr>

                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : ''}
          </tbody>
          <tfoot>
            <tr className="budget-summary-grand-total">
                <td colSpan={2} className="text-end"><strong>GRAND TOTAL:</strong></td>
                {/* <td className="text-end"><strong>{formatter.format(total_amount)}</strong></td> */}
                {Array.from({length:12},(v,k)=>k+1).map((m) => (
                  <td key={m + '_grand_total'} className="text-end budget-summary-month" style={{ maxWidth: 80 + 'px' }}>
                    <strong>{ (month_totals[m]) ? formatter.format(month_totals[m]) : '-'}</strong>
                  </td>
                ))}
                <td className="text-end"><strong>{formatter.format(total_amount)}</strong></td>
                <td className="text-end">
                  <strong>
                    {(ly_total_amount > 0) ? formatter.format(ly_total_amount) : "-"}
                  </strong>
                </td>
                <td className="text-end">
                  <strong>
                    {(ly_total_amount > 0 && total_amount > 0) ? formatter.format(total_amount - ly_total_amount) : "-"}
                  </strong>
                </td>
                <td className="text-end">
                  <strong>
                    {(ly_total_amount > 0 && total_amount > 0) ? pformatter.format((total_amount - ly_total_amount)*100/ly_total_amount) + "%" : " - "}
                  </strong>
                </td>
                <td className="text-end budget-summary-allocated">
                  <strong><em>
                    {(allocated_grand_total > 0) ? formatter.format(allocated_grand_total) : "-"}
                  </em></strong>
                </td>
                <td className="text-end budget-summary-allocated">
                  <strong><em>
                    {(total_amount !== 0) ? (
                    formatter.format(total_amount - allocated_grand_total)
                    ) : '-'}
                  </em></strong>
                </td>
                <td className="text-end budget-summary-allocated">
                  <strong><em>
                    {(total_amount !== 0 && allocated_grand_total !== 0) ? (
                    pformatter.format((total_amount - allocated_grand_total)*100/allocated_grand_total) + "%"
                    ) : '-'}
                  </em></strong>
                </td>
              </tr>
          </tfoot>
        </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default BudgetSummaryMonth;