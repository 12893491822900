import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button } from "reactstrap";
import Select from 'react-select'
import "assets/css/sidebar.css";


function DepartmentAdd(props) {

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);
    const [department_name, setDepartmentName] = useState(0);
    const [department_abbr, setDepartmentAbbr] = useState(0);
    const [department_category, setDepartmentCategory] = useState(0);
    const [department_pl_category, setDepartmentPLCategory] = useState(null);
    
    const [categories, setCategories] = useState([]);
    const [pl_categories, setPLCategories] = useState([]);

    useEffect(() => {
        const DATA_URL = process.env.REACT_APP_API_URL + "/department/create_form/";
        const fetchData = async () => {
            try {
                const result = await api.get(DATA_URL);
                if (Object.keys(result.data).length == 0) {
                    navigate('/dashboard');
                }
                const cats = [];
                result.data.categories.map((c) => {
                    cats.push({ value: c.id, label: c.name });
                    return 0;
                });
                setCategories(cats);
                const pl_cats = [{ value: null, label: 'None'}];
                result.data.pl_categories.map((plc) => {
                    pl_cats.push({ value: plc.id, label: plc.name });
                    return 0;
                });
                setPLCategories(pl_cats);
            } catch {
                setError("Something went wrong");
            }
        };
        fetchData();
    }, []);

    function validate() {
        if (!department_name) {
            alert("Please enter cost centre's name");
            return false;
        }
        if (!department_abbr) {
            alert("Please enter cost centre's abbreviation");
            return false;
        }
        if (!department_category) {
            alert("Please select a category for the cost centre");
            return false;
        }
        return true;
    }

    function handleSubmit(event) {
        const POST_URL = process.env.REACT_APP_API_URL + "/department/";
        if (validate()) {
            api.post(POST_URL, {
                // 'csrfmiddlewaretoken': 'XDpd80GzwdwOiKgb6PKnpCaDjcijtlaHF3HlnhcYBPFJEHD2gHCROy8VKlPWdi3N',
                'name': department_name,
                'abbr': department_abbr,
                'category': department_category,
                'pl_category': department_pl_category,
            })
                .then(function (response) {
                    if (response.status == 201) {
                        alert("Cost Centre created successfully");
                        navigate("/departments");
                    } else {
                        alert("Something went wrong. Please try again or contact support");
                    }
                })
                .catch(function (error) {
                    alert(error.message);
                });
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="m-2">
                <h4>Add a new cost centre</h4>
                <hr />
                <Form onSubmit={handleSubmit} class="d-flex flex-column flex-grow-1">
                    <FormGroup row>
                        <Label for="name" sm={2}>
                            Name
                        </Label>
                        <Col sm={10}>
                            <Input id="name" name="name" placeholder="name" type="text" bsSize="sm"
                                value={department_name ? department_name : ''} onChange={(e) => setDepartmentName(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="abbr" sm={2}>
                            Abbreviation
                        </Label>
                        <Col sm={4}>
                            <Input id="abbr" name="abbr" placeholder="abbreviation" type="text" bsSize="sm"
                                value={department_abbr ? department_abbr : ''} onChange={(e) => setDepartmentAbbr(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="category" sm={2}>
                            Category
                        </Label>
                        <Col sm={6}>
                            {(categories.length === 0) ? (
                                <p>Loading list of categories...</p>
                            ) : (
                                <Select options={categories} id="category" name="category" placeholder="select a category"
                                    value={categories.filter(a => a.value === department_category)[0]} onChange={(val) => setDepartmentCategory(val.value)} />
                            )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="pl_category" sm={2}>
                            P&L Category (level 4)
                        </Label>
                        <Col sm={6}>
                            {(categories.length === 0) ? (
                                <p>Loading list of P&L categories...</p>
                            ) : (
                                <Select options={pl_categories} id="pl_category" name="pl_category" placeholder="select a P&L category"
                                    value={pl_categories.filter(a => a.value === department_pl_category)[0]} onChange={(val) => setDepartmentPLCategory(val.value)} />
                            )}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Save
                            </Button>
                            {' '}
                            <Button href={`/budget/departments/`} variant="secondary">
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default DepartmentAdd;
