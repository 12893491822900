import React from "react";
import { Navigate, Link } from "react-router-dom";
import {Button, List } from "reactstrap";
import "assets/css/sidebar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function PLCategorySubList({pl_categories, pl_type, cat_name, add_child}) {

    return (
    <React.Fragment>
        <div>
            <h4 className="pl_cat_title">P&L {cat_name} Categories</h4>

            <List className="pl_cat_level_header">
                <li>
                    <div className="pl_cat_header">
                        Level 1 
                        <Link className="ms-2 badge rounded-pill bg-secondary" title="Add Level 1 P&L Category" to={`/pl_category/add/1/${pl_type}/`}>add</Link>
                    </div>
                    
                    <List className="pl_cat_level">
                        <li>
                            <div className="pl_cat_header">
                                Level 2
                                <Link className="ms-2 badge rounded-pill bg-secondary" title="Add Level 2 P&L Category" to={`/pl_category/add/2/${pl_type}/`}>add</Link>
                            </div>
                            <List className="pl_cat_level">
                                <li>
                                <div className="pl_cat_header">
                                    Level 3
                                    <Link className="ms-2 badge rounded-pill bg-secondary" title="Add Level 3 P&L Category" to={`/pl_category/add/3/${pl_type}/`}>add</Link>
                                </div>
                                <List className="pl_cat_level">
                                    <li>
                                        <div className="pl_cat_header">
                                            Level 4
                                            <Link className="ms-2 badge rounded-pill bg-secondary" title="Add Level 4 P&L Category" to={`/pl_category/add/4/${pl_type}/`}>add</Link>
                                        </div>
                                    </li>
                                </List>
                                </li>
                            </List>
                        </li>
                    </List>
                </li>
            </List>
            
            <br/>

            {pl_categories.length > 0 ? (
            <List className="pl_cat_level">
            {pl_categories.map((plc) => (
                <li key={plc.id} className="l1">
                    <div className="pl_cat_label">
                    <Link className="text-decoration-none" title="Edit P&L Category" to={`/pl_category/edit/${plc.id}`}>
                        {plc.name}
                    </Link> <span className="text-muted"><small>[{plc.sort_order}]</small></span>
                    </div>
                    {plc.children.length > 0 ? (
                        <List className="pl_cat_level">
                            {plc.children.map((plc_2) => (
                                <li key={plc_2.id}>
                                    <div className="pl_cat_label">
                                        <Link className="text-decoration-none" title="Edit P&L Category" to={`/pl_category/edit/${plc_2.id}`}>
                                            {plc_2.name}
                                        </Link> <span className="text-muted"><small>[{plc_2.sort_order}]</small></span>
                                    </div>
                                    {plc_2.children.length > 0 ? (
                                        <List className="pl_cat_level">
                                            {plc_2.children.map((plc_3) => (
                                                <li key={plc_3.id}>
                                                    <div className="pl_cat_label">
                                                        <Link className="text-decoration-none" title="Edit P&L Category" to={`/pl_category/edit/${plc_3.id}`}>
                                                            {plc_3.name}
                                                        </Link> <span className="text-muted"><small>[{plc_3.sort_order}]</small></span>
                                                    </div>
                                                    {plc_3.children.length > 0 ? (
                                                    <List className="pl_cat_level">
                                                        {plc_3.children.map((plc_4) => (
                                                            <li key={plc_4.id}>
                                                                <div className="pl_cat_label pl_cat_label_l4">
                                                                    <Link className="text-decoration-none" title="Edit P&L Category" to={`/pl_category/edit/${plc_4.id}`}>
                                                                        {plc_4.name}
                                                                    </Link> <span className="text-muted"><small>[{plc_4.sort_order}]</small></span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </List>
                                                ) : ''}
                                                </li>
                                            ))}
                                        </List>
                                    ) : ''}
                                </li>
                            ))}
                        </List>
                    ) : ''}
                </li>
            ))}
            </List>
            ) : (
            <div>No P&L Category has been created.</div>
            )}
        </div>
    </React.Fragment>
  );
}

export default PLCategorySubList;
