import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import useAxios from "utils/useAxios";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row, Spinner, Button } from "reactstrap";

import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ExpenseList from "components/report/ExpenseListComponent";
import IncomeList from "components/report/IncomeListComponent";

function ItemList(props) {

  const { user } = useContext(AuthContext);

  let { budgetYearId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const [budgetYear, setBudgetYear] = useState("");
  const [incomes, setIncomes] = useState();
  const [expenses, setExpenses] = useState();
  const [income_total, setIncomeTotal] = useState(0);
  const [expense_total, setExpenseTotal] = useState(0);
  const [funds, setFunds] = useState([]);
  const [fundIds, setFundIds] = useState([]);
  const [fundTotals, setFundTotals] = useState([]);


  const [error, setError] = useState("");

  const api = useAxios();

  const formatter = {
    numberFormatter: new Intl.NumberFormat("en-SG", {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: "code",
      currencySign: "accounting",
      maximumFractionDigits: 0,
    }),
    format: function(number) {
      const nDisplay = this.numberFormatter.formatToParts(number)
      .filter(x => x.type !== "currency")
      .map(x => x.value.trim())
      .join("")
      .trim();
      if (number < 0)
        return (<span style={{color: "red"}}>{nDisplay}</span>);
      else
      return nDisplay;
    }
  };

  useEffect(() => {
    const DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/item_list/" + budgetYearId + "/";
    const fetchData = async () => {
      try {
        const result = await api.get(DATA_URL);
        setBudgetYear(result.data.budget_year);
        setIncomes(result.data.incomes);
        setExpenses(result.data.expenses);
        setFunds(extractUsedFunds(result.data.expenses));
        setIncomeTotal(result.data.income_total);
        setExpenseTotal(result.data.expense_total);
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const downloadExel = () => {
    setIsDownloading(true);
    const EXCEL_DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/item_list_excel/" + budgetYearId + "/";
    const fetchExcel = async () => {
      try {
        const result = await api.get(EXCEL_DATA_URL, {responseType: 'blob'});
        const contentDisposition = result.headers['content-disposition'];
        const fileName = contentDisposition.substring(
          contentDisposition.indexOf('filename=') + 9,
          contentDisposition.length
        );
        let blob = new Blob([result.data])
        blob = blob.slice(0, blob.size, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);  
        setIsDownloading(false);
      } catch {
        alert("Something went wrong. Please try again or contact support");
        setIsDownloading(false);
      }
    };
    fetchExcel();
  }

  const extractUsedFunds = (expenses) => {
    const usedFunds = [];
    const fundIds = [];
    const fundTotals = {};
    expenses.forEach((d) => {
      for (var si in d.sofs) {
        var sof_id = d.sofs[si]["sof"]["id"];
        if (!fundIds.includes(sof_id)) {
          fundIds.push(sof_id);
          usedFunds.push({
            id: sof_id,
            code: d.sofs[si]["sof"]["code"],
            description: d.sofs[si]["sof"]["description"],
          });
          fundTotals[sof_id] = 0;
        }
        fundTotals[sof_id] += parseFloat(d.sofs[si]["amount"]);
      }
    });
    setFundIds(fundIds);
    setFundTotals(fundTotals);
    return usedFunds.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        if (a.description > b.description) {
          return 1;
        } else if (a.description < b.description) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  };

  return (
    <React.Fragment>
      {(!user.is_portal_admin && !user.is_budget_viewer && !user.is_school_leader) ? (
        <Navigate to="/dashboard" />
      ) : (
        <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          <div>
            <h4>
              Income & Expense List &mdash;{" "} Budget Year {budgetYear.year} {(budgetYear.label.length > 0) ? (<span>&mdash; {budgetYear.label}</span>) : ''}
            </h4>

            {isDownloading ? (
              <p className="float-end mb-2 me-2">Downloading...
                <Spinner size="sm" color="success">Downloading...</Spinner>
              </p>
            ) : (
              <Button onClick={downloadExel} title="Download Excel" className="float-end btn btn-primary btn-sm mb-2 me-2" color="primary" >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            )}
            <div className="clearfix"></div>

              <React.Fragment>
                <Nav tabs>
                  <NavItem> <NavLink active={currentActiveTab === "1"} onClick={() => { toggle("1"); }} role="button" > Income </NavLink> </NavItem>
                  <NavItem> <NavLink active={currentActiveTab === "2"} onClick={() => { toggle("2"); }} role="button" > Expense </NavLink> </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <br />
                        <IncomeList incomes={incomes} total={income_total} formatter={formatter} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <br />
                        <ExpenseList expenses={expenses} total={expense_total} formatter={formatter} funds={funds} fundIds={fundIds} fundTotals={fundTotals} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </React.Fragment>
          </div>
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
      </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ItemList;
