import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Form, FormGroup, Label, Col, Input, Button, Spinner, } from "reactstrap";

function ResetPassword(props) {
  const navigate = useNavigate();

  let { token } = useParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [requirement, setRequirement] = useState([]);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const RESET_PASSWORD_URL =
      process.env.REACT_APP_API_URL + "/account/reset_password/" + token + "/";
    const fetchData = async () => {
      try {
        const result = await fetch(RESET_PASSWORD_URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await result.json();
        setIsTokenValid(data.message === "Token valid");
        setRequirement(data.requirement);
        setIsLoading(false);
      } catch {
        setError("Something went wrong");
      }
    };
    fetchData();
  }, []);

  function validate() {
    if (!password) {
      alert("Please enter your password");
      return false;
    }
    if (password !== password2) {
      alert("Password doesn't match");
      return false;
    }
    return true;
  }

  const reset_password = async (token, password) => {
    const result = await fetch(
      process.env.REACT_APP_API_URL + "/account/reset_password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          password,
        }),
      }
    );
    const data = await result.json();
    if (data.error) {
      setError(data.error)
    } else {
      alert("Password has been successfully changed");
      navigate('/');
    }
  };

  async function handleSubmit(event) {
    setIsSaving(true);
    if (validate()) {
      reset_password(token, password);
    }
    setIsSaving(false);
    event.preventDefault();
  }

  return (
    <React.Fragment>
      {!isLoading ? (
        <React.Fragment>
          <div className="m-2">
            <h4>Reset Password</h4>
            <hr />
            {isTokenValid ? (
            <Form onSubmit={handleSubmit}>
              {requirement ? (
                <FormGroup row>
                  <Col md={{ size: 10, offset: 2 }}>
                  <h5>Password requirements</h5>
                  <ul>
                    {requirement.map((r, i) => (
                      <li key={`req_${i}`}>{r}</li>
                    ))}
                  </ul>
                  </Col>
                </FormGroup>
              ) : ''}
              {error ? (
                <FormGroup row>
                  <Col md={{ size: 10, offset: 2 }} className="text-danger">
                  <h6>Validation errors</h6>
                  <ul>
                    {error.map((e, ei) => (
                      <li key={`err_${ei}`}>{e}</li>
                    ))}
                  </ul>
                  </Col>
                </FormGroup>
              ) : ''}
              <FormGroup row>
                <Label for="password" sm={2}>
                  Enter a new password
                </Label>
                <Col sm={4}>
                  <Input id="password" name="password" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />{" "} </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="password" sm={2}>
                  Enter the new password again
                </Label>
                <Col sm={4}>
                  <Input id="password2" name="password2" placeholder="Verify password" type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />{" "} </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={{ size: 10, offset: 2 }}>
                  {isSaving ? (
                    <span> <Spinner size="sm"> Resetting password, please wait... </Spinner>{" "} <span>Resetting password, please wait...</span> </span> ) : (
                    <Button type="submit" id="Submit" name="Submit" color="primary" > Submit </Button> )}
                </Col>
              </FormGroup>
            </Form>
          ) : (
            <p>Invalid token</p>
          )}
          </div>
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default ResetPassword;
