import React from "react";
import {Row, Table, Spinner } from "reactstrap";
import {Link} from 'react-router-dom';
import 'assets/css/budgetsummary.css'

function IncomeSummaryMonth({user, incomes, funds, formatter, pformatter, budget_year, ly_incomes}) {

  let total_amount = 0;
  let fund_totals = {};
  let month_totals = {};
  let cat_totals = {}

  let ly_total_amount = 0;
  let ly_cat_totals = {}

  Object.keys(incomes).forEach((cat) => {
    incomes[cat].forEach((i) => {
      if (!(cat in cat_totals)) {
        cat_totals[cat] = 0;
      }
      cat_totals[cat] += parseFloat(i.amount);

      i.income_funds.forEach((ifund) => {
        if (!(ifund.fund.id in fund_totals)) {
          fund_totals[ifund.fund.id] = 0;
        }
        fund_totals[ifund.fund.id] += parseFloat(ifund.amount);

        if (!(cat in fund_totals)) {
          fund_totals[cat] = {}
        }
        if (fund_totals[cat][ifund.fund.id])
          fund_totals[cat][ifund.fund.id] += parseFloat(ifund.amount);
        else
          fund_totals[cat][ifund.fund.id] = parseFloat(ifund.amount);
      })

      i.income_months.forEach((imonth) => {
        if (!(imonth.month in month_totals)) {
          month_totals[imonth.month] = 0;
        }
        month_totals[imonth.month] += parseFloat(imonth.amount);
        if (!(cat in month_totals)) {
          month_totals[cat] = {}
        }
        if (month_totals[cat][imonth.month])
          month_totals[cat][imonth.month] += parseFloat(imonth.amount);
        else
          month_totals[cat][imonth.month] = parseFloat(imonth.amount);

      })
      total_amount += parseFloat(i.amount);
    });
  });

  Object.keys(ly_incomes).forEach((cat) => {
    ly_incomes[cat].forEach((i) => {
      if (!(cat in ly_cat_totals)) {
        ly_cat_totals[cat] = 0;
      }
      ly_cat_totals[cat] += parseFloat(i.amount);

      ly_total_amount += parseFloat(i.amount);
    });
  });

  return (
    <React.Fragment>
      <Row className="m-1">
      </Row>
      <hr />
      {(incomes) ? (
        <div className="tableFixHead">
        <Table id="budget-summary-combined" bordered size="sm">
          <thead className="bg-info">
            <tr>
              <th rowSpan={2} className="text-center"> </th>
              <th rowSpan={2} className="text-center">Account</th>
              <th rowSpan={2} className="text-center">Cost Centre</th>
              <th rowSpan={2} className="text-center">Description</th>
              <th colSpan={12} className="text-center budget-summary-month">Month</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{budget_year.year}</th>
              <th rowSpan={2} className="text-center"> Total<br/>Budget Year<br/>{budget_year.year-1}<br/></th>
              <th rowSpan={2} className="text-center"> Difference</th>
              <th rowSpan={2} className="text-center"> Difference (%)</th>
            </tr>
            <tr> 
              {Array.from({length:12},(v,k)=>k+1).map((m) => (
                <th key={`head${m}`} className="text-center budget-summary-month" style={{ maxWidth: 80 + 'px' }}>{m}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(incomes.length > 0 || true) ? (
              <React.Fragment>
                {Object.keys(incomes).map((cat, index) => (
                   (ly_cat_totals[cat] || cat_totals[cat]) ? (
                    <React.Fragment key={cat}>
                    <tr>
                      <td className="budget-summary-acc-cat" colSpan={20}><strong>{cat}</strong></td>
                    </tr>
                    {incomes[cat].map((income, j) => (
                      <React.Fragment key={income.id}>
                        <tr key={income.id}>

                          <td>{income.income_no}</td>
                          <td><span title={income.account.account_number + ' ' + income.account.description}>{income.account.account_number}</span></td>
                          <td>
                            {(income.department.abbr === '') ? (
                              <span title="GEN">GEN</span>
                            ) : (
                            <span title={income.department.abbr + ': ' + income.department.name}>{income.department.abbr}</span>
                            )}
                          </td>
                          <td>
                            {user.is_portal_admin ? (
                              <Link className="text-decoration-none" to={`/income/edit/${budget_year.id}/${income.id}`} style={{whiteSpace: "pre-wrap"}}>{income.description}</Link>
                            ) : (
                              <span style={{whiteSpace: "pre-wrap"}}>{income.description}</span>
                            )}
                          </td>

                          {/* Months Columns */}
                          {Array.from({length:12},(v,k)=>k+1).map((m) => (
                            <td key={income.id + '_' + m} className="text-end budget-summary-month">
                              {(income.income_months.filter((im) => im.month === m).length > 0) ? formatter.format(income.income_months.filter((im) => im.month === m)[0].amount) : '-'}
                            </td>
                          ))}

                          {income.copied ? (
                            <td className="text-end copied-value">{formatter.format(income.amount)}</td>
                          ) : (
                            <td className="text-end">{formatter.format(income.amount)}</td>
                          )}
                          <td colSpan={3}></td> 
                        </tr>
                        </React.Fragment>
                    ))}

                    <tr className="budget-summary-subtotal">
                      <td colSpan={4} className="text-end budget-summary-acc-cat"><em><strong>Total for {cat}</strong></em></td>
                      {Array.from({length:12},(v,k)=>k+1).map((m) => (
                        <td key={cat + '_' + m} className="text-end budget-summary-month" style={{ maxWidth: 80 + 'px' }}>
                          <strong>{ (month_totals[cat] && month_totals[cat][m]) ? formatter.format(month_totals[cat][m]) : '-'}</strong>
                        </td>
                      ))}
                      <td className="text-end budget-summary-acc-cat"><strong><em>{(cat_totals[cat]) ? formatter.format(cat_totals[cat]) : "-"}</em></strong></td>
                      <td className="text-end budget-summary-acc-cat"><strong><em>{(ly_cat_totals[cat]) ? formatter.format(ly_cat_totals[cat]) : "-"}</em></strong></td>
                      <td className="text-end budget-summary-acc-cat"><strong><em>
                        {(ly_cat_totals[cat] && cat_totals[cat]) ? 
                          formatter.format(cat_totals[cat]-ly_cat_totals[cat]) : 
                          (ly_cat_totals[cat]) ? formatter.format(0-ly_cat_totals[cat]) : "-"} 
                        </em></strong>
                      </td>
                      <td className="text-end budget-summary-acc-cat">
                        <strong><em>
                          {(ly_cat_totals[cat] && cat_totals[cat]) ? (
                            (cat_totals[cat]-ly_cat_totals[cat] != 0) ?
                            pformatter.format((cat_totals[cat]-ly_cat_totals[cat])*100/ly_cat_totals[cat]) + "%" : "0%"
                          ) : (ly_cat_totals[cat]) ? "-100%" : "-"}
                        </em></strong>
                      </td>
                    </tr>


                    <tr>
                      <td colSpan={20}>&nbsp;</td>
                    </tr>

                  </React.Fragment>
                   ) : ''
                ))}
              </React.Fragment>
            ) : ''}
          </tbody>
          <tfoot>
            <tr className="budget-summary-grand-total">
                <td colSpan={4} className="text-end"><strong>GRAND TOTAL:</strong></td>
                {Array.from({length:12},(v,k)=>k+1).map((m) => (
                  <td key={m + '_grand_total'} className="text-end budget-summary-month" style={{ maxWidth: 80 + 'px' }}>
                    <strong>{ (month_totals[m]) ? formatter.format(month_totals[m]) : '-'}</strong>
                  </td>
                ))}
                <td className="text-end"><strong>{formatter.format(total_amount)}</strong></td>
                <td className="text-end"><strong>{(ly_total_amount != 0) ? formatter.format(ly_total_amount) : "-"}</strong></td>
                <td className="text-end"><strong>{(ly_total_amount != 0 && total_amount != 0) ? formatter.format(total_amount - ly_total_amount) : "-"}</strong></td>
                <td className="text-end">
                  <strong>
                    {(ly_total_amount != 0 && total_amount != 0) ? (
                      (total_amount - ly_total_amount != 0) ?
                      pformatter.format((total_amount - ly_total_amount)*100/ly_total_amount) + "%" : "0%"
                      ) : " - "}
                  </strong>
                </td>
              </tr>
          </tfoot>
        </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default IncomeSummaryMonth;