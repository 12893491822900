import React, { useState, useEffect, useContext } from "react";
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Spinner, TabContent, TabPane, Collapse, Nav, NavItem, NavLink, Row, ListGroup, ListGroupItem} from "reactstrap";
import Select from 'react-select'
import "assets/css/sidebar.css";
import { useNavigate, useParams } from "react-router-dom";


function BudgetYearEdit(props) {

    let { budgetYearId } = useParams();

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [departments, setDepartments] = useState([]);
    const [error, setError] = useState('');
    const api = useAxios();
    const years = [{ value: 2023, label: '2023' }, { value: 2024, label: '2024' }];

    const [dept_categories, setDeptCategories] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [funds, setFunds] = useState([]);
    const [acc_collapse, setAccountCollapse] = useState({});
    const [acc_cat_checks, setAccountCatChecks] = useState({});
    const [acc_checks, setAccountChecks] = useState({});
    const [fund_checks, setFundChecks] = useState({})

    const [budget_year, setBudgetYear] = useState('');
    const [year, setYear] = useState(2023);
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [label, setLabel] = useState('');
    const [budget_departments, setBudgetDepartments] = useState([]);
    const [selected_budget_departments, setSelectedBudgetDepartments] = useState([]);
    const [budget_accounts, setBudgetAccounts] = useState([]);
    const [budget_funds, setBudgetFunds] = useState([]);


    const [isSaving, setIsSaving] = useState(false)
    const [isCopying, setIsCopying] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    useEffect(() => {

        if (!user.is_portal_admin) {
            navigate('/dashboard')
        }

        const BUDGET_YEAR_DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/" + budgetYearId + "/";
        // const DATA_URL = process.env.REACT_APP_API_URL + "/department/";
        const DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/create_form";

        const fetchData = async () => {
          try {
            // Get data to populate form
            const result = await api.get(DATA_URL);
            setDeptCategories(result.data.department_categories);
            const depts = [];
            result.data.department.map((dept) => {
                depts.push({ value: dept.id, label: dept.abbr + ": " + dept.name });
                return 0;
            });
            setDepartments(depts);
            const acc_check_states = setAccountsbyCategory(result.data.accounts, result.data.department_categories);
            setFunds(result.data.funds);
            const fundCheckStates = setFundChecksState(result.data.funds);

            // Get this budget year data
            const budget_year = await api.get(BUDGET_YEAR_DATA_URL);
            setBudgetYear(budget_year.data);
            setYear(budget_year.data.year);
            setStartDate(budget_year.data.start_date);
            setEndDate(budget_year.data.end_date);
            setLabel(budget_year.data.label);
            const selectedDepts = [];
            const budget_depts = [];
            budget_year.data.departments.forEach((dept) => {
                selectedDepts.push({ value: dept.department.id, label: dept.department.abbr + ": " + dept.department.name })
                budget_depts.push({'id': dept.department.id});
            });
            setBudgetDepartments(budget_depts);
            setSelectedBudgetDepartments(selectedDepts);
            const budget_accs = [];
            budget_year.data.budget_accounts.forEach((acc) => {
                budget_accs.push({'department_category' : acc.department_category.id, 'account' : acc.account.id});
                acc_check_states[acc.department_category.id][acc.account.id] = true;
            });
            setAccountChecks(acc_check_states);
            setBudgetAccounts(budget_accs);

            const budgetFunds = [];
            budget_year.data.budget_year_funds.forEach((f) => {
                budgetFunds.push(f.fund.id);
                fundCheckStates[f.fund.id] = true;
            });
            setBudgetFunds(budgetFunds);
            setFundChecks(fundCheckStates);
          } catch {
            setError("Something went wrong");
          }
        };
        fetchData();
    }, []);

    function setBudgetYearDepartments(val) {
        const depts = [];
        const selectedDepts = [];
        val.forEach((dept) => {
           depts.push({'id': dept.value});
           selectedDepts.push(dept);
        })
        setBudgetDepartments(depts);
        setSelectedBudgetDepartments(selectedDepts);
    }

    function setAccountsbyCategory(raw_acc_list, dept_cat) {
        const acc_list = [];
        const acc_cats = [];
        var cat_id = 0;
        var cat_index = -1;
        raw_acc_list.forEach((a) => {
            if (cat_id !== a.category.id) {
                cat_index++;
                acc_list.push({'cat_id' : a.category.id, 'cat_name' : a.category.name, 'accs' : []});
                cat_id = a.category.id;
                acc_cats.push(cat_id);
            }
            acc_list[cat_index]['accs'].push({'id' : a.id, 'desc': a.description, 'acc_no': a.account_number })
        });
        setAccountCollapseState(acc_cats, dept_cat);
        const acc_check_states = setAccountChecksState(acc_list, dept_cat);
        setAccounts(acc_list);
        return acc_check_states;
    }

    function setAccountCollapseState(acc_cats, dept_cat) {
        let acc_collapse_states = {}
        dept_cat.forEach((dc) => {
            acc_collapse_states[dc.id] = {};
            acc_cats.forEach((a) => {
                acc_collapse_states[dc.id][a] = {'isOpen': false, 'label' : "Show Individual Accounts"};
            });
        });
        // console.log(JSON.stringify(acc_collapse_states));
        setAccountCollapse(acc_collapse_states);
    }

    function setAccountChecksState(acc_list, dept_cat) {
        let acc_check_states = {}
        dept_cat.forEach((dc) => {
            acc_check_states[dc.id] = {};
            acc_list.forEach((acc_cat) => {
                acc_cat['accs'].forEach((a) => {
                    acc_check_states[dc.id][a.id] = false;
                });
            });
        });
        return acc_check_states;
    }

    function setFundChecksState(fund_list) {
        let fundCheckStates = {}
        fund_list.forEach((f) => {
            fundCheckStates[f.id] = false;
        });
        return fundCheckStates;
    }

    function toggleAccountDisplay(dept_cat, acc_cat) {
        const updated_acc_collapse = {...acc_collapse};
        updated_acc_collapse[dept_cat][acc_cat]['isOpen'] = !acc_collapse[dept_cat][acc_cat]['isOpen'];
        updated_acc_collapse[dept_cat][acc_cat]['label'] = ((updated_acc_collapse[dept_cat][acc_cat]['isOpen']) ? "Hide" : "Show") + " Individual Accounts";
        setAccountCollapse(updated_acc_collapse);
    }

    function toggleAccountCheck(dept_cat, acc_id) {
        const updated_acc_checks = {...acc_checks};
        updated_acc_checks[dept_cat][acc_id] = !acc_checks[dept_cat][acc_id];
        setAccountChecks(updated_acc_checks);
        updateBudgetAccounts(updated_acc_checks);
    }

    function toggleFundCheck(f_id) {
        const updatedFundChecks = {...fund_checks};
        updatedFundChecks[f_id] = !fund_checks[f_id];
        setFundChecks(updatedFundChecks);
        updateBudgetFunds(updatedFundChecks);
    }

    function updateBudgetAccounts(checked_accounts) {
        const budget_accs = [];
        const depts = Object.keys(checked_accounts);
        depts.forEach((dept_cat) => {
            const a_id = Object.keys(checked_accounts[dept_cat]);
            a_id.forEach((a_id) => {
                if (checked_accounts[dept_cat][a_id]) {
                    budget_accs.push({'department_category' : dept_cat, 'account' : a_id});
                }
            });
        });
        setBudgetAccounts(budget_accs);
    }

    function updateBudgetFunds(checkedFunds) {
        const budgetFunds = [];
        const f_ids = Object.keys(checkedFunds);
        f_ids.forEach((f_id) => {
            if (checkedFunds[f_id]) {
                budgetFunds.push(f_id);
            }
        });
        setBudgetFunds(budgetFunds);
    }



    function validate() {

        return true;
    }

    function handleSubmit(event) {
        setIsSaving(true);
        const PUT_URL = process.env.REACT_APP_API_URL + "/budget_year/" + budgetYearId + "/";
        if (validate()) {
            api.put(PUT_URL, {
                'year': year,
                'start_date': start_date,
                'end_date': end_date,
                'label': label,
                'departments': budget_departments,
                'budget_accounts': budget_accounts,
                'budget_funds': budget_funds,
            })
            .then(function (response) {
                alert("Budget Year successfully updated");
                navigate('/dashboard')
            })
            .catch(function (error) {
                alert(error.message);
            });
        }
        event.preventDefault();
    }

    function copyBudget() {
        setIsCopying(true);
        const POST_URL = process.env.REACT_APP_API_URL + "/budget_year/" + budgetYearId + "/copy/";
        api.post(POST_URL)
        .then(function (response) {
            alert("Budget Year successfully copied");
            navigate('/dashboard')
        })
        .catch(function (error) {
            alert(error.message);
        });
    }

    function supplementary() {
        setIsCopying(true);
        const POST_URL = process.env.REACT_APP_API_URL + "/budget_year/" + budgetYearId + "/supplementary/";
        api.post(POST_URL)
        .then(function (response) {
            alert("Supplementary Budget successfully copied");
            navigate('/dashboard')
        })
        .catch(function (error) {
            alert(error.message);
        });
    }

    function deleteBudgetYear() {
        setIsDeleting(true);
        if (window.confirm("Are you sure you want to delete this budget year?\n\nThis action cannot be undone.")) {
            const DELETE_URL = process.env.REACT_APP_API_URL + "/budget_year/" + budgetYearId + "/";
            api.delete(DELETE_URL)
            .then(function (response) {
                alert("Budget Year successfully deleted");
                navigate('/dashboard')
            })
            .catch(function (error) {
                alert(error.message);
            });
        }
    }

    // console.log(JSON.stringify(budget_accounts));
    // console.log(JSON.stringify(acc_checks));


    return (
        <React.Fragment>
            {budget_year ? (
            <div className="m-2">
                <h4>Edit Budget Year {year} {label.length > 0 ? (<span> &mdash; {label}</span>) : ''}</h4>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <FormGroup row>
                        <Label for="year" sm={2}>
                           Year
                        </Label>
                        <Col sm={2}>
                            <Select options={years} id="year" name="year" placeholder="choose a year"
                                    value={{ value: year, label: year }} onChange={(val) => setYear(val.value)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="description" sm={2}>
                            Label
                        </Label>
                        <Col sm={6}>
                            <Input id="label" name="label" placeholder="label" type="text" bsSize="sm"
                                value={label ? label : ''} onChange={(e) => setLabel(e.target.value)} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="start_date" sm={2}>
                            Submission start date
                        </Label>
                        <Col sm={2}>
                            <Input id="start_date" name="start_date" type="date" bsSize="sm" defaultValue={start_date} onChange={(e) => setStartDate(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="end_date" sm={2}>
                            Submission deadline
                        </Label>
                        <Col sm={2}>
                            <Input id="end_date" name="end_date" type="date" bsSize="sm" defaultValue={end_date} onChange={(e) => setEndDate(e.target.value)}  />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="departments" sm={2}>
                            Cost Centres
                        </Label>
                        <Col sm={10}>
                        <Select name="departments" id="departments" options={departments} value={selected_budget_departments} isMulti onChange={setBudgetYearDepartments} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="accounts" sm={2}>
                            Accounts Selection
                        </Label>
                        <Col sm={10}>
                            {(dept_categories.length > 0) ? (
                                <React.Fragment>
                                    <Nav tabs>
                                        {dept_categories.map((dc, i) => (
                                            <NavItem key={dc.id}>
                                                <NavLink active={currentActiveTab === i+1} onClick={() => { toggle(i+1); }} role="button">
                                                    {dc.name}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                    <TabContent activeTab={currentActiveTab}>
                                        {dept_categories.map((dc, i) => (
                                            <TabPane key={dc.id} tabId={i+1}>
                                                <Row>
                                                    <Col sm="12">
                                                        <p className="mt-3">Select accounts for {dc.name}</p>
                                                        {(accounts.length > 0) ? (
                                                            <ListGroup>
                                                                {accounts.map((account_cat) => (
                                                                    <ListGroupItem key={account_cat.cat_id}>
                                                                        <FormGroup check>
                                                                            {/* <Input type="checkbox" checked={false} onChange={() => {return true;}}/> */}
                                                                            <Label check>{account_cat.cat_name}</Label>
                                                                            <span className="ms-3 badge rounded-pill bg-secondary" onClick={() => { toggleAccountDisplay(dc.id, account_cat.cat_id);}} role="button">
                                                                                {acc_collapse[dc.id][account_cat.cat_id]['label']}
                                                                            </span>
                                                                        </FormGroup>

                                                                        <Collapse isOpen={acc_collapse[dc.id][account_cat.cat_id]['isOpen']} className="ms-4">
                                                                        {account_cat.accs.map((a) => (
                                                                            <FormGroup check key={dc.id + "_" + account_cat.cat_id + "_" + a.id}>
                                                                                <Input type="checkbox" id={dc.id + "_" + a.id} checked={(acc_checks[dc.id]) ? acc_checks[dc.id][a.id] : false} onChange={() => { toggleAccountCheck(dc.id, a.id);}} role="button"/>
                                                                                <Label check htmlFor={dc.id + "_" + a.id}>{a.acc_no} {a.desc}</Label>
                                                                            </FormGroup>
                                                                        ))}
                                                                        </Collapse>

                                                                    </ListGroupItem>
                                                                ))}
                                                            </ListGroup>
                                                        ) : ''}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        ))}
                                    </TabContent>
                                </React.Fragment>
                            ): ''}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="departments" sm={2}>
                            Funds Selection
                        </Label>
                        <Col sm={10}>
                            {(funds.length > 0) ? (
                            <ul className="list-unstyled card-columns" style={{columnCount: 2}}>
                                {funds.map((f) => (
                                    <li key={f.id + "li"}>
                                    <FormGroup check key={f.id}>
                                        <Input type="checkbox" id={f.id + "_" + f.code} checked={fund_checks[f.id]} onChange={() => { toggleFundCheck(f.id);}} role="button"/>
                                        <Label check htmlFor={f.id + "_" + f.code}>{f.code} {f.description}</Label>
                                    </FormGroup>
                                    </li>
                                ))}
                            </ul>
                            ) : ''}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            {(isSaving || isCopying || isDeleting) ? 
                            (
                                (isSaving) ? (
                                    <span>
                                        <Spinner size="sm">Updating, please wait...</Spinner> <span>Updating, please wait...</span>
                                    </span>
                                ) : 
                                (isCopying) ? (
                                    <span>
                                        <Spinner size="sm">Copying, please wait...</Spinner> <span>Copying, please wait...</span>
                                    </span>
                                ) : 
                                (isDeleting) ? (
                                    <span>
                                        <Spinner size="sm">Deleting, please wait...</Spinner> <span>Deleting, please wait...</span>
                                    </span>
                                ) : ''
                            ) : (
                                <React.Fragment>
                                    <Button href={"/budget/dashboard"} variant="secondary">
                                        Cancel
                                    </Button>
                                    {' '}
                                    <Button type="submit" id="Submit" name="Submit" color="primary">
                                        Update
                                    </Button>
                                    {' '}
                                    <Button className="ms-5" type="button" id="Copy" name="Copy" color="warning" onClick={copyBudget}>
                                        Copy to {year + 1} budget year
                                    </Button>
                                    {' '}
                                    <Button className="ms-5" type="button" id="Supplementary" name="Supplementary" color="warning" onClick={supplementary}>
                                        Create Supplementary Budget
                                    </Button>
                                    {user.is_superuser ? (
                                        <React.Fragment>
                                            {' '}
                                            <Button className="ms-5" type="button" id="Delete" name="Delete" color="danger" onClick={deleteBudgetYear}>
                                                Delete
                                            </Button>
                                        </React.Fragment>
                                    ) : ''}
                                </React.Fragment>
                            )}
                        </Col>
                    </FormGroup>

                </Form>
            </div>
            ) : (
                <div className="text-center mt-3">
                    <Spinner>Loading...</Spinner>
                    <p>Loading...</p>
                </div>
            )}
        </React.Fragment>
    );
}

export default BudgetYearEdit;
