import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, json } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Table, InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import "assets/css/sidebar.css";


function UserEdit(props) {  

    let { staffId } = useParams();

    const navigate = useNavigate();
    const [error, setError] = useState({});
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);

    const [groups, setGroups] = useState([]);
    const [staff, setStaff] = useState();
    const [userUsername, setUserUsername] = useState('');
    const [userFullname, setUserFullname] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userGroups, setUserGroups] = useState([]);

    const [staffGroup, setStaffGroup] = useState();
    const [selectedUserGroups, setSelectedUserGroups] = useState([]);


    useEffect(() => {
        const DATA_URL = process.env.REACT_APP_API_URL + "/account/user/create_form/";
        const STAFF_DATA_URL = process.env.REACT_APP_API_URL + "/account/user/" + staffId + "/";

        const fetchData = async () => {
            try {
                const result = await api.get(DATA_URL);
                if (Object.keys(result.data).length == 0) {
                    navigate('/dashboard');
                }
                const groups = [];
                const userGroups = [];
                const selectedGroups = []
                result.data.groups.map((g) => {
                    const g_option = { value: g.id, label: g.name };
                    groups.push(g_option);
                    if (g.name === "APS Portal Staff") {
                        setStaffGroup(g_option);
                    }
                    return 0;
                });
                setGroups(groups);

                const staff = await api.get(STAFF_DATA_URL);

                setUserUsername(staff.data.username);
                setUserFullname(staff.data.fullname);
                setUserEmail(staff.data.email);
                groups.map((g) => {
                    if (staff.data.groups.includes(g.label)) {
                        selectedGroups.push(g);
                        userGroups.push({'id': g.value});
                    }
                });
                setSelectedUserGroups(selectedGroups);
                setUserGroups(userGroups);
            } catch {
                setError("Something went wrong. Please refresh the page to try again.");
            }
        };
        fetchData();
    }, []);

    function updateUserGroups(val) {
        const groups = [{'id': staffGroup.value}];
        const selectedGroups = [staffGroup];
        val.forEach((g) => {
            if (g.value !== staffGroup.value) {
                selectedGroups.push(g);
                groups.push({'id': g.value});
            }
        })
        setUserGroups(groups);
        setSelectedUserGroups(selectedGroups);
    }

    function validate() {
        if (!userFullname) {
            alert("Please enter full name");
            return false;
        }
        if (!userUsername) {
            alert("Please enter username");
            return false;
        }
        if (!userEmail) {
            alert("Please enter email address");
            return false;
        }
        return true;
    }

    function handleSubmit(event) {
        const PUT_URL = process.env.REACT_APP_API_URL + "/account/user/" + staffId + "/";
        if (validate()) {
            api.put(PUT_URL, {
                // 'csrfmiddlewaretoken': 'XDpd80GzwdwOiKgb6PKnpCaDjcijtlaHF3HlnhcYBPFJEHD2gHCROy8VKlPWdi3N',
                'username': userUsername,
                'fullname': userFullname,
                'email': userEmail,
                'groups': userGroups,
            })
            .then(function (response) {
                if (response.status == 200 || response.status == 204) {
                    alert("Staff user account updated successfully");
                    navigate("/staff");
                } else {
                    alert("Something went wrong. Please try again or contact support");
                }
            })
            .catch(function (error) {
                setError(error.response.data);
                alert(error.message);
            });
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="m-2">
                <h3>Edit staff</h3>
                <hr />
                <Form onSubmit={handleSubmit} className="d-flex flex-column flex-grow-1">
                    <FormGroup row>
                        <Label for="user_fullname" sm={2}>
                            Staff Full Name
                        </Label>
                        <Col sm={4}>
                            <Input id="user_fullname" name="user_fullname" placeholder="full name" type="text" bsSize="sm"
                                value={userFullname ? userFullname : ''} onChange={(e) => setUserFullname(e.target.value)} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="user_username" sm={2}>
                            Staff username
                        </Label>
                        <Col sm={4}>
                            <Input id="user_username" name="user_username" placeholder="username" type="text" bsSize="sm"
                                value={userUsername ? userUsername : ''} onChange={(e) => setUserUsername(e.target.value)} />
                            <small>Please use the same username as APS Portal</small>
                            {error.hasOwnProperty('username') ? (
                                <p className="text-danger clearfix">{error['username']}</p>
                            ) : ''}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="user_email" sm={2}>
                            Email address
                        </Label>
                        <Col sm={10}>
                            <Input id="user_email" name="user_email" placeholder="email address" type="text" bsSize="sm"
                                value={userEmail ? userEmail : ''} onChange={(e) => setUserEmail(e.target.value)} />
                            <small>Please ensure you enter the correct email address. Password reset link will be sent to this email address</small>
                            {error.hasOwnProperty('email') ? (
                                <p className="text-danger clearfix">{error['email']}</p>
                            ) : ''}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="groups" sm={2}>
                            Groups
                        </Label>
                        <Col sm={6}>
                            {(groups.length > 0) ? (
                                <React.Fragment>
                                    <Select name="groups" id="groups" options={groups} value={selectedUserGroups} isMulti onChange={updateUserGroups} />
                                    <small>A user must belong to at least "APS Portal Staff" group</small>
                                </React.Fragment>
                            ) : (
                                <p>Loading list of groups...</p>
                            )}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Save
                            </Button>
                            {' '}
                            <Button href={`/budget/staff/`} variant="secondary">
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default UserEdit;
