import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Table, InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import "assets/css/sidebar.css";


function PLCategoryEdit(props) {

    let { plCatId } = useParams();

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);
    const [pl_cat_name, setPLCatName] = useState('');
    const [pl_cat_level, setPLCatLevel] = useState(0);
    const [pl_cat_sort_order, setPLCatSortOrder] = useState('');
    const [pl_cat_pl_type, setPLCatPLType] = useState('');
    const [pl_cat_pl_category_type, setPLCatPLCategoryType] = useState('');
    const [pl_cat_parent_id, setPLCatParentId] = useState(null);
    const [pl_cat_parents, setPLCatParents] = useState([]);

    const [tab_no, setTabNo] = useState(1);


    useEffect(() => {
        const PL_CATEGORY_DATA_URL = process.env.REACT_APP_API_URL + "/pl_category/" + plCatId + "/";
        const fetchData = async () => {
            try {
                // Get this pl_category data
                const pl_cat = await api.get(PL_CATEGORY_DATA_URL);
                if (Object.keys(pl_cat.data).length == 0) {
                    navigate('/dashboard');
                }
                setPLCatName(pl_cat.data.name);
                setPLCatLevel(pl_cat.data.level);
                setPLCatSortOrder(pl_cat.data.sort_order);
                setPLCatPLType(pl_cat.data.pl_type);
                setPLCatPLCategoryType(pl_cat.data.pl_category_type);
                setPLCatParentId(pl_cat.data.parent);

                if (pl_cat.data.pl_category_type == 'INCOME') {
                    setTabNo(1)
                } else if (pl_cat.data.pl_type == 'DIVISION') {
                    setTabNo(3)
                } else {
                    setTabNo(2)
                }

            } catch {
                setError("Something went wrong");
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const PL_CATEGORY_FORM_DATA_URL = process.env.REACT_APP_API_URL + "/pl_category/create_form/";
        const fetchData = async (pl_cat_level) => {
            try {
                var pl_category_type = "";
                if (pl_cat_pl_category_type === "CAPITAL EXPENSE") {
                    pl_category_type = "CAPEX";
                } else {
                    pl_category_type = pl_cat_pl_category_type;
                }
                const result = await api.get(PL_CATEGORY_FORM_DATA_URL + (pl_cat_level-1) + "/" + pl_cat_pl_type + "/" + pl_category_type + "/");
                const cats = [];
                result.data.map((c) => {
                    cats.push({ value: c.id, label: c.name });
                    return 0;
                });
                setPLCatParents(cats);
            } catch {
                setError("Something went wrong");
            }
        };
        if (pl_cat_level > 1) {
            fetchData(pl_cat_level);
        }
    }, [pl_cat_level])

    function validate() {
        if (!pl_cat_name) {
            alert("Please enter P&L Category Name");
            return false;
        }
        if (!pl_cat_sort_order) {
            alert("Please enter P&L Category Sort Order");
            return false;
        }
        return true;
    }

    function handleSubmit(event) {
        const PUT_URL = process.env.REACT_APP_API_URL + "/pl_category/" + plCatId + "/";
        if (validate()) {
            var data = {
                'name': pl_cat_name,
                'level': pl_cat_level,
                'sort_order': pl_cat_sort_order,
                'pl_type': pl_cat_pl_type,
                'pl_category_type': pl_cat_pl_category_type,
            }
            if (pl_cat_parent_id != null) {
                data['parent'] = pl_cat_parent_id;
            }
            api.put(PUT_URL, data).then(function (response) {
                    if (response.status == 200 || response.status == 204) {
                        alert("P&L Category updated successfully");
                        navigate("/pl_categories/" + tab_no);
                    } else {
                        alert("Something went wrong. Please try again or contact support");
                    }
                })
                .catch(function (error) {
                    alert(error.message);
                }
            );
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="m-2">
                <h4>Edit P&L Category</h4>
                <hr />
                <Form onSubmit={handleSubmit} className="d-flex flex-column flex-grow-1">
                    <FormGroup row>
                        <Label for="description" sm={2}>
                            Level
                        </Label>
                        <Col sm={10}>
                            {pl_cat_level ? pl_cat_level : ''}
                        </Col>
                    </FormGroup>
                    {(pl_cat_pl_category_type && pl_cat_pl_category_type != 'INCOME') ? (
                        <FormGroup row>
                            <Label for="category" sm={2}>
                                P&L Type
                            </Label>
                            <Col sm={10}>
                                {pl_cat_pl_type ? pl_cat_pl_type : ''}
                            </Col>
                        </FormGroup>
                    ) : ''}
                    <FormGroup row>
                        <Label for="category" sm={2}>
                            P&L Category Type
                        </Label>
                        <Col sm={10}>
                            {pl_cat_pl_category_type ? pl_cat_pl_category_type : ''}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="account_number" sm={2}>
                            Name
                        </Label>
                        <Col sm={4}>
                            <Input id="pl_cat_name" name="pl_cat_name" placeholder="P&L Category Name" type="text" bsSize="sm"
                                value={pl_cat_name ? pl_cat_name : ''} onChange={(e) => setPLCatName(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="account_number" sm={2}>
                            Sort Order
                        </Label>
                        <Col sm={2}>
                            <Input id="pl_cat_sort_order" name="pl_cat_sort_order" placeholder="P&L Sort Order" type="text" bsSize="sm"
                                value={pl_cat_sort_order ? pl_cat_sort_order : ''} onChange={(e) => setPLCatSortOrder(e.target.value)} />
                        </Col>
                    </FormGroup>

                    {pl_cat_level > 1 ? (
                        <FormGroup row>
                            <Label for="pl_category" sm={2}>
                                Parent (level {pl_cat_level - 1})
                            </Label>
                            <Col sm={6}>
                                {(pl_cat_parents.length === 0) ? (
                                    <p>Loading list of P&L categories...</p>
                                ) : (
                                    <Select options={pl_cat_parents} id="pl_category" name="pl_category" placeholder="select a P&L Category parent"
                                        value={pl_cat_parents.filter(a => a.value === pl_cat_parent_id)[0]} onChange={(val) => setPLCatParentId(val.value)} />
                                )}
                            </Col>
                            </FormGroup>
                    ) : ''}

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Save
                            </Button>
                            {' '}
                            <Button href={`/budget/pl_categories/${tab_no}`} variant="secondary">
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default PLCategoryEdit;
