import React, { useState, useEffect, useContext } from "react";
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Row, Input, Button, ListGroup, ListGroupItem,  Nav, NavItem, NavLink, TabContent, TabPane, Collapse } from "reactstrap";
import Select from 'react-select'
import "assets/css/sidebar.css";
import { useNavigate } from "react-router-dom";


function BudgetYearAdd(props) {

    const { user } = useContext(AuthContext);

    const api = useAxios();

    const navigate = useNavigate();

    const [dept_categories, setDeptCategories] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [error, setError] = useState('');
    const years = [{ value: 2023, label: '2023' }, { value: 2024, label: '2024' }];
    const [accounts, setAccounts] = useState([]);
    const [funds, setFunds] = useState([]);
    const [acc_collapse, setAccountCollapse] = useState({});
    const [acc_cat_checks, setAccountCatChecks] = useState({});
    const [acc_checks, setAccountChecks] = useState({});
    const [fund_checks, setFundChecks] = useState({})

    const [budget_year, setBudgetYear] = useState(2023);
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [budget_departments, setBudgetDepartments] = useState([]);
    const [budget_accounts, setBudgetAccounts] = useState([]);
    const [budget_funds, setBudgetFunds] = useState([]);

      // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }


    useEffect(() => {

        if (!user.is_portal_admin) {
            navigate('/dashboard');
        }
    
        const DATA_URL = process.env.REACT_APP_API_URL + "/budget_year/create_form/";
        const fetchData = async () => {
          try {
            const result = await api.get(DATA_URL);
            setDeptCategories(result.data.department_categories);
            const depts = [];
            result.data.department.map((dept) => {
                depts.push({ value: dept.id, label: dept.abbr + ": " + dept.name });
                return 0;
            });
            setDepartments(depts);
            setAccountsbyCategory(result.data.accounts, result.data.department_categories);
            setFunds(result.data.funds);
            setFundChecksState(result.data.funds);
          } catch {
            setError("Something went wrong");
          }
        };
        fetchData();
    }, []);

    function setAccountsbyCategory(raw_acc_list, dept_cat) {
        const acc_list = [];
        const acc_cats = [];
        var cat_id = 0;
        var cat_index = -1;
        raw_acc_list.forEach((a) => {
            if (cat_id !== a.category.id) {
                cat_index++;
                acc_list.push({'cat_id' : a.category.id, 'cat_name' : a.category.name, 'accs' : []});
                cat_id = a.category.id;
                acc_cats.push(cat_id);
            }
            acc_list[cat_index]['accs'].push({'id' : a.id, 'desc': a.description, 'acc_no': a.account_number })
        });
        setAccountCollapseState(acc_cats, dept_cat);
        setAccountChecksState(acc_list, dept_cat);
        setAccounts(acc_list);
    }

    function setAccountCollapseState(acc_cats, dept_cat) {
        let acc_collapse_states = {}
        dept_cat.forEach((dc) => {
            acc_collapse_states[dc.id] = {};
            acc_cats.forEach((a) => {
                acc_collapse_states[dc.id][a] = {'isOpen': false, 'label' : "Show Individual Accounts"};
            });
        });
        // console.log(JSON.stringify(acc_collapse_states));
        setAccountCollapse(acc_collapse_states);
    }

    function setAccountChecksState(acc_list, dept_cat) {
        let acc_check_states = {}
        dept_cat.forEach((dc) => {
            acc_check_states[dc.id] = {};
            acc_list.forEach((acc_cat) => {
                acc_cat['accs'].forEach((a) => {
                    acc_check_states[dc.id][a.id] = false;
                });
            });
        });
        setAccountChecks(acc_check_states);
    }

    function setFundChecksState(fund_list) {
        let fundCheckStates = {}
        fund_list.forEach((f) => {
            fundCheckStates[f.id] = true;
        });
        setFundChecks(fundCheckStates);
    }

    function setBudgetYearDepartments(val) {
        const depts = [];
        val.forEach((dept) => {
           depts.push({'id': dept.value});
        })
        
        setBudgetDepartments(depts);
    }

    function validate() {

        return true;
    }

    function toggleAccountDisplay(dept_cat, acc_cat) {
        const updated_acc_collapse = {...acc_collapse};
        updated_acc_collapse[dept_cat][acc_cat]['isOpen'] = !acc_collapse[dept_cat][acc_cat]['isOpen'];
        updated_acc_collapse[dept_cat][acc_cat]['label'] = ((updated_acc_collapse[dept_cat][acc_cat]['isOpen']) ? "Hide" : "Show") + " Individual Accounts";
        setAccountCollapse(updated_acc_collapse);
    }

    function toggleAccountCheck(dept_cat, acc_id) {
        const updatedAccChecks = {...acc_checks};
        updatedAccChecks[dept_cat][acc_id] = !acc_checks[dept_cat][acc_id];
        setAccountChecks(updatedAccChecks);
        updateBudgetAccounts(updatedAccChecks);
    }

    function toggleFundCheck(f_id) {
        const updatedFundChecks = {...fund_checks};
        updatedFundChecks[f_id] = !fund_checks[f_id];
        setFundChecks(updatedFundChecks);
        updateBudgetFunds(updatedFundChecks);
    }

    function updateBudgetAccounts(checked_accounts) {
        const budget_accs = [];
        const depts = Object.keys(checked_accounts);
        depts.forEach((dept_cat) => {
            const a_id = Object.keys(checked_accounts[dept_cat]);
            a_id.forEach((a_id) => {
                if (checked_accounts[dept_cat][a_id]) {
                    budget_accs.push({'department_category' : dept_cat, 'account' : a_id});
                }
            });
        });
        setBudgetAccounts(budget_accs);
    }

    function updateBudgetFunds(checkedFunds) {
        const budgetFunds = [];
        const f_ids = Object.keys(checkedFunds);
        f_ids.forEach((f_id) => {
            if (checkedFunds[f_id]) {
                budgetFunds.push(f_id);
            }
        });
        setBudgetFunds(budgetFunds);
    }

    function handleSubmit(event) {
        const POST_URL = process.env.REACT_APP_API_URL + "/budget_year/";
        if (validate()) {
            api.post(POST_URL, {
                'year': budget_year,
                'start_date': start_date,
                'end_date': end_date,
                'departments': budget_departments,
                'budget_accounts': budget_accounts,
                'budget_funds': budget_funds,
            })
            .then(function (response) {
                alert("Budget Year successfully created");
                navigate('/dashboard')
            })
            .catch(function (error) {
                alert(error.message);
            });
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="m-2">
                <h4>Create Budget Year</h4>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <FormGroup row>
                        <Label for="year" sm={2}>
                           Year
                        </Label>
                        <Col sm={2}>
                            <Select options={years} id="year" name="year" placeholder="choose a year"
                                    defaultValue={years[0]} onChange={(val) => setBudgetYear(val.value)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="start_date" sm={2}>
                            Submission start date
                        </Label>
                        <Col sm={2}>
                            <Input id="start_date" name="start_date" type="date" bsSize="sm" onChange={(e) => setStartDate(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="end_date" sm={2}>
                            Submission deadline
                        </Label>
                        <Col sm={2}>
                            <Input id="end_date" name="end_date" type="date" bsSize="sm" onChange={(e) => setEndDate(e.target.value)}  />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="departments" sm={2}>
                            Departments
                        </Label>
                        <Col sm={10}>
                        <Select name="departments" id="departments" options={departments} isMulti onChange={setBudgetYearDepartments} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="accounts" sm={2}>
                            Accounts Selection
                        </Label>
                        <Col sm={10}>
                            {(dept_categories.length > 0) ? (
                                <React.Fragment>
                                    <Nav tabs>
                                        {dept_categories.map((dc, i) => (
                                            <NavItem key={dc.id}>
                                                <NavLink active={currentActiveTab === i+1} onClick={() => { toggle(i+1); }} role="button">
                                                    {dc.name}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                    <TabContent activeTab={currentActiveTab}>
                                        {dept_categories.map((dc, i) => (
                                            <TabPane tabId={i+1}>
                                                <Row>
                                                    <Col sm="12">
                                                        <p className="mt-3">Select accounts for {dc.name} departments</p>
                                                        {(accounts.length > 0) ? (
                                                            <ListGroup>
                                                                {accounts.map((account_cat) => (
                                                                    <ListGroupItem key={account_cat.cat_id}>
                                                                        <FormGroup check>
                                                                            <Input type="checkbox" checked={true}/>
                                                                            <Label check>{account_cat.cat_name}</Label>
                                                                            <span className="ms-3 badge rounded-pill bg-secondary" onClick={() => { toggleAccountDisplay(dc.id, account_cat.cat_id);}} role="button">
                                                                                {acc_collapse[dc.id][account_cat.cat_id]['label']}
                                                                            </span>
                                                                        </FormGroup>

                                                                        <Collapse isOpen={acc_collapse[dc.id][account_cat.cat_id]['isOpen']} className="ms-4">
                                                                        {account_cat.accs.map((a) => (
                                                                            <FormGroup check key={dc.id + "_" + account_cat.cat_id + "_" + a.id}>
                                                                                <Input type="checkbox" id={dc.id + "_" + a.id} checked={acc_checks[dc.id][a.id]} onChange={() => { toggleAccountCheck(dc.id, a.id);}} role="button"/>
                                                                                <Label check htmlFor={dc.id + "_" + a.id}>{a.acc_no} {a.desc}</Label>
                                                                            </FormGroup>
                                                                        ))}
                                                                        </Collapse>

                                                                    </ListGroupItem>
                                                                ))}
                                                            </ListGroup>
                                                        ) : ''}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        ))}
                                    </TabContent>
                                </React.Fragment>
                            ): ''}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="departments" sm={2}>
                            Funds Selection
                        </Label>
                        <Col sm={10}>
                            {(funds.length > 0) ? (
                            <ul className="list-unstyled card-columns" style={{columnCount: 2}}>
                                {funds.map((f) => (
                                    <li>
                                    <FormGroup check key={f.id}>
                                        <Input type="checkbox" id={f.id + "_" + f.code} checked={fund_checks[f.id]} onChange={() => { toggleFundCheck(f.id);}} role="button"/>
                                        <Label check htmlFor={f.id + "_" + f.code}>{f.code} {f.description}</Label>
                                    </FormGroup>
                                    </li>
                                ))}
                            </ul>
                            ) : ''}
                        </Col>
                    </FormGroup>


                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2}}>
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Create
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default BudgetYearAdd;
