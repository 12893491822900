import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Table, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import "assets/css/sidebar.css";

function RenderFundRow({ fund, funds, num_fund, setIncomeFundId, setIncomeFundAmount, removeFund }) {
    return (
        <React.Fragment>
            <Col sm={6}>
                {(funds.length === 0) ? (
                    <p>Loading list of fund...</p>
                ) : (
                    <Select options={funds} id={'fund_' + fund.fund_no} name={'fund_' + fund.fund_no} placeholder="select a fund"
                        value={funds.filter(s => s.value === fund.fund_id)} onChange={(val) => setIncomeFundId(fund.fund_no, val.value)} />
                )}
            </Col>
            {(num_fund > 1) ? (
                <React.Fragment>
                    <Col sm={2}>
                        <InputGroup>
                            <InputGroupText>$</InputGroupText>
                            <Input id={'fund_' + fund.fund_no + '_amount'} name={'fund_' + fund.fund_no + '_amount'} placeholder="0" type="text"
                                value={(fund.fund_amount) ? fund.fund_amount : ''} onChange={(e) => setIncomeFundAmount(fund.fund_no, e.target.value)} />
                        </InputGroup>
                    </Col>
                    <Col sm={1} className="d-flex align-items-center">
                        <FontAwesomeIcon className="align-middle text-danger" icon="fa-solid fa-circle-minus" onClick={e => removeFund(fund.fund_no)} />
                    </Col>
                </React.Fragment>
            ) : ''}
        </React.Fragment>
    );
}


function IncomeAdd(props) {

    const navigate = useNavigate();

    let { budgetYearId } = useParams();

    const [funds, setFunds] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [pl_categories, setPLCategories] = useState([]);
    const [error, setError] = useState('');
    const [num_fund, setNumFund] = useState(1);
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);
    const [budget_year, setBudgetYear] = useState(0);
    const [budget_year_value, setBudgetYearValue] = useState(0);
    const [income_account, setIncomeAccount] = useState(0);
    const [income_department, setIncomeDepartment] = useState(null);
    const [income_pl_category, setIncomePLCategory] = useState(0);
    const [income_description, setIncomeDescription] = useState('');
    const [income_amount, setIncomeAmount] = useState(0);
    const [income_funds, setIncomeFunds] = useState([{ 'fund_no': 1, 'fund_id': 0, 'fund_amount': 0 }]);
    const [income_months, setIncomeMonths] = useState([])

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        const DATA_URL = process.env.REACT_APP_API_URL + "/income/create_form/" + budgetYearId + "/";
        const fetchData = async () => {
            try {
                const result = await api.get(DATA_URL);
                setBudgetYear(result.data.year.id);
                if (result.data.year.label.length > 0)
                    setBudgetYearValue(result.data.year.year + ' — ' + result.data.year.label);
                else
                    setBudgetYearValue(result.data.year.year);
                const funds = [];
                result.data.funds.map((fund) => {
                    funds.push({ value: fund.id, label: fund.code + ": " + fund.description });
                    return 0;
                });
                setFunds(funds);
                const accounts = [];
                result.data.accounts.map((acc) => {
                    accounts.push({ value: acc.id, label: acc.account_number + ": " + acc.description });
                    return 0;
                });
                setAccounts(accounts);
                const departments = [];
                result.data.departments.map((dept) => {
                    departments.push({ value: dept.id, label: dept.abbr + ": " + dept.name });
                    return 0;
                });
                setDepartments(departments);
                const pl_categories = [];
                result.data.pl_categories.map((pl_cat) => {
                    pl_categories.push({ value: pl_cat.id, label: pl_cat.name });
                    return 0;
                });
                setPLCategories(pl_categories);
            } catch {
                setError("Something went wrong");
            }
        };
        fetchData();
    }, []);

    function addFund() {
        setNumFund(num_fund + 1);
        const newIncomeFund = [...income_funds, { 'fund_no': num_fund + 1, 'fund_id': 0, 'fund_amount': 0 }];
        setIncomeFunds(newIncomeFund);
    }

    function removeFund(fund_no) {
        setNumFund(num_fund - 1);
        const newIncomeFund = income_funds.filter(fund => fund.fund_no !== fund_no);
        let i = 1;
        const reorderedIncomeFund = newIncomeFund.map(fund => {
            return { ...fund, fund_no: i++ };
        });
        setIncomeFunds(reorderedIncomeFund);

    }

    function setIncomeFundId(fund_no, fund_id) {
        const newIncomeFund = income_funds.map(fund => {
            if (fund.fund_no === fund_no) {
                return { ...fund, fund_id: fund_id };
            }
            return fund;
        });
        setIncomeFunds(newIncomeFund);
    }

    function setIncomeFundAmount(fund_no, fund_amount) {
        const newIncomeFund = income_funds.map(fund => {
            if (fund.fund_no === fund_no) {
                return { ...fund, fund_amount: fund_amount };
            }
            return fund;
        });
        setIncomeFunds(newIncomeFund);
    }

    function validate() {
        if (!income_pl_category) {
            alert("Please select an income category")
            return false;
        }
        if (!income_account) {
            alert("Please select an account");
            return false;
        }
        if (income_description.trim() === '') {
            alert("Please enter a description for this income");
            return false;
        }
        if (income_funds.length > 1) {
            let total_funds = 0;
            income_funds.forEach((fund) => {
                total_funds += parseFloat(fund.fund_amount);
            });
            if (total_funds !== parseFloat(income_amount)) {
                alert("Total amount for all fund must be equal to the income amount. " + total_funds + " vs " + income_amount);
                return false;
            }    
        }
        let total_months = 0;
        income_months.forEach((amount, i) => {
            if (!isNaN(parseFloat(amount))) {
                total_months += parseFloat(amount);
            }
        });
        if (total_months !== parseFloat(income_amount)) {
            alert("Total amount for income months must be equal to the income amount. " + total_months + " vs " + income_amount);
            return false;
        }

        return true;
    }

    function handleSubmit(event) {
        setIsSaving(true);
        const POST_URL = process.env.REACT_APP_API_URL + "/income/";
        if (validate()) {
            const i_funds = income_funds.map((fund) =>
                ({ 'fund': fund.fund_id, 'amount': (num_fund > 1) ? fund.fund_amount : income_amount })
            );
            const i_months = [];
            income_months.slice(1).forEach((amount, i) => {
                if (amount && amount != 0) i_months.push({'month': i + 1, 'amount' : amount});
            });
            
            api.post(POST_URL, {
                // 'csrfmiddlewaretoken': 'XDpd80GzwdwOiKgb6PKnpCaDjcijtlaHF3HlnhcYBPFJEHD2gHCROy8VKlPWdi3N',
                'description': income_description,
                'account': income_account,
                'amount': income_amount,
                'budget_year': budget_year,
                'department': income_department,
                'pl_category': income_pl_category,
                'funds': i_funds,
                'months': i_months
            })
                .then(function (response) {
                    alert(response.data.status);
                    navigate("/income/summary/" + budgetYearId);
                })
                .catch(function (error) {
                    alert(error.message);
                });
        } else {
            setIsSaving(false);
        }
        event.preventDefault();
    }

    function updateIncomeMonth(m, amount) {
        const updatedIncomeMonths = income_months;
        updatedIncomeMonths[m] = amount;
        setIncomeMonths(updatedIncomeMonths);
    }

    const fund_rows = income_funds.map((fund) => {
        return (
            <div className="row mb-1" key={fund.fund_no}>
                <RenderFundRow fund={fund} funds={funds} num_fund={num_fund} setIncomeFundId={setIncomeFundId} setIncomeFundAmount={setIncomeFundAmount} removeFund={removeFund} />
            </div>
        );
    });

    return (
        <React.Fragment>
            {(!user.is_portal_admin) ? (
                <Navigate to="/dashboard" />
            ) : (
                <div className="m-2">
                    <h4>Add an income item for Budget Year {budget_year_value}</h4>
                    <hr />
                    <Form onSubmit={handleSubmit}>
                        <FormGroup row>
                            <Label for="department" sm={2}>
                                Department
                            </Label>
                            <Col sm={6}>
                                {(accounts.length === 0) ? (
                                    <p>Loading list of departments...</p>
                                ) : (
                                    <Select options={departments} id="department" name="department" placeholder="select a department (optional)"
                                        value={departments.filter(a => a.value === income_department)[0]} onChange={(val) => setIncomeDepartment(val.value)} />
                                )}
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="category" sm={2}>
                                PL Category
                            </Label>
                            <Col sm={6}>
                                {(pl_categories.length === 0) ? (
                                    <p>Loading list of categories...</p>
                                ) : (
                                    <Select options={pl_categories} id="pl_category" name="pl_category" placeholder="select a category"
                                        value={pl_categories.filter(a => a.value === income_pl_category)[0]} onChange={(val) => setIncomePLCategory(val.value)} />
                                )}
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="account" sm={2}>
                                Account Code
                            </Label>
                            <Col sm={6}>
                                {(accounts.length === 0) ? (
                                    <p>Loading list of account code...</p>
                                ) : (
                                    <Select options={accounts} id="account" name="account" placeholder="select an account"
                                        value={accounts.filter(a => a.value === income_account)[0]} onChange={(val) => setIncomeAccount(val.value)} />
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={2}>
                                Description
                            </Label>
                            <Col sm={10}>
                                <textarea className="form-control" name="description" id="description" rows={5} style={{fontSize: "small"}} value={income_description ? income_description : ''} onChange={(e) => setIncomeDescription(e.target.value)} />

                                {/* <Input id="description" name="description" placeholder="description" type="text" bsSize="sm"
                                    value={income_description ? income_description : ''} onChange={(e) => setIncomeDescription(e.target.value)} /> */}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="amount" sm={2}>
                                Amount
                            </Label>
                            <Col sm={2}>
                                <InputGroup size="sm">
                                    <InputGroupText>$</InputGroupText>
                                    <Input id="amount" name="amount" placeholder="0" type="text" 
                                        value={income_amount ? income_amount : ''} onChange={(e) => setIncomeAmount(e.target.value)} />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <hr />
                        <FormGroup row>
                            <Label for="sof" sm={2}>
                                Fund
                            </Label>
                            <Col sm={10}>
                                {(num_fund > 1) ? (
                                    <p className="bg-info p-1"><small><em>Please allocate the amount for each fund and ensure that the total amount from multiple fund equals the amount of this income.</em></small></p>
                                ) : ''}
                                {fund_rows}
                                <span className="badge rounded-pill bg-secondary" onClick={addFund} role="button">Add another Fund</span>
                            </Col>
                        </FormGroup>
                        <hr />
                        <FormGroup row>
                            <Label for="month" sm={2}>
                                Month of Income
                            </Label>
                            <Col sm={10}>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Jan</th><th>Feb</th><th>Mar</th><th>Apr</th><th>May</th><th>Jun</th>
                                        </tr>
                                        <tr>
                                        {[...Array(6)].map((x, i) =>
                                            <td key={`td_${i}`}>
                                                <InputGroup key={i + 1} size="sm">
                                                <InputGroupText>$</InputGroupText>
                                                <Input id={'month_amount_' + (i+1)} name={'month_amount_' + (i+1)} placeholder="0" type="text" 
                                                    onChange={(e) => updateIncomeMonth(i+1, e.target.value)} />
                                            </InputGroup>
                                            </td>
                                        )}
                                        </tr>
                                        <tr>
                                        <th>Jul</th><th>Aug</th><th>Sep</th><th>Oct</th><th>Nov</th><th>Dec</th>
                                        </tr>
                                        <tr>
                                        {[...Array(6)].map((x, i) =>
                                            <td key={`td_${i}`}>
                                                <InputGroup key={(i + 7)} size="sm">
                                                <InputGroupText>$</InputGroupText>
                                                <Input id={'month_amount_' + (i + 7)} name={'month_amount_' + (i + 7)} placeholder="0" type="text" 
                                                    onChange={(e) => updateIncomeMonth(i+7, e.target.value)}/>
                                            </InputGroup>
                                            </td>
                                        )}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={{ size: 10, offset: 2 }}>
                            {(isSaving) ? (
                                    <span>
                                        <Spinner size="sm">Saving, please wait...</Spinner> <span>Saving, please wait...</span>
                                    </span>
                                ) : (
                                    <React.Fragment>
                                        <Button href={`/budget/income/summary/${budgetYearId}`} variant="secondary">
                                            Cancel
                                        </Button>
                                        {' '}
                                        <Button type="submit" id="Submit" name="Submit" color="primary">
                                            Save
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Col>
                        </FormGroup>

                    </Form>
                </div>
            )}
        </React.Fragment>
    );
}

export default IncomeAdd;
