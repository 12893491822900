import React, { useState, useEffect, useContext } from "react";
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Button } from "reactstrap";
import Select from 'react-select'
import "assets/css/sidebar.css";
import { useNavigate, useParams } from "react-router-dom";


function BudgetDepartmentEdit(props) {

    let { budgetDepartmentId } = useParams();

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [owners, setOwners] = useState([]);
    const [reviewers, setReviewers] = useState([]);
    const [error, setError] = useState('');

    const [budget_department, setBudgetDepartment] = useState([]);
    const [budget_owners, setBudgetOwners] = useState([]);
    const [selected_owners, setSelectedOwners] = useState([]);
    const [assigned_reviewer, setAssignedReviewer] = useState(0);
    const [selected_reviewer, setSelectedReviewer] = useState();

    const goBack = () => {
          navigate(-1);
      }
 
    const api = useAxios();

    useEffect(() => {

        if (!user.is_portal_admin) {
            navigate('/dashboard')
        }

        const BUDGET_DEPARTMENT_DATA_URL = process.env.REACT_APP_API_URL + "/budget_department/" + budgetDepartmentId + '/';
        const DATA_URL = process.env.REACT_APP_API_URL + "/account/staff/";
        const fetchData = async () => {
          try {
            // Get data to populate form
            const result = await api.get(DATA_URL);
            const users = [];
            const school_leaders = [];
            result.data.map((user) => {
                users.push({ value: user.id, label: user.fullname });
                if (user.groups.includes("APS Portal School Leader")) {
                    school_leaders.push({ value: user.id, label: user.fullname });
                }
                return 0;
            });
            setOwners(users);
            setReviewers(school_leaders);

            // Get this budget department data
            const budget_department = await api.get(BUDGET_DEPARTMENT_DATA_URL);
            // console.log(JSON.stringify(budget_department));
            setBudgetDepartment(budget_department.data);
            const selected_owners = [];
            const budget_owners = [];
            budget_department.data.ownerships.forEach((oship) => {
                selected_owners.push({ value: oship.owner.id, label: oship.owner.fullname })
                budget_owners.push({'id': oship.owner.id});
            });
            setBudgetOwners(budget_owners);
            setSelectedOwners(selected_owners);
            setAssignedReviewer(budget_department.data.assigned_reviewer.id);
            setSelectedReviewer({value: budget_department.data.assigned_reviewer.id, label: budget_department.data.assigned_reviewer.fullname});
          } catch {
            setError("Something went wrong");
          }
        };
        fetchData();
    }, []);

    function updateBudgetOwners(val) {
        const owners = [];
        const selected_owners = [];
        val.forEach((o) => {
            owners.push({'id': o.value});
            selected_owners.push(o);
        })
        setBudgetOwners(owners);
        setSelectedOwners(selected_owners);
    }

    function updateAssignedReviewer(val) {
        setAssignedReviewer(val.value);
        setSelectedReviewer(val);
    }

    function validate() {
        if (budget_owners.length < 1) {
            alert("Please select at least one budget owner");
            return false;
        }
        if (!assigned_reviewer) {
            alert("Please select an assigned reviewer");
            return false;
        }
        return true;
    }

    function handleSubmit(event) {
        const PUT_URL = process.env.REACT_APP_API_URL + "/budget_department/" + budgetDepartmentId + "/";
        if (validate()) {
            api.put(PUT_URL, {
                'owners': budget_owners,
                'assigned_reviewer': assigned_reviewer
            })
            .then(function (response) {
                alert("Cost Centre owners and assigned reviewer successfully updated");
                navigate('/dashboard')
            })
            .catch(function (error) {
                alert(error.message);
            });
        }
        event.preventDefault();
    }


    return (
        <React.Fragment>
            <div className="m-2">
                <h3>Edit Cost Centre Owners and Assigned Reviewer
                    {(budget_department.department) ? (
                        <React.Fragment> &mdash; {budget_department.department.name}</React.Fragment>
                    ) : ''}
                </h3>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <FormGroup row>
                        <Label for="owners" sm={2}>
                            Owners
                        </Label>
                        <Col sm={10}>
                        <Select name="owners" id="owners" options={owners} value={selected_owners} isMulti onChange={updateBudgetOwners} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="owners" sm={2}>
                            Assigned Reviewer
                        </Label>
                        <Col sm={10}>
                        <Select name="assigned_reviewer" id="assigned_reviewer" options={reviewers} value={selected_reviewer} onChange={updateAssignedReviewer} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            <Button onClick={goBack} variant="secondary">
                                Cancel
                            </Button>
                            {' '}
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Update
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default BudgetDepartmentEdit;
