import React, { useState, useEffect, useContext } from "react";
import { Navigate, Link } from 'react-router-dom';
import useAxios from "utils/useAxios";
import { Spinner, Table } from "reactstrap";
import 'assets/css/sidebar.css'
import AuthContext from "context/AuthContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faCircleMinus, faUserCheck } from '@fortawesome/free-solid-svg-icons'


function UserList(props) {

  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);

  const [staff, setStaff] = useState([]);

  const api = useAxios();

  useEffect(() => {

    const USER_DATA_URL = process.env.REACT_APP_API_URL + "/account/staff/";
    const fetchData = async () => {
      try {
        const result = await api.get(USER_DATA_URL);
        setStaff(result.data);
        setIsLoading(false);
      } catch {
        alert("Something went wrong");
      }  
    }
    fetchData();
  }, []);

  const reactivate = (user_id) => {
    const POST_URL = process.env.REACT_APP_API_URL + "/account/user/" + user_id + "/reactivate/";
    api.post(POST_URL)
    .then(function (response) {
        if (response.status == 200) {
            alert("Staff user account successfully reactivated");
            window.location.reload(true)
        } else {
            alert("Something went wrong. Please try again or contact support");
        }
    })
    .catch(function (error) {
        alert(error.message);
    });
  }

  const deactivate = (user_id) => {
    const confirmed = window.confirm("Are you sure you want to de-activate this user?");
    if (confirmed) {
      const POST_URL = process.env.REACT_APP_API_URL + "/account/user/" + user_id + "/deactivate/";
      api.post(POST_URL)
      .then(function (response) {
          if (response.status == 200) {
              alert("Staff user account successfully deactivated");
              window.location.reload(true)
          } else {
              alert("Something went wrong. Please try again or contact support");
          }
      })
      .catch(function (error) {
          alert(error.message);
      });
    }
  }

  return (
    <React.Fragment>
      {(!isLoading) ? (
        <React.Fragment>
          {(!user.is_portal_admin) ? (
              <Navigate to="/dashboard" />
          ) : (
            <div>
              <h4>Staff List</h4>

              <p><Link className="btn btn-secondary btn-sm me-1" to="/account/user/add">Add New Staff</Link></p>

              {(staff.length > 0) ? (

                <React.Fragment>
                  <Table id="staff-list" bordered responsive size="sm">
                    <thead className="bg-info">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Username</th>
                        <th>Roles</th>
                        <th>Active</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staff.map((s) => (
                        (s.is_active) ? (
                          <tr key={s.id}>
                            <td>{s.id}</td>
                            <td>{s.fullname}</td>
                            <td>{s.email}</td>
                            <td>{s.username}</td>
                            <td>
                              {s.groups.map((g, i) => (
                                <span className="badge bg-secondary text-bg-secondary ms-2" key={`ug_${i}`}> {g} </span>
                              ))}
                            </td>
                            <td>{(s.is_active) ? "Yes" : "No"}</td>
                            <td>
                              <Link className="ms-1" to={`/account/user/edit/${s.id}`} title="Edit"><FontAwesomeIcon icon={faPenToSquare} /></Link> 
                              <Link className="ms-1 text-danger" onClick={() => deactivate(s.id)} title="De-activate"><FontAwesomeIcon icon={faCircleMinus} /></Link> 
                            </td>
                          </tr>
                        ): (
                          <tr key={s.id} style={{backgroundColor: '#ffcccccc', color: '#787878'}}>
                            <td>{s.id}</td>
                            <td>{s.fullname}</td>
                            <td>{s.email}</td>
                            <td>{s.username}</td>
                            <td>
                              {s.groups.map((g, i) => (
                                <span className="badge bg-secondary text-bg-secondary ms-2" key={`ug_${i}`}> {g} </span>
                              ))}
                            </td>
                            <td>{(s.is_active) ? "Yes" : "No"}</td>
                            <td>
                              <Link className="ms-1" to={`/account/user/edit/${s.id}`} title="Edit"><FontAwesomeIcon icon={faPenToSquare} /></Link> 
                              <Link className="ms-1 text-success" onClick={() => reactivate(s.id)} title="Re-activate"><FontAwesomeIcon icon={faUserCheck} /></Link> 
                            </td>
                          </tr>
                        )
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>

              ) : (

                <div>No staff account has been created.</div>
              
              )}

            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="text-center mt-3">
          <Spinner>Loading...</Spinner>
          <p>Loading...</p>
        </div>
      )}
    </React.Fragment>
  );

}

export default UserList;
