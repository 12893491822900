import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button, Table, InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import "assets/css/sidebar.css";


function AccountAdd(props) {

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);
    const [account_number, setAccountNumber] = useState(0);
    const [account_description, setAccountDescription] = useState(0);
    const [account_category, setAccountCategory] = useState(0);
    const [account_pl_category, setAccountPLCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [all_pl_categories, setAllPLCategories] = useState([]);
    const [pl_categories, setPLCategories] = useState([]);

    useEffect(() => {
        const DATA_URL = process.env.REACT_APP_API_URL + "/budget_account/create_form/";
        const fetchData = async () => {
            try {
                const result = await api.get(DATA_URL);
                if (Object.keys(result.data).length == 0) {
                    navigate('/dashboard');
                }
                const cats = [];
                result.data.categories.map((c) => {
                    cats.push({ value: c.id, label: c.name });
                    return 0;
                });
                setAllPLCategories(result.data.pl_categories);
                setCategories(cats);
            } catch {
                setError("Something went wrong");
            }
        };
        fetchData();
    }, []);

    function setPLCategoryOptions(acc_cat) {
        setAccountPLCategory(null);
        setAccountCategory(acc_cat.value);
        const pl_cats = [];
        if (acc_cat.label != "INCOME ITEMS") {
            const pl_cat_types = {
                'EXPENDITURE ITEMS': 'EXPENSE',
                'FIXED ASSET ITEMS': 'CAPITAL EXPENSE',
            }
            all_pl_categories.map((plc) => {
                if (plc.pl_category_type == pl_cat_types[acc_cat.label])
                    pl_cats.push({value: plc.id, label: plc.name});
            });
        }
        setPLCategories(pl_cats);
    }

    function validate() {
        if (!account_number) {
            alert("Please enter account number");
            return false;
        }
        if (!account_description) {
            alert("Please enter account description");
            return false;
        }
        if (!account_category) {
            alert("Please select a category for the account");
            return false;
        }
        return true;
    }

    function handleSubmit(event) {
        const POST_URL = process.env.REACT_APP_API_URL + "/budget_account/";
        if (validate()) {
            api.post(POST_URL, {
                // 'csrfmiddlewaretoken': 'XDpd80GzwdwOiKgb6PKnpCaDjcijtlaHF3HlnhcYBPFJEHD2gHCROy8VKlPWdi3N',
                'account_number': account_number,
                'description': account_description,
                'category': account_category,
                'pl_category': account_pl_category,
            })
                .then(function (response) {
                    if (response.status == 201) {
                        alert("Account created successfully");
                        navigate("/budget_accounts");
                    } else {
                        alert("Something went wrong. Please try again or contact support");
                    }
                })
                .catch(function (error) {
                    alert(error.message);
                });
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="m-2">
                <h3>Add a new account</h3>
                <hr />
                <Form onSubmit={handleSubmit} class="d-flex flex-column flex-grow-1">
                    <FormGroup row>
                        <Label for="account_number" sm={2}>
                            Account Number
                        </Label>
                        <Col sm={4}>
                            <Input id="account_number" name="account_number" placeholder="Account Number" type="text" bsSize="sm"
                                value={account_number ? account_number : ''} onChange={(e) => setAccountNumber(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="description" sm={2}>
                            Description
                        </Label>
                        <Col sm={10}>
                            <Input id="description" name="description" placeholder="description" type="text" bsSize="sm"
                                value={account_description ? account_description : ''} onChange={(e) => setAccountDescription(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="category" sm={2}>
                            Category
                        </Label>
                        <Col sm={6}>
                            {(categories.length === 0) ? (
                                <p>Loading list of categories...</p>
                            ) : (
                                <Select options={categories} id="category" name="category" placeholder="select a category"
                                    value={categories.filter(a => a.value === account_category)[0]} onChange={(val) => setPLCategoryOptions(val)} />
                            )}
                        </Col>
                    </FormGroup>
                    {(account_category == 6 || account_category == 7) ? (
                        <FormGroup row>
                            <Label for="pl_category" sm={2}>
                                P&L Category (level 4)
                            </Label>
                            <Col sm={6}>
                                {(all_pl_categories.length === 0) ? (
                                    <p>Loading list of P&L categories...</p>
                                ) : (
                                    <Select options={pl_categories} id="pl_category" name="pl_category" placeholder="select a P&L category"
                                        value={(account_pl_category) ? pl_categories.filter(a => a.value === account_pl_category)[0] : 0} onChange={(val) => setAccountPLCategory(val.value)} />
                                )}
                            </Col>
                        </FormGroup>
                    ) : ''}

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Save
                            </Button>
                            {' '}
                            <Button href={`/budget/budget_accounts/`} variant="secondary">
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default AccountAdd;
