import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from "context/AuthContext";
import useAxios from "utils/useAxios";
import { Form, FormGroup, Label, Col, Input, Button } from "reactstrap";
import "assets/css/sidebar.css";


function FundEdit(props) {

    let { fundId } = useParams();

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const api = useAxios();

    const { user, logoutUser } = useContext(AuthContext);
    const [fund_code, setFundCode] = useState(0);
    const [fund_description, setFundDescription] = useState(0);

    useEffect(() => {
        const FUND_DATA_URL = process.env.REACT_APP_API_URL + "/fund/" + fundId + "/";
        const fetchData = async () => {
            try {
                // Get this fund data
                const acc = await api.get(FUND_DATA_URL);
                setFundCode(acc.data.code);
                setFundDescription(acc.data.description);
            } catch {
                setError("Something went wrong");
            }
        };
        fetchData();
    }, []);

    function validate() {
        if (!fund_code) {
            alert("Please enter fund code");
            return false;
        }
        if (!fund_description) {
            alert("Please enter fund description");
            return false;
        }
        return true;
    }

    function handleSubmit(event) {
        const PUT_URL = process.env.REACT_APP_API_URL + "/fund/" + fundId + "/";
        if (validate()) {
            api.put(PUT_URL, {
                // 'csrfmiddlewaretoken': 'XDpd80GzwdwOiKgb6PKnpCaDjcijtlaHF3HlnhcYBPFJEHD2gHCROy8VKlPWdi3N',
                'code': fund_code,
                'description': fund_description
            })
                .then(function (response) {
                    if (response.status == 200 || response.status == 204) {
                        alert("Fund updated successfully");
                        navigate("/funds");
                    } else {
                        alert("Something went wrong. Please try again or contact support");
                    }
                })
                .catch(function (error) {
                    alert(error.message);
                });
        }
        event.preventDefault();
    }

    return (
        <React.Fragment>
            <div className="m-2">
                <h4>Edit fund</h4>
                <hr />
                <Form onSubmit={handleSubmit} class="d-flex flex-column flex-grow-1">
                <FormGroup row>
                        <Label for="fund_code" sm={2}>
                            Code
                        </Label>
                        <Col sm={4}>
                            <Input id="fund_code" name="fund_code" placeholder="Code" type="text" bsSize="sm"
                                value={fund_code ? fund_code : ''} onChange={(e) => setFundCode(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="description" sm={2}>
                            Description
                        </Label>
                        <Col sm={10}>
                            <Input id="description" name="description" placeholder="description" type="text" bsSize="sm"
                                value={fund_description ? fund_description : ''} onChange={(e) => setFundDescription(e.target.value)} />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col md={{ size: 10, offset: 2 }}>
                            <Button type="submit" id="Submit" name="Submit" color="primary">
                                Save
                            </Button>
                            {' '}
                            <Button href={`/budget/funds/`} variant="secondary">
                                Cancel
                            </Button>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        </React.Fragment>
    );
}

export default FundEdit;
