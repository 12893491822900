function Footer(props) {
  return (
    <footer className="footer mt-auto py-3 bg-light text-center">
      <div className="container">
        <span className="text-muted">
          <small>Developed by <a href="https://www.aeternasolutions.com" target="_blank">Aeterna Solutions</a> for Assumption Pathway School</small>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
